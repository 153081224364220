import { useTranslation } from 'react-i18next';
import PageCreateButton from '../../components/PageCreateButton';
import routes from '../../config/routes';
import useURL from '../../hooks/useURL';
import { can } from '../permission/helpers';

const GlobalClubCreateButton = () => {
  const { t } = useTranslation();
  const { navigate } = useURL();

  const handleClick = () => navigate(routes.clubsCreate);

  if (!can.club.createUpdate) return null;

  return <PageCreateButton onClick={handleClick}>{t('common.club')}</PageCreateButton>;
};

export default GlobalClubCreateButton;
