import clsx from 'clsx';
import { format, isSameDay } from 'date-fns';
import { Fragment } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { loadNotifications } from '../../../api';
import { NotificationSchema } from '../../../api/schemas/notificationSchema';
import { NotificationsCountSchema } from '../../../api/schemas/notificationsCountSchema';
import queryKeys from '../../../config/queryKeys';
import { MONTH_DAY } from '../../../constants/DATE_FORMATS';
import usePaginatedDataNew from '../../../hooks/usePaginatedDataNew';
import useWebSocketMessage from '../../../hooks/useWebSocketMessage';
import icon from '../../../static/icons';
import { getDate } from '../../../utils/date';
import {
  createInfinitePaginatedQueryData,
  updateInfinitePaginatedQueryData,
} from '../../../utils/queryClient';
import Button from '../../Button';
import PageAddon from '../../PageAddon';
import { getUrlParams } from '../../PageAddon/PageAddonFilter/helpers';
import Spinner from '../../Spinner';
import Stub from '../../Stub';
import Tooltip from '../../Tooltip';
import { useReadNotificationMutation } from './mutations';
import PageNotificationsFilter from './PageNotificationsFilter';
import PageNotificationsItem from './PageNotificationsItem';
import PageNotificationsSettingsDialog from './PageNotificationsSettingsDialog';
import styles from './styles.module.scss';
import { Fields } from './types';

interface Props {
  count: NotificationsCountSchema['count'];
  onClose: () => void;
}

const PageNotifications = (props: Props) => {
  const { count, onClose } = props;

  const { t } = useTranslation();

  const readNotifications = useReadNotificationMutation();

  const form = useForm<Fields>({
    defaultValues: {
      action_type_in: [],
      workspace_in: [],
      author_in: [],
      show_read: false,
    },
  });

  const { watch } = form;

  const urlParams = getUrlParams(watch());

  const queryKey = [queryKeys.notifications, urlParams];

  const { data, loading, loadingMore, ref } = usePaginatedDataNew({
    queryKey,
    queryFn: (params) => loadNotifications({ ...urlParams, ...params, order_by: 'desc' }),
  });

  useWebSocketMessage<NotificationSchema>({
    domain: 'Notification',
    onMessage: (message) => {
      const { payload } = message;

      if (message.action === 'Created') {
        createInfinitePaginatedQueryData(queryKey, payload);
      }

      if (message.action === 'MarkedRead') {
        updateInfinitePaginatedQueryData(
          queryKey,
          message.payload,
          (item) => item.id === payload.id
        );
      }
    },
  });

  const total = data.length;

  return (
    <PageAddon
      title={
        <div className={styles.title}>
          <div className={styles.label}>
            {t('common.notifications')}
            {count !== 0 && <span>{count}</span>}
          </div>
          <div className={styles.controls}>
            <Tooltip label={t('common.mark_all_as_read')}>
              <Button
                size="small"
                variant="transparent"
                icon={icon('eye', 16)}
                disabled={!count || readNotifications.isPending}
                onClick={() => readNotifications.mutate({ payload: { ids: [] } })}
              />
            </Tooltip>
            <FormProvider {...form}>
              <PageNotificationsFilter urlParams={urlParams} />
            </FormProvider>
            <PageNotificationsSettingsDialog />
          </div>
        </div>
      }
      className={styles.addon}
      onClose={onClose}
    >
      {!total && !loading && <Stub title={t('sentences.no_new_notifications')} />}
      {loading && (
        <div className={styles.spinner}>
          <Spinner />
        </div>
      )}
      <div className={styles.notifications}>
        {data.map((item, index) => {
          const prevNote = data[index - 1];

          const date = getDate(item.created_on);

          const isYesterday = () => {
            if (prevNote) {
              if (!isSameDay(date, getDate(prevNote.created_on))) return true;

              return false;
            }

            return true;
          };

          return (
            <Fragment key={item.id}>
              {isYesterday() && (
                <div className={styles.day} style={{ zIndex: total + index }}>
                  {format(date, MONTH_DAY)}
                </div>
              )}
              <button
                type="button"
                {...(total - 1 - index === 0 && { ref })}
                className={clsx(styles.button, item.is_read && styles.readed)}
                {...(!item.is_read && {
                  onClick: () => readNotifications.mutate({ payload: { ids: [item.id] } }),
                })}
              >
                <PageNotificationsItem data={item} />
              </button>
            </Fragment>
          );
        })}
        {loadingMore && (
          <div className={clsx(styles.spinner, styles.more)}>
            <Spinner />
          </div>
        )}
      </div>
    </PageAddon>
  );
};

export default PageNotifications;
