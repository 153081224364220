import { useQuery, keepPreviousData } from '@tanstack/react-query';
import { ReactElement } from 'react';
import { loadGlobalWorkspace } from '../../api';
import { GlobalClubSchema } from '../../api/schemas/globalClubSchema';
import { WorkspaceSchema } from '../../api/schemas/workspaceSchema';
import PageAddon from '../../components/PageAddon';
import queryKeys from '../../config/queryKeys';
import useWebSocketMessage from '../../hooks/useWebSocketMessage';
import {
  createInfinitePaginatedQueryData,
  deleteInfinitePaginatedQueryData,
  updateDetailQueryData,
} from '../../utils/queryClient';
import { can } from '../permission/helpers';
import { useGlobalWorkspaceClubsQuery, useGlobalWorkspaceUsersQuery } from './queries';

interface Props {
  workspaceId: WorkspaceSchema['id'];
  children: (args: { data: WorkspaceSchema; clubsData: GlobalClubSchema[] }) => ReactElement;
}

const GlobalWorkspaceLoader = (props: Props) => {
  const { workspaceId, children } = props;

  const queryKey = [queryKeys.globalWorkspace({ workspaceId })];
  const queryFn = () => loadGlobalWorkspace({ workspaceId });

  const { data, isPending } = useQuery({
    queryKey,
    queryFn,
    placeholderData: keepPreviousData,
    meta: { ignoredStatuses: [403] },
  });

  useWebSocketMessage<WorkspaceSchema>({
    domain: 'GlobalMaintenance',
    onMessage: (message) => {
      if (
        (message.action === 'Edited' ||
          message.action === 'Deleted' ||
          message.action === 'NegativeBalancePilotAllowed' ||
          message.action === 'NegativeBalancePilotDisallowed') &&
        message.payload.id === workspaceId
      ) {
        updateDetailQueryData(queryKey, message.payload);
      }
    },
  });

  useGlobalWorkspaceUsersQuery({ workspaceId, enabled: can.user.read });

  const {
    data: clubsData,
    loading: clubsLoading,
    queryKey: workspaceClubsQueryKey,
  } = useGlobalWorkspaceClubsQuery({ workspaceId, enabled: can.club.read });

  useWebSocketMessage<{
    id: WorkspaceSchema['id'];
    modified_on: WorkspaceSchema['modified_on'];
    club: GlobalClubSchema;
  }>({
    domain: 'GlobalMaintenance',
    onMessage: (message) => {
      const payload = message.payload.club;

      if (message.action === 'ClubAdded') {
        createInfinitePaginatedQueryData(workspaceClubsQueryKey, payload);
      }

      if (message.action === 'ClubRemoved') {
        deleteInfinitePaginatedQueryData(workspaceClubsQueryKey, (item) => item.id === payload.id);
      }
    },
    enabled: (message) => message.payload.id === workspaceId,
  });

  if (isPending || (can.club.read && clubsLoading)) return <PageAddon.DetailPlaceholder />;
  if (!data) return null;

  return children({ data, clubsData });
};

export default GlobalWorkspaceLoader;
