import { useQuery } from '@tanstack/react-query';
import { ReactElement } from 'react';
import { loadGlobalAgent } from '../../api';
import { AgentSchema } from '../../api/schemas/agentSchema';
import PageAddon from '../../components/PageAddon';
import queryKeys from '../../config/queryKeys';
import useWebSocketMessage from '../../hooks/useWebSocketMessage';
import { updateDetailQueryData } from '../../utils/queryClient';

interface Props {
  agentId: AgentSchema['id'];
  children: (args: { data: AgentSchema }) => ReactElement;
}

const GlobalAgentLoader = (props: Props) => {
  const { agentId, children } = props;

  const queryKey = [queryKeys.agent({ agentId })];
  const queryFn = () => loadGlobalAgent({ agentId });

  const { data, isPending } = useQuery({
    queryKey,
    queryFn,
    meta: { ignoredStatuses: [403] },
  });

  useWebSocketMessage<AgentSchema>({
    domain: 'GlobalAgent',
    onMessage: (message) => {
      if (
        (message.action === 'Edited' ||
          message.action === 'NoteAdded' ||
          message.action === 'NoteRemoved') &&
        message.payload.id === agentId
      ) {
        updateDetailQueryData(queryKey, message.payload);
      }
    },
  });

  if (isPending) return <PageAddon.DetailPlaceholder />;
  if (!data) return null;

  return children({ data });
};

export default GlobalAgentLoader;
