import { ChannelSchema, STATUS } from '../../api/schemas/channelSchema';
import { t } from '../../i18n';
import { Fields } from './types';

export const CHANNEL_STATUS_LABELS: Record<ChannelSchema['status'], string> = {
  [STATUS.UNKNOWN]: t('common.unknown'),
  [STATUS.INACTIVE]: t('common.inactive'),
  [STATUS.ACTIVE]: t('common.active'),
};

export const getDefaultValues = (data?: ChannelSchema) => ({
  host: data?.host || '',
  port: data ? String(data.port) : '',
  username: data?.username || '',
  password: data?.password || '',
});

export const getPayload = (values: Fields) => ({ ...values, port: Number(values.port) });
