import { useTranslation } from 'react-i18next';
import { loadGlobalClubAnnouncements } from '../../../api';
import { GlobalClubAnnouncementSchema } from '../../../api/schemas/globalClubAnnouncementSchema';
import { GlobalClubSchema } from '../../../api/schemas/globalClubSchema';
import Collapse from '../../../components/Collapse';
import Empty from '../../../components/Empty';
import PageAddon from '../../../components/PageAddon';
import Spinner from '../../../components/Spinner';
import queryKeys from '../../../config/queryKeys';
import usePaginatedDataNew from '../../../hooks/usePaginatedDataNew';
import useWebSocketMessage from '../../../hooks/useWebSocketMessage';
import { formatAbsoluteDate } from '../../../utils/date';
import {
  createInfinitePaginatedQueryData,
  updateInfinitePaginatedQueryData,
} from '../../../utils/queryClient';
import styles from './styles.module.scss';

interface Props {
  clubId: GlobalClubSchema['id'];
}

const GlobalClubDetailAnnouncementsTab = (props: Props) => {
  const { clubId } = props;

  const { t } = useTranslation();

  const queryKey = [queryKeys.clubAnnouncements({ clubId })];

  const { data, loading, loadingMore, checkIsInView } = usePaginatedDataNew({
    queryKey,
    queryFn: (params) =>
      loadGlobalClubAnnouncements({ clubId }, { ...params, show_deactivated: 'true' }),
  });

  useWebSocketMessage<GlobalClubAnnouncementSchema>({
    domain: 'Announcement',
    onMessage: (message) => {
      const { payload } = message;

      if (message.payload.club_id === clubId) {
        if (message.action === 'Created') {
          createInfinitePaginatedQueryData(queryKey, payload);
        }

        if (message.action === 'Deactivated') {
          updateInfinitePaginatedQueryData(
            queryKey,
            message.payload,
            (item) => item.id === payload.id
          );
        }
      }
    },
  });

  if (loading) return <PageAddon.DetailPlaceholder wrapper={false} />;
  if (!data) return null;

  const getContent = () => {
    if (!data.length) return <Empty />;

    return data.map((item, index) => (
      <Collapse
        key={item.id}
        label={formatAbsoluteDate(item.created_on)}
        labelBefore={<Collapse.Badge color={item.is_active ? 'green' : 'red'} />}
        {...checkIsInView(index)}
      >
        <Collapse.InfoField label={t('common.type')}>{item.type || '—'}</Collapse.InfoField>
        <Collapse.InfoField label={t('common.identity')}>{item.identity || '—'}</Collapse.InfoField>
        {item.deactivated && (
          <Collapse.InfoField label={t('common.expired')}>
            {formatAbsoluteDate(item.deactivated)}
          </Collapse.InfoField>
        )}
        <div className={styles.divider} />
        <span className={styles.message}>{item.message}</span>
      </Collapse>
    ));
  };

  return (
    <div className={styles.container}>
      {getContent()}
      {loadingMore && (
        <div className={styles.spinner}>
          <Spinner />
        </div>
      )}
    </div>
  );
};

export default GlobalClubDetailAnnouncementsTab;
