import { UseFormReturn } from 'react-hook-form';
import { z } from 'zod';
import { AccountStrategyProfileSchema } from '../../api/schemas/accountStrategyProfileSchema';
import { WorkspaceSettingsStrategyProfileSchema } from '../../api/schemas/workspaceSettingsStrategyProfileSchema';
import Checkbox from '../../components/Checkbox';
import Input from '../../components/Input';
import Select, { Option } from '../../components/Select';
import { t } from '../../i18n';
import { convertStringToNumber } from '../../utils/data';
import { max, required } from '../../utils/form';
import { numbers } from '../../utils/validations';
import {
  PLAYER_TYPE_LABELS,
  playerTypesOptions,
  SEATING_PREFERENCE_LABELS,
  seatingPreferencesOptions,
} from './helpers';

export interface Fields {
  player_type: Option<AccountStrategyProfileSchema['player_type']>;
  buyin_bb_min: string;
  buyin_bb_max: string;
  buyin_step_bb: string;
  buyin_bb_straddle_table_min: string;
  buyin_bb_straddle_table_max: string;
  buyin_step_bb_straddle_table: string;
  rebuy_below_bb: string;
  rebuy_below_bb_min: string;
  rebuy_below_bb_max: string;
  rebuy_below_bb_straddle_table: string;
  rebuy_below_bb_straddle_table_min: string;
  rebuy_below_bb_straddle_table_max: string;
  stop_lose_bb: string;
  stop_lose_bb_min: string;
  stop_lose_bb_max: string;
  stop_win_bb: string;
  stop_win_bb_min: string;
  stop_win_bb_max: string;
  stop_lose_table_bb: string;
  stop_lose_table_bb_min: string;
  stop_lose_table_bb_max: string;
  stop_win_table_bb: string;
  stop_win_table_bb_min: string;
  stop_win_table_bb_max: string;
  max_swing_bb: string;
  max_swing_bb_min: string;
  max_swing_bb_max: string;
  effective_stack_limit_bb: string;
  effective_stack_limit_bb_min: string;
  effective_stack_limit_bb_max: string;
  max_stack_size_bb: string;
  max_stack_size_bb_min: string;
  max_stack_size_bb_max: string;
  table_min_hands: string;
  mtt_max_rebuy: string;
  mtt_max_rebuy_min: string;
  mtt_max_rebuy_max: string;
  mtt_addon: boolean;
  auto_rebuy: boolean;
  wait_for_big_blind: boolean;
  seating_preference: Option<AccountStrategyProfileSchema['seating_preference']>;
}

export const getFields = (args: {
  form: UseFormReturn<Fields>;
  onSubmit: (values: Fields) => void;
  selectProps?: Record<string, unknown>;
  inputProps?: Record<string, unknown>;
  checkboxProps?: Record<string, unknown>;
}) => {
  const { form, onSubmit, selectProps = {}, inputProps = {}, checkboxProps = {} } = args;

  const { control, handleSubmit } = form;

  const commonProps = { control, onSubmit: handleSubmit(onSubmit) };

  const getSelectProps = () => ({ ...commonProps, ...selectProps });

  const getInputProps = (label: string, maxValue = 100000) => ({
    ...commonProps,
    allow: numbers,
    rules: { validate: { required, max: max(label, maxValue) } },
    ...inputProps,
  });

  const getCheckboxProps = () => ({ ...commonProps, ...checkboxProps });

  return {
    player_type: (
      <Select.Quick name="player_type" options={playerTypesOptions} {...getSelectProps()} />
    ),
    buyin_bb_min: <Input.Quick name="buyin_bb_min" {...getInputProps(t('common.buy_in_min_bb'))} />,
    buyin_bb_max: <Input.Quick name="buyin_bb_max" {...getInputProps(t('common.buy_in_max_bb'))} />,
    buyin_step_bb: <Input.Quick name="buyin_step_bb" {...getInputProps(t('common.step_bb'))} />,
    rebuy_below_bb: (
      <Input.Quick name="rebuy_below_bb" {...getInputProps(t('common.rebuy_threshold_bb'))} />
    ),
    rebuy_below_bb_min: (
      <Input.Quick
        name="rebuy_below_bb_min"
        {...getInputProps(t('common.rebuy_threshold_min_bb'))}
      />
    ),
    rebuy_below_bb_max: (
      <Input.Quick
        name="rebuy_below_bb_max"
        {...getInputProps(t('common.rebuy_threshold_max_bb'))}
      />
    ),
    buyin_bb_straddle_table_min: (
      <Input.Quick
        name="buyin_bb_straddle_table_min"
        {...getInputProps(t('common.buy_in_min_bb'))}
      />
    ),
    buyin_bb_straddle_table_max: (
      <Input.Quick
        name="buyin_bb_straddle_table_max"
        {...getInputProps(t('common.buy_in_max_bb'))}
      />
    ),
    buyin_step_bb_straddle_table: (
      <Input.Quick
        name="buyin_step_bb_straddle_table"
        {...getInputProps(t('common.buy_in_step_bb'))}
      />
    ),
    rebuy_below_bb_straddle_table: (
      <Input.Quick
        name="rebuy_below_bb_straddle_table"
        {...getInputProps(t('common.rebuy_threshold_bb'))}
      />
    ),
    rebuy_below_bb_straddle_table_min: (
      <Input.Quick
        name="rebuy_below_bb_straddle_table_min"
        {...getInputProps(t('common.rebuy_threshold_bb'))}
      />
    ),
    rebuy_below_bb_straddle_table_max: (
      <Input.Quick
        name="rebuy_below_bb_straddle_table_max"
        {...getInputProps(t('common.rebuy_threshold_bb'))}
      />
    ),
    stop_lose_bb: <Input.Quick name="stop_lose_bb" {...getInputProps(t('common.stop_lose_bb'))} />,
    stop_lose_bb_min: (
      <Input.Quick name="stop_lose_bb_min" {...getInputProps(t('common.stop_lose_bb'))} />
    ),
    stop_lose_bb_max: (
      <Input.Quick name="stop_lose_bb_max" {...getInputProps(t('common.stop_lose_bb'))} />
    ),
    stop_win_bb: <Input.Quick name="stop_win_bb" {...getInputProps(t('common.stop_win_bb'))} />,
    stop_win_bb_min: (
      <Input.Quick name="stop_win_bb_min" {...getInputProps(t('common.stop_win_bb'))} />
    ),
    stop_win_bb_max: (
      <Input.Quick name="stop_win_bb_max" {...getInputProps(t('common.stop_win_bb'))} />
    ),
    stop_lose_table_bb: (
      <Input.Quick
        name="stop_lose_table_bb"
        {...getInputProps(t('common.stop_loss_by_table_bb'))}
      />
    ),
    stop_lose_table_bb_min: (
      <Input.Quick
        name="stop_lose_table_bb_min"
        {...getInputProps(t('common.stop_loss_by_table_bb'))}
      />
    ),
    stop_lose_table_bb_max: (
      <Input.Quick
        name="stop_lose_table_bb_max"
        {...getInputProps(t('common.stop_loss_by_table_bb'))}
      />
    ),
    stop_win_table_bb: (
      <Input.Quick name="stop_win_table_bb" {...getInputProps(t('common.stop_win_by_table_bb'))} />
    ),
    stop_win_table_bb_min: (
      <Input.Quick
        name="stop_win_table_bb_min"
        {...getInputProps(t('common.stop_win_by_table_bb'))}
      />
    ),
    stop_win_table_bb_max: (
      <Input.Quick
        name="stop_win_table_bb_max"
        {...getInputProps(t('common.stop_win_by_table_bb'))}
      />
    ),
    max_swing_bb: <Input.Quick name="max_swing_bb" {...getInputProps(t('common.max_swing_bb'))} />,
    max_swing_bb_min: (
      <Input.Quick name="max_swing_bb_min" {...getInputProps(t('common.max_swing_bb'))} />
    ),
    max_swing_bb_max: (
      <Input.Quick name="max_swing_bb_max" {...getInputProps(t('common.max_swing_bb'))} />
    ),
    effective_stack_limit_bb: (
      <Input.Quick name="effective_stack_limit_bb" {...getInputProps(t('common.eff_stack_bb'))} />
    ),
    effective_stack_limit_bb_min: (
      <Input.Quick
        name="effective_stack_limit_bb_min"
        {...getInputProps(t('common.eff_stack_bb'))}
      />
    ),
    effective_stack_limit_bb_max: (
      <Input.Quick
        name="effective_stack_limit_bb_max"
        {...getInputProps(t('common.eff_stack_bb'))}
      />
    ),
    max_stack_size_bb: (
      <Input.Quick name="max_stack_size_bb" {...getInputProps(t('common.max_stack_bb'))} />
    ),
    max_stack_size_bb_min: (
      <Input.Quick name="max_stack_size_bb_min" {...getInputProps(t('common.max_stack_bb'))} />
    ),
    max_stack_size_bb_max: (
      <Input.Quick name="max_stack_size_bb_max" {...getInputProps(t('common.max_stack_bb'))} />
    ),
    table_min_hands: (
      <Input.Quick name="table_min_hands" {...getInputProps(t('common.min_hands'))} />
    ),
    mtt_max_rebuy: (
      <Input.Quick name="mtt_max_rebuy" {...getInputProps(t('common.max_rebuy'), 5)} />
    ),
    mtt_max_rebuy_min: (
      <Input.Quick name="mtt_max_rebuy_min" {...getInputProps(t('common.max_rebuy'), 5)} />
    ),
    mtt_max_rebuy_max: (
      <Input.Quick name="mtt_max_rebuy_max" {...getInputProps(t('common.max_rebuy'), 5)} />
    ),
    mtt_addon: <Checkbox.Quick name="mtt_addon" {...getCheckboxProps()} />,
    wait_for_big_blind: <Checkbox.Quick name="wait_for_big_blind" {...getCheckboxProps()} />,
    auto_rebuy: <Checkbox.Quick name="auto_rebuy" {...getCheckboxProps()} />,
    seating_preference: (
      <Select.Quick
        name="seating_preference"
        options={seatingPreferencesOptions}
        {...getSelectProps()}
      />
    ),
  };
};

type Data = AccountStrategyProfileSchema | WorkspaceSettingsStrategyProfileSchema;

const isSettings = (
  data?: Data,
  settings?: boolean
): data is WorkspaceSettingsStrategyProfileSchema => Boolean(settings);

export const getDefaultValues = (args: { data?: Data; settings?: boolean } = {}) => {
  const { data, settings } = args;

  const common = {
    player_type: data
      ? { value: data.player_type, label: PLAYER_TYPE_LABELS[data.player_type] }
      : null,
    buyin_bb_min: data ? String(data.buyin_bb_min) : '',
    buyin_bb_max: data ? String(data.buyin_bb_max) : '',
    buyin_step_bb: data ? String(data.buyin_step_bb) : '',
    buyin_bb_straddle_table_min: data ? String(data.buyin_bb_straddle_table_min) : '',
    buyin_bb_straddle_table_max: data ? String(data.buyin_bb_straddle_table_max) : '',
    buyin_step_bb_straddle_table: data ? String(data.buyin_step_bb_straddle_table) : '',
    table_min_hands: data ? String(data.table_min_hands) : '',
    wait_for_big_blind: Boolean(data?.wait_for_big_blind),
    mtt_addon: Boolean(data?.mtt_addon),
    auto_rebuy: Boolean(data?.auto_rebuy),
    seating_preference: data
      ? {
          value: data.seating_preference,
          label: SEATING_PREFERENCE_LABELS[data.seating_preference],
        }
      : null,
  };

  if (isSettings(data, settings)) {
    return {
      ...common,
      rebuy_below_bb_min: data ? String(data.rebuy_below_bb_min) : '',
      rebuy_below_bb_max: data ? String(data.rebuy_below_bb_max) : '',
      rebuy_below_bb_straddle_table_min: data ? String(data.rebuy_below_bb_straddle_table_min) : '',
      rebuy_below_bb_straddle_table_max: data ? String(data.rebuy_below_bb_straddle_table_max) : '',
      stop_lose_bb_min: data ? String(data.stop_lose_bb_min) : '',
      stop_lose_bb_max: data ? String(data.stop_lose_bb_max) : '',
      stop_win_bb_min: data ? String(data.stop_win_bb_min) : '',
      stop_win_bb_max: data ? String(data.stop_win_bb_max) : '',
      stop_lose_table_bb_min: data ? String(data.stop_lose_table_bb_min) : '',
      stop_lose_table_bb_max: data ? String(data.stop_lose_table_bb_max) : '',
      stop_win_table_bb_min: data ? String(data.stop_win_table_bb_min) : '',
      stop_win_table_bb_max: data ? String(data.stop_win_table_bb_max) : '',
      max_swing_bb_min: data ? String(data.max_swing_bb_min) : '',
      max_swing_bb_max: data ? String(data.max_swing_bb_max) : '',
      effective_stack_limit_bb_min: data ? String(data.effective_stack_limit_bb_min) : '',
      effective_stack_limit_bb_max: data ? String(data.effective_stack_limit_bb_max) : '',
      max_stack_size_bb_min: data ? String(data.max_stack_size_bb_min) : '',
      max_stack_size_bb_max: data ? String(data.max_stack_size_bb_max) : '',
      mtt_max_rebuy_min: data ? String(data.mtt_max_rebuy_min) : '',
      mtt_max_rebuy_max: data ? String(data.mtt_max_rebuy_max) : '',
    };
  }

  return {
    ...common,
    rebuy_below_bb: data ? String(data.rebuy_below_bb) : '',
    rebuy_below_bb_straddle_table: data ? String(data.rebuy_below_bb_straddle_table) : '',
    stop_lose_bb: data ? String(data.stop_lose_bb) : '',
    stop_win_bb: data ? String(data.stop_win_bb) : '',
    stop_lose_table_bb: data ? String(data.stop_lose_table_bb) : '',
    stop_win_table_bb: data ? String(data.stop_win_table_bb) : '',
    max_swing_bb: data ? String(data.max_swing_bb) : '',
    effective_stack_limit_bb: data ? String(data.effective_stack_limit_bb) : '',
    max_stack_size_bb: data ? String(data.max_stack_size_bb) : '',
    mtt_max_rebuy: data ? String(data.mtt_max_rebuy) : '',
  };
};

export const getPayload = (args: { values: Fields; settings?: boolean }) => {
  const { values, settings } = args;

  const getBooleanPayload = (value: unknown) =>
    typeof value === 'boolean'
      ? value
      : Boolean(z.object({ value: z.number() }).parse(value).value);

  const settingsPayload = {
    rebuy_below_bb_min: convertStringToNumber(values.rebuy_below_bb_min),
    rebuy_below_bb_max: convertStringToNumber(values.rebuy_below_bb_max),
    rebuy_below_bb_straddle_table_min: convertStringToNumber(
      values.rebuy_below_bb_straddle_table_min
    ),
    rebuy_below_bb_straddle_table_max: convertStringToNumber(
      values.rebuy_below_bb_straddle_table_max
    ),
    stop_lose_bb_min: convertStringToNumber(values.stop_lose_bb_min),
    stop_lose_bb_max: convertStringToNumber(values.stop_lose_bb_max),
    stop_win_bb_min: convertStringToNumber(values.stop_win_bb_min),
    stop_win_bb_max: convertStringToNumber(values.stop_win_bb_max),
    stop_lose_table_bb_min: convertStringToNumber(values.stop_lose_table_bb_min),
    stop_lose_table_bb_max: convertStringToNumber(values.stop_lose_table_bb_max),
    stop_win_table_bb_min: convertStringToNumber(values.stop_win_table_bb_min),
    stop_win_table_bb_max: convertStringToNumber(values.stop_win_table_bb_max),
    max_swing_bb_min: convertStringToNumber(values.max_swing_bb_min),
    max_swing_bb_max: convertStringToNumber(values.max_swing_bb_max),
    effective_stack_limit_bb_min: convertStringToNumber(values.effective_stack_limit_bb_min),
    effective_stack_limit_bb_max: convertStringToNumber(values.effective_stack_limit_bb_max),
    max_stack_size_bb_min: convertStringToNumber(values.max_stack_size_bb_min),
    max_stack_size_bb_max: convertStringToNumber(values.max_stack_size_bb_max),
    mtt_max_rebuy_min: convertStringToNumber(values.mtt_max_rebuy_min),
    mtt_max_rebuy_max: convertStringToNumber(values.mtt_max_rebuy_max),
  };

  return {
    player_type: values.player_type?.value,
    buyin_bb_min: convertStringToNumber(values.buyin_bb_min),
    buyin_bb_max: convertStringToNumber(values.buyin_bb_max),
    buyin_step_bb: convertStringToNumber(values.buyin_step_bb),
    buyin_bb_straddle_table_min: convertStringToNumber(values.buyin_bb_straddle_table_min),
    buyin_bb_straddle_table_max: convertStringToNumber(values.buyin_bb_straddle_table_max),
    buyin_step_bb_straddle_table: convertStringToNumber(values.buyin_step_bb_straddle_table),
    rebuy_below_bb: convertStringToNumber(values.rebuy_below_bb),
    rebuy_below_bb_straddle_table: convertStringToNumber(values.rebuy_below_bb_straddle_table),
    stop_lose_bb: convertStringToNumber(values.stop_lose_bb),
    stop_win_bb: convertStringToNumber(values.stop_win_bb),
    stop_lose_table_bb: convertStringToNumber(values.stop_lose_table_bb),
    stop_win_table_bb: convertStringToNumber(values.stop_win_table_bb),
    max_swing_bb: convertStringToNumber(values.max_swing_bb),
    effective_stack_limit_bb: convertStringToNumber(values.effective_stack_limit_bb),
    max_stack_size_bb: convertStringToNumber(values.max_stack_size_bb),
    table_min_hands: convertStringToNumber(values.table_min_hands),
    mtt_max_rebuy: convertStringToNumber(values.mtt_max_rebuy),
    wait_for_big_blind: getBooleanPayload(values.wait_for_big_blind),
    mtt_addon: getBooleanPayload(values.mtt_addon),
    auto_rebuy: getBooleanPayload(values.auto_rebuy),
    seating_preference: values.seating_preference?.value,
    ...(settings && settingsPayload),
  };
};
