import { useTranslation } from 'react-i18next';
import { Outlet, useParams } from 'react-router-dom';
import { loadWorkspaceClubManagers } from '../../api';
import { WorkspaceClubManagerSchema } from '../../api/schemas/workspaceClubManagerSchema';
import { WorkspaceSchema } from '../../api/schemas/workspaceSchema';
import Card from '../../components/Card';
import Page from '../../components/Page';
import PageControls from '../../components/PageControls';
import PageTop from '../../components/PageTop';
import Table, { getTableProps } from '../../components/Table';
import Tag from '../../components/Tag';
import queryKeys from '../../config/queryKeys';
import routes from '../../config/routes';
import WorkspaceClubManagerBalance from '../../features/workspace-club-manager/WorkspaceClubManagerBalance';
import WorkspaceClubManagerCreateButton from '../../features/workspace-club-manager/WorkspaceClubManagerCreateButton';
import WorkspaceClubManagerDetailLink from '../../features/workspace-club-manager/WorkspaceClubManagerDetailLink';
import WorkspaceClubManagerDropdown from '../../features/workspace-club-manager/WorkspaceClubManagerDropdown';
import WorkspaceClubManagerFilter from '../../features/workspace-club-manager/WorkspaceClubManagerFilter';
import WorkspaceClubManagerStartButton from '../../features/workspace-club-manager/WorkspaceClubManagerStartButton';
import WorkspaceClubManagerStatus from '../../features/workspace-club-manager/WorkspaceClubManagerStatus';
import useBreakpoints from '../../hooks/useBreakpoints';
import usePaginatedDataNew from '../../hooks/usePaginatedDataNew';
import useURL from '../../hooks/useURL';
import useWebSocketPaginatedActions from '../../hooks/useWebSocketPaginatedActions';
import { RouteParams } from '../../utils/generics';
import { TRAINER_POKER_ROOM_LABELS } from '../../utils/trainer';
import Error403Page from '../Error403Page';
import styles from './styles.module.scss';

type Params = RouteParams<typeof routes.workspaceClubManager>;

interface Props {
  workspaceId: WorkspaceSchema['id'];
}

const WorkspaceClubManagersPage = (props: Props) => {
  const { workspaceId } = props;

  const { t } = useTranslation();
  const { isDesktop, isTablet, isPhone } = useBreakpoints();
  const { managerId } = useParams<Params>();
  const { urlParams } = useURL();
  const { search } = urlParams;

  const queryKey = [queryKeys.workspaceClubManagers({ workspaceId }), urlParams];

  const query = usePaginatedDataNew({
    queryKey,
    queryFn: (params) => loadWorkspaceClubManagers({ workspaceId }, { ...urlParams, ...params }),
  });

  const { data, error, checkIsInView } = query;

  const { checkIsCreated } = useWebSocketPaginatedActions<
    WorkspaceClubManagerSchema,
    WorkspaceClubManagerSchema
  >({
    queryKey,
    domain: 'Manager',
    updateAction: ['Edited', 'Started', 'Stopped', 'Online', 'Offline'],
    enabled: (message) => message.meta_info?.maintenance_id === workspaceId,
    select: (item) => item,
  });

  if (error?.response?.status === 403) return <Error403Page />;

  const renderClubs = (clubs: WorkspaceClubManagerSchema['clubs']) => {
    if (clubs.length) {
      return (
        <Tag count={clubs.length}>
          <Tag.FadingText text={clubs[0]} />
        </Tag>
      );
    }

    return '—';
  };

  return (
    <Page
      heading={t('common.managers')}
      tabs={[
        {
          to: routes.workspaceClubManagers({ workspaceId }),
          label: t('common.managers'),
          active: true,
        },
        {
          to: routes.workspaceClubMembers({ workspaceId }),
          label: t('common.members'),
        },
        {
          to: routes.workspaceClubWhitelist({ workspaceId }),
          label: t('common.whitelist'),
        },
        {
          to: routes.workspaceClubTransactions({ workspaceId }),
          label: t('common.transactions'),
        },
      ]}
      extra={
        <PageControls>
          <WorkspaceClubManagerCreateButton workspaceId={workspaceId} />
        </PageControls>
      }
      filter={<WorkspaceClubManagerFilter workspaceId={workspaceId} />}
      addon={<Outlet />}
    >
      {isPhone && (
        <PageTop
          sorting={[
            { key: 'login', title: t('common.login'), default: true },
            { key: 'created_on', title: t('common.created') },
            { key: 'status', title: t('common.status') },
            { key: 'poker_room', title: t('common.poker_room') },
            { key: 'balance', title: t('common.balance') },
          ]}
        />
      )}
      {(isDesktop || isTablet) && (
        <Table
          data={data}
          search={search}
          {...getTableProps(query)}
          className={styles.table}
          columns={[
            {
              key: 'login',
              title: t('common.login'),
              sorting: true,
              render: ({ values }) => (
                <WorkspaceClubManagerDetailLink data={values} workspaceId={workspaceId} />
              ),
            },
            {
              key: 'status',
              title: t('common.status'),
              sorting: true,
              render: ({ values }) => <WorkspaceClubManagerStatus data={values} />,
            },
            {
              key: 'poker_room',
              title: t('common.poker_room'),
              sorting: true,
              render: ({ values }) => TRAINER_POKER_ROOM_LABELS[values.room],
            },
            {
              title: t('common.clubs'),
              render: ({ values }) => renderClubs(values.clubs),
            },
            {
              key: 'balance',
              title: t('common.balance'),
              sorting: true,
              render: ({ values }) => <WorkspaceClubManagerBalance data={values} />,
            },
            {
              render: ({ values }) => (
                <div className={styles.buttons}>
                  <WorkspaceClubManagerStartButton data={values} workspaceId={workspaceId} />
                  <WorkspaceClubManagerDropdown data={values} workspaceId={workspaceId} />
                </div>
              ),
              align: 'right',
              controls: true,
            },
          ]}
          renderRow={(row, index) => (
            <Table.Row
              highlighted={checkIsCreated(row.data.id)}
              active={row.data.id === managerId}
              danger={row.data.is_deleted}
              {...checkIsInView(index)}
              {...row}
            />
          )}
        />
      )}
      {isPhone && (
        <Card.Group>
          {data.map((item, index) => (
            <Card
              key={item.id}
              highlighted={checkIsCreated(item.id)}
              danger={item.is_deleted}
              {...checkIsInView(index)}
            >
              <Card.Top>
                <WorkspaceClubManagerDetailLink data={item} workspaceId={workspaceId} />
                <Card.Top.Controls>
                  <WorkspaceClubManagerDropdown data={item} workspaceId={workspaceId} />
                </Card.Top.Controls>
              </Card.Top>
              <Card.Field.Group>
                <Card.Field label={t('common.poker_room')}>
                  {TRAINER_POKER_ROOM_LABELS[item.room]}
                </Card.Field>
                <Card.Field label={t('common.clubs')}>{renderClubs(item.clubs)}</Card.Field>
                <Card.Field label={t('common.balance')}>
                  <WorkspaceClubManagerBalance data={item} />
                </Card.Field>
              </Card.Field.Group>
            </Card>
          ))}
        </Card.Group>
      )}
    </Page>
  );
};

export default WorkspaceClubManagersPage;
