import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { deleteGlobalSeatControlRule } from '../../api';
import { SeatControlRuleSchema } from '../../api/schemas/seatControlRuleSchema';
import { presets } from '../../components/Dropdown';
import routes from '../../config/routes';
import useURL from '../../hooks/useURL';
import { RouteParams } from '../../utils/generics';
import notify from '../../utils/notify';
import { can } from '../permission/helpers';

type Params = RouteParams<typeof routes.seatConrolRule>;

interface Args {
  data: SeatControlRuleSchema;
}

const useDropdown = (args: Args) => {
  const { data } = args;

  const { id, name, is_deleted } = data;

  const { t } = useTranslation();
  const { navigate } = useURL();
  const { seatControlRuleId } = useParams<Params>();

  return {
    title: name,
    disabled: is_deleted,
    options: {
      edit: {
        ...presets.edit,
        onClick: () => navigate(routes.seatConrolRuleEdit({ seatControlRuleId: id })),
        disabled: !can.seatControlRule.createUpdate,
        ...(!can.seatControlRule.createUpdate && { tooltip: t('common.no_permission') }),
      },
      copy: presets.copyId(id),
      divider: { divider: true },
      delete: {
        ...presets.delete,
        onClick: async () => {
          await deleteGlobalSeatControlRule({ seatControlRuleId: id });

          if (id === seatControlRuleId) {
            navigate(routes.seatConrolRules);
          }

          notify('success', { title: t('sentences.record_has_been_deleted') });
        },
        disabled: !can.seatControlRule.delete,
        ...(!can.seatControlRule.delete && { tooltip: t('common.no_permission') }),
      },
    },
  };
};

export default useDropdown;
