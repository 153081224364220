import { useTranslation } from 'react-i18next';
import { WorkspaceSchema } from '../../api/schemas/workspaceSchema';
import Id from '../../components/Id';
import PageAddon from '../../components/PageAddon';
import ShowButton from '../../components/ShowButton';
import Tag from '../../components/Tag';
import { formatAbsoluteDate } from '../../utils/date';
import { TRAINER_POKER_ROOM_LABELS, TRAINER_TECH_LABELS } from '../../utils/trainer';
import { can } from '../permission/helpers';
import GlobalWorkspaceAutomining from './GlobalWorkspaceAutomining';
import GlobalWorkspaceDetailAddress from './GlobalWorkspaceDetailAddress';
import GlobalWorkspaceDetailBalance from './GlobalWorkspaceDetailBalance';
import { BUSINESS_MODEL_LABELS, WORKSPACE_TYPES_LABELS } from './helpers';

interface Props {
  data: WorkspaceSchema;
}

const GlobalWorkspaceDetailDetailsTab = (props: Props) => {
  const { data } = props;

  const { t } = useTranslation();

  const {
    id,
    poker_rooms,
    trainer_tech,
    maintenance_types,
    bar_seq,
    business_model,
    start_new_table,
    allow_negative_balance_pilot,
    hands_billing,
    created_on,
    modified_on,
  } = data;

  const getWorkspaceTypes = () => {
    if (!maintenance_types.length) return '—';

    return maintenance_types.map((item) => <Tag key={item}>{WORKSPACE_TYPES_LABELS[item]}</Tag>);
  };

  const getPokerRooms = () => {
    if (!poker_rooms.length) return '—';

    return poker_rooms.map((item) => <Tag key={item}>{TRAINER_POKER_ROOM_LABELS[item]}</Tag>);
  };

  const getTrainerTechnologies = () => {
    if (!trainer_tech.length) return '—';

    return trainer_tech.map((item) => <Tag key={item}>{TRAINER_TECH_LABELS[item]}</Tag>);
  };

  return (
    <PageAddon.Fields>
      <PageAddon.Id id={id} />
      <PageAddon.Field label={t('common.bar_sequence')}>
        {bar_seq ? <Id variant="primary" id={bar_seq} /> : '—'}
      </PageAddon.Field>
      <PageAddon.Field label={t('common.business_model')}>
        {BUSINESS_MODEL_LABELS[business_model]}
      </PageAddon.Field>
      <PageAddon.Field label={t('common.types')}>
        <PageAddon.Tags>{getWorkspaceTypes()}</PageAddon.Tags>
      </PageAddon.Field>
      <PageAddon.Field label={t('common.trainer_technologies')}>
        <PageAddon.Tags>{getTrainerTechnologies()}</PageAddon.Tags>
      </PageAddon.Field>
      <PageAddon.Field label={t('common.poker_rooms')}>
        <PageAddon.Tags>{getPokerRooms()}</PageAddon.Tags>
      </PageAddon.Field>
      {can.workspace.addressRead && (
        <PageAddon.Field label={t('common.address')}>
          <ShowButton>
            <GlobalWorkspaceDetailAddress workspaceId={id} />
          </ShowButton>
        </PageAddon.Field>
      )}
      <PageAddon.Field label={t('common.start_new_table')}>
        {start_new_table ? t('common.true') : t('common.false')}
      </PageAddon.Field>
      <PageAddon.Field label={t('common.disable_balance_check')}>
        {allow_negative_balance_pilot ? t('common.true') : t('common.false')}
      </PageAddon.Field>
      <PageAddon.Field label={t('common.hands_billing')}>
        {hands_billing ? t('common.true') : t('common.false')}
      </PageAddon.Field>
      <GlobalWorkspaceAutomining data={data} />
      {can.workspace.balanceRead && (
        <PageAddon.Collapse label={t('common.balance')}>
          <GlobalWorkspaceDetailBalance workspaceId={id} />
        </PageAddon.Collapse>
      )}
      <PageAddon.Field label={t('common.modified')}>
        {formatAbsoluteDate(modified_on)}
      </PageAddon.Field>
      <PageAddon.Field label={t('common.created')}>
        {formatAbsoluteDate(created_on)}
      </PageAddon.Field>
    </PageAddon.Fields>
  );
};

export default GlobalWorkspaceDetailDetailsTab;
