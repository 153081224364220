import { useTranslation } from 'react-i18next';
import { GlobalRoleSchema } from '../../api/schemas/globalRoleSchema';
import Button from '../../components/Button';
import PageAddon from '../../components/PageAddon';
import Tooltip from '../../components/Tooltip';
import icon from '../../static/icons';
import { formatAbsoluteDate } from '../../utils/date';
import { can } from '../permission/helpers';
import PermissionField from '../permission/PermissionField';
import GlobalRoleDetailDropdown from './GlobalRoleDetailDropdown';

interface Props {
  data: GlobalRoleSchema;
  onClose: () => void;
  onEdit: () => void;
}

const GlobalRoleDetail = (props: Props) => {
  const { data, onClose, onEdit } = props;

  const { t } = useTranslation();

  const { id, name, permissions, is_deleted, created_on, modified_on } = data;

  const titleIcons = is_deleted && icon('trash', 12);

  return (
    <PageAddon onClose={onClose} title={name} icons={titleIcons}>
      <PageAddon.Fields>
        <PageAddon.Id id={id} />
        <PermissionField permissions={permissions} />
        <PageAddon.Field label={t('common.modified')}>
          {formatAbsoluteDate(modified_on)}
        </PageAddon.Field>
        <PageAddon.Field label={t('common.created')}>
          {formatAbsoluteDate(created_on)}
        </PageAddon.Field>
      </PageAddon.Fields>
      {!is_deleted && (
        <PageAddon.Controls>
          <PageAddon.Controls.Group>
            <Tooltip
              label={can.role.createUpdate ? t('common.edit') : t('common.no_permission')}
              hideOnMobile={can.role.createUpdate}
            >
              <div>
                <Button
                  variant="transparent"
                  icon={icon('edit', 16)}
                  onClick={onEdit}
                  data-testid="edit"
                  disabled={!can.role.createUpdate}
                />
              </div>
            </Tooltip>
            <GlobalRoleDetailDropdown data={data} />
          </PageAddon.Controls.Group>
        </PageAddon.Controls>
      )}
    </PageAddon>
  );
};

export default GlobalRoleDetail;
