import { ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { PermissionSchema } from '../../../api/schemas/permissionSchema';
import Checkbox from '../../../components/Checkbox';
import Collapse from '../../../components/Collapse';
import { permissionsGroups } from '../helpers';
import styles from './styles.module.scss';

type Value = PermissionSchema[];

interface Props {
  value: Value;
  onChange: (value: Value) => void;
}

const PermissionFormField = (props: Props) => {
  const { value: permissions, onChange } = props;

  const { t } = useTranslation();

  return (
    <Collapse.Group>
      {permissionsGroups.map((item) => {
        const checkedPermissions = item.permissions.map((el) => permissions.includes(el));

        const checked = checkedPermissions.some((el) => el);
        const indeterminate = !checkedPermissions.every((el) => el);

        const changePermissionsGroups = (value: ChangeEvent<HTMLInputElement>) => {
          const newPermissions = value.target.checked
            ? [...permissions, ...item.permissions]
            : permissions.filter((permission) => !item.permissions.flat().includes(permission));

          onChange(newPermissions);
        };

        return (
          <Collapse
            key={item.label}
            label={
              <Checkbox
                indeterminate={indeterminate}
                checked={checked}
                onChange={changePermissionsGroups}
              >
                {item.label}
              </Checkbox>
            }
          >
            {item.permissions.map((el) => {
              const changePermissions = (value: ChangeEvent<HTMLInputElement>) => {
                const newPermissions = value.target.checked
                  ? [...permissions, el]
                  : permissions.filter((permission) => permission !== el);

                onChange(newPermissions);
              };

              return (
                <div key={el} className={styles.checkbox}>
                  <Checkbox checked={permissions.includes(el)} onChange={changePermissions}>
                    {t(`permissions.${el}`)}
                  </Checkbox>
                </div>
              );
            })}
          </Collapse>
        );
      })}
    </Collapse.Group>
  );
};

export default PermissionFormField;
