import { useTranslation } from 'react-i18next';
import { GlobalRoleSchema } from '../../api/schemas/globalRoleSchema';
import { GlobalUserSchema } from '../../api/schemas/globalUserSchema';
import Link from '../../components/Link';
import PageAddon from '../../components/PageAddon';
import Tag from '../../components/Tag';
import { formatAbsoluteDate } from '../../utils/date';

interface Props {
  data: GlobalUserSchema;
  getRoleRoute?: (args: { roleId: GlobalRoleSchema['id'] }) => string;
}

const GlobalUserDetailDetailsTab = (props: Props) => {
  const { data, getRoleRoute } = props;

  const { t } = useTranslation();

  const { id, roles, created_on } = data;

  const getRoles = () => {
    if (!roles.length) return '—';

    if (getRoleRoute) {
      return roles.map((item) => (
        <Link key={item.id} to={getRoleRoute({ roleId: item.id })}>
          <Tag>{item.name}</Tag>
        </Link>
      ));
    }

    return roles.map((item) => <Tag key={item.id}>{item.name}</Tag>);
  };

  return (
    <PageAddon.Fields>
      <PageAddon.Id id={id} />
      <PageAddon.Field label={t('common.roles')}>
        <PageAddon.Tags>{getRoles()}</PageAddon.Tags>
      </PageAddon.Field>
      <PageAddon.Field label={t('common.created')}>
        {formatAbsoluteDate(created_on)}
      </PageAddon.Field>
    </PageAddon.Fields>
  );
};

export default GlobalUserDetailDetailsTab;
