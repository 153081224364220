import clsx from 'clsx';
import { memo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { LobbySchema } from '../../../api/schemas/lobbySchema';
import { COLOR } from '../../../api/schemas/lobbyTableSchema';
import Link from '../../../components/Link';
import Tooltip from '../../../components/Tooltip';
import routes from '../../../config/routes';
import icon from '../../../static/icons';
import { RouteParams } from '../../../utils/generics';
import { TRAINER_GAME_TYPE_LABELS, TRAINER_LIMITS_LABELS } from '../../../utils/trainer';
import { can } from '../../permission/helpers';
import GlobalLobbyTablePlayers from '../GlobalLobbyTablePlayers';
import {
  checkIsCritical,
  checkIsWarning,
  checkIsOk,
  checkIsEmpty,
  /* checkIsBanned, */
} from '../helpers';
import styles from './styles.module.scss';

type Params = RouteParams<typeof routes.lobbyTable>;

const getTableStyles = (data: LobbySchema) => {
  if (checkIsCritical(data)) return styles.critical;
  if (checkIsWarning(data)) return styles.warning;
  if (checkIsOk(data)) return styles.ok;
  if (checkIsEmpty(data)) return styles.empty;
  /* if (checkIsBanned(data)) return styles.banned; */

  return undefined;
};

const COLORS: Record<LobbySchema['table']['color'], string> = {
  [COLOR.UNKNOWN]: styles.grey,
  [COLOR.GREEN]: styles.green,
  [COLOR.YELLOW]: styles.yellow,
  [COLOR.RED]: styles.red,
  [COLOR.PURPLE]: styles.purple,
};

interface Props {
  data: LobbySchema;
  index: number;
}

const GlobalLobbyTable = memo((props: Props) => {
  const { data, index } = props;

  const { table, accounts } = data;

  const { t } = useTranslation();
  const { tableId } = useParams<Params>();

  const { id, player_count, max_no_of_seats, game_type, comparative_limit, ante, currency, color } =
    table;

  const [visible, setVisible] = useState(false);

  const toggle = () => setVisible((prev) => !prev);

  const hasAccounts = accounts.length !== 0;
  const active = tableId === id;
  /* const isBanned = checkIsBanned(data); */

  return (
    <div
      style={{ zIndex: index }}
      className={clsx(styles.container, active && styles.active, getTableStyles(data))}
    >
      <div className={clsx(styles.table, COLORS[color])}>
        <div className={styles.info}>
          <Link to={routes.lobbyTable({ tableId: id })}>{id}</Link>
          <span className={styles.subInfo}>
            {TRAINER_LIMITS_LABELS[comparative_limit]}
            {` • ${TRAINER_GAME_TYPE_LABELS[game_type]}`}
            {` • ${currency}`}
            {ante ? ' • A' : ''}
          </span>
          <div className={styles.bottom}>
            <div className={styles.players}>
              {icon('users', 16)}
              <div>
                {hasAccounts && (
                  <>
                    <Tooltip label={t('common.accounts')}>
                      <span>{accounts.length}</span>
                    </Tooltip>
                    <span>&nbsp;/&nbsp;</span>
                  </>
                )}
                <Tooltip label={t('common.players')}>
                  <span>{player_count}</span>
                </Tooltip>
                <span>&nbsp;/&nbsp;</span>
                <Tooltip label={t('common.max_no_of_seats')}>
                  <span>{max_no_of_seats}</span>
                </Tooltip>
              </div>
            </div>
            {/* <div className={styles.icons}>{isBanned && icon('crossCircle', 16)}</div> */}
          </div>
        </div>
        {can.lobby.playersRead && (
          <button
            type="button"
            className={clsx(styles.toggle, visible && styles.active)}
            onClick={toggle}
          >
            {icon('chevronDown', 16)}
          </button>
        )}
      </div>
      {visible && (
        <div className={styles.popup}>
          <GlobalLobbyTablePlayers data={data} variant="primary" />
        </div>
      )}
    </div>
  );
});

export default GlobalLobbyTable;
