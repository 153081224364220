import routes from '../config/routes';
import { can } from '../features/permission/helpers';
import { t } from '../i18n';
import icon from '../static/icons';
import { useUserStore } from '../store/user';
import { addWhen } from '../utils';
import useURL from './useURL';

const useSidebarSection = () => {
  const user = useUserStore();
  const { navigate } = useURL();

  const getAccountsTo = () => {
    if (can.account.read) return routes.accounts;
    if (can.session.read) return routes.sessions;

    return routes.accounts;
  };

  const getMembersTo = () => {
    if (can.user.read) return routes.users;
    if (can.role.read) return routes.roles;

    return routes.users;
  };

  const sections = [
    ...addWhen(
      {
        to: routes.lobby,
        title: t('common.lobby'),
        icon: icon('lobby', 24),
      },
      can.lobby.read
    ),
    ...addWhen(
      {
        to: getAccountsTo(),
        title: t('common.accounts'),
        icon: icon('spadeMaps', 24),
      },
      can.account.read || can.session.read
    ),
    ...addWhen(
      {
        to: routes.globalWorkspaces,
        title: t('common.workspaces'),
        icon: icon('folder', 24),
      },
      can.workspace.read
    ),
    ...addWhen(
      {
        to: routes.orders,
        title: t('common.orders'),
        icon: icon('documents', 24),
      },
      can.order.read
    ),
    ...addWhen(
      {
        to: routes.deals,
        title: t('common.deals'),
        icon: icon('case', 24),
      },
      can.deal.read
    ),
    ...addWhen(
      {
        to: getMembersTo(),
        title: t('common.members'),
        icon: icon('users', 24),
      },
      can.user.read || can.role.read
    ),
    ...addWhen(
      {
        to: routes.seatConrolRules,
        title: t('common.seat_control_rules'),
        icon: icon('compasses', 24),
      },
      can.seatControlRule.read
    ),
  ];

  const collapsedSections = [
    ...addWhen(
      {
        to: routes.botSettings,
        title: t('common.bot_settings'),
      },
      can.botSetting.read
    ),

    ...addWhen(
      {
        to: routes.clubs,
        title: t('common.clubs'),
      },
      can.club.read
    ),
  ];

  const isWorkspaceExist = Boolean(user.workspaces && Object.keys(user.workspaces).length);
  const isPlatformSectionExist = Boolean(sections.length);
  const isSectionExists = isWorkspaceExist || isPlatformSectionExist;

  const moveToFirstSection = () => {
    if (isWorkspaceExist) {
      navigate(routes.home, { params: false });
    } else if (isPlatformSectionExist) {
      navigate(sections[0].to, { params: false });
    }
  };

  return { sections, collapsedSections, isSectionExists, moveToFirstSection };
};

export default useSidebarSection;
