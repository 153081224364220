import { useTranslation } from 'react-i18next';
import Radio from '../../Radio';
import styles from './styles.module.scss';

interface Props {
  value: string;
  onChange: (value: string) => void;
}

const FilterRadio = (props: Props) => {
  const { value, onChange } = props;

  const { t } = useTranslation();

  return (
    <div className={styles.container}>
      <Radio
        value="true"
        checked={value === 'true'}
        onChange={(event) => onChange(event.target.value)}
        className={styles.radio}
      >
        <span>{t('common.true')}</span>
      </Radio>
      <Radio
        value="false"
        checked={value === 'false'}
        onChange={(event) => onChange(event.target.value)}
        className={styles.radio}
      >
        <span>{t('common.false')}</span>
      </Radio>
    </div>
  );
};

export default FilterRadio;
