import ALIASES from '../../constants/ALIASES';
import api from '../../services/api';
import emptyResponseSchema from '../schemas/emptyResponseSchema';
import { WorkspaceSchema, WorkspaceTypes } from '../schemas/workspaceSchema';

type Schema = WorkspaceSchema;

interface Payload {
  business_model?: Schema['business_model'];
  maintenance_types?: WorkspaceTypes;
  poker_rooms?: Schema['poker_rooms'];
  trainer_tech?: Schema['trainer_tech'];
  bar_seq?: Schema['bar_seq'];
  start_new_table?: Schema['start_new_table'];
  allow_negative_balance_pilot?: Schema['allow_negative_balance_pilot'];
  auto_create_mining_session?: Schema['auto_create_mining_session'];
  hands_billing?: Schema['hands_billing'];
  mining_stage_ids?: Schema['mining_stages'][number]['id'][];
  mining_session_duration?: Schema['mining_session_duration'];
  mining_session_interval?: Schema['mining_session_interval'];
}

interface Args {
  workspaceId: Schema['id'];
  payload: Payload;
}

const updateGlobalWorkspace = async (args: Args) => {
  const { workspaceId, payload } = args;

  const response = await api.patch(`/api/global/${ALIASES.WORKSPACE.L}/${workspaceId}`, payload);

  return emptyResponseSchema.parse(response.data);
};

export default updateGlobalWorkspace;
