import clsx from 'clsx';
import { ReactNode, useEffect, useState } from 'react';
import staticIcon from '../../../static/icons';
import { useSidebarStore } from '../../../store/sidebar';
import styles from './styles.module.scss';

interface Props {
  label: string;
  icon: ReactNode;
  active: boolean;
  children: ReactNode;
}

const SidebarCollapse = (props: Props) => {
  const { label, icon, active, children } = props;

  const { open: sidebarOpen } = useSidebarStore((state) => ({
    open: state.open,
  }));

  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (!sidebarOpen && open) {
      setOpen(false);
    }
  }, [open, sidebarOpen]);

  const handleClick = () => setOpen((prev) => !prev);

  return (
    <>
      <button
        type="button"
        onClick={handleClick}
        className={clsx(styles.container, active && styles.active)}
      >
        <div className={clsx(styles.label, sidebarOpen && styles.sidebarOpen)}>
          {icon}
          <span>{label}</span>
        </div>
        {staticIcon('chevronDown', 16, {
          className: clsx(styles.icon, open && styles.open, sidebarOpen && styles.sidebarOpen),
        })}
      </button>
      {open && children}
    </>
  );
};

export default SidebarCollapse;
