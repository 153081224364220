import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  inviteGlobalUsers,
  inviteWorkspaceUsers,
  loadGlobalUsers,
  loadWorkspaceUsers,
} from '../../../api';
import Form from '../../../components/Form';
import PageAddon from '../../../components/PageAddon';
import Select from '../../../components/Select';
import SubmitButton from '../../../components/SubmitButton';
import routes from '../../../config/routes';
import useURL from '../../../hooks/useURL';
import { email } from '../../../utils/form';
import notify from '../../../utils/notify';
import styles from './styles.module.scss';
import { Fields } from './types';
import UserInviteFormField from './UserInviteFormField';

interface Props {
  workspaceId?: string;
  onClose: () => void;
}

const UserInviteForm = (props: Props) => {
  const { workspaceId, onClose } = props;

  const { t } = useTranslation();
  const { navigate } = useURL();

  const form = useForm<Fields>({ defaultValues: { email: null, users: [] } });

  const {
    control,
    setValue,
    setError,
    clearErrors,
    formState: { errors },
  } = form;

  const usersField = useFieldArray({ control, name: 'users' });

  const users = usersField.fields;

  const emails = users.map((item) => item.email);

  const onSubmit = async (values: Fields) => {
    if (users.length) {
      const workspaceUsers = values.users.map((item) => ({ email: item.email }));
      const globalUsers = values.users.map((item) => ({
        email: item.email,
        role_ids: item.role_ids.map((el) => el.value),
        maintenance_types: item.maintenance_types.map((el) => el.value),
      }));

      if (workspaceId) {
        const response = await inviteWorkspaceUsers({
          workspaceId,
          payload: workspaceUsers,
        });

        if (response.length === 1) {
          navigate(routes.workspaceUser({ workspaceId, userId: response[0].id }));
        }
      } else {
        const response = await inviteGlobalUsers({ payload: globalUsers });

        if (response.length === 1) {
          navigate(routes.user({ userId: response[0].id }));
        }
      }

      onClose();
      notify('success', { title: t('sentences.invitation_sent', { count: users.length }) });
    } else {
      setError('email', { message: t('validations.required') });
    }
  };

  return (
    <PageAddon onClose={onClose} title={t('common.invite_new_members')}>
      <Form form={form} onSubmit={onSubmit}>
        <Controller
          name="email"
          control={control}
          render={({ field }) => {
            if (workspaceId) {
              return (
                <Select.AsyncV2
                  onLoad={(args) =>
                    loadWorkspaceUsers({ workspaceId }, { ...args, sort_by: 'verified' })
                  }
                  select={(item) => ({
                    value: item.username,
                    label: item.username,
                    ...(item.verified && { caption: t('common.joined_the_workspace') }),
                    disabled: item.verified || emails.includes(item.username),
                  })}
                >
                  {({ options, ...rest }) => (
                    <Select
                      creatable
                      placeholder={`${t('common.email')}*`}
                      options={options}
                      value={field.value}
                      onChange={(value) => {
                        clearErrors();
                        field.onChange(value);

                        if (value) {
                          const emailValue = String(value.value).trim();

                          const emailValidation = email(emailValue);

                          if (typeof emailValidation === 'string') {
                            setError('email', { message: emailValidation });
                          }

                          if (emailValidation === true) {
                            if (emails.includes(emailValue)) {
                              setError('email', { message: t('validations.email_already_added') });
                              setValue('email', null);
                            } else {
                              usersField.append({
                                email: emailValue,
                                isReInvite: value.caption === t('common.invite_not_accepted'),
                                role_ids: [],
                                maintenance_types: [],
                              });

                              setValue('email', null);
                            }
                          }
                        }
                      }}
                      error={errors.email?.message}
                      {...rest}
                      filterOptions={!!workspaceId} // TODO: костыль на фронте, т.к. на бекенде не работает ?search
                    />
                  )}
                </Select.AsyncV2>
              );
            }

            return (
              <Select.Async
                onLoad={async (args) => {
                  const response = await loadGlobalUsers({ ...args, sort_by: 'verified' });

                  const options = response.items.map((item) => ({
                    value: item.username,
                    label: item.username,
                    caption: item.verified
                      ? t('common.invite_accepted')
                      : t('common.invite_not_accepted'),
                    disabled: item.verified || emails.includes(item.username),
                  }));

                  return options;
                }}
              >
                {({ options, ...rest }) => (
                  <Select
                    creatable
                    placeholder={`${t('common.email')}*`}
                    options={options}
                    value={field.value}
                    onChange={(value) => {
                      clearErrors();
                      field.onChange(value);

                      if (value) {
                        const emailValue = String(value.value).trim();

                        const emailValidation = email(emailValue);

                        if (typeof emailValidation === 'string') {
                          setError('email', { message: emailValidation });
                        }

                        if (emailValidation === true) {
                          if (emails.includes(emailValue)) {
                            setError('email', { message: t('validations.email_already_added') });
                            setValue('email', null);
                          } else {
                            usersField.append({
                              email: emailValue,
                              isReInvite: value.caption === t('common.invite_not_accepted'),
                              role_ids: [],
                              maintenance_types: [],
                            });

                            setValue('email', null);
                          }
                        }
                      }
                    }}
                    error={errors.email?.message}
                    {...rest}
                    filterOptions={!!workspaceId} // TODO: костыль на фронте, т.к. на бекенде не работает ?search
                  />
                )}
              </Select.Async>
            );
          }}
        />
        {Boolean(users.length) && (
          <div className={styles.users}>
            {users.map((user, index) => (
              <UserInviteFormField
                key={user.id}
                index={index}
                user={user}
                workspaceId={workspaceId}
                onRemove={() => usersField.remove(index)}
              />
            ))}
          </div>
        )}
        <PageAddon.Controls>
          <SubmitButton>{t('common.send')}</SubmitButton>
        </PageAddon.Controls>
      </Form>
    </PageAddon>
  );
};

export default UserInviteForm;
