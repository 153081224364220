import { RouteObject } from 'react-router-dom';
import routes from '../../config/routes';
import GlobalAccountDetail from '../../features/global-account/GlobalAccountDetail';
import GlobalAccountLoader from '../../features/global-account/GlobalAccountLoader';
import GlobalLobbyDetail from '../../features/global-lobby/GlobalLobbyDetail';
import GlobalLobbyLoader from '../../features/global-lobby/GlobalLobbyLoader';
import GlobalLobbyPage from '../../pages/GlobalLobbyPage';
import Route from './Route';

const page = <GlobalLobbyPage />;

const detail = (
  <Route params={['tableId']}>
    {({ tableId, navigate }) => (
      <GlobalLobbyLoader tableId={tableId}>
        {({ data }) => <GlobalLobbyDetail data={data} onClose={() => navigate(routes.lobby)} />}
      </GlobalLobbyLoader>
    )}
  </Route>
);

const detailAccount = (
  <Route params={['accountId', 'tableId']}>
    {({ accountId, tableId, navigate }) => (
      <GlobalAccountLoader accountId={accountId}>
        {({ data }) => (
          <GlobalAccountDetail
            data={data}
            onClose={() => navigate(routes.lobbyTable({ tableId }))}
          />
        )}
      </GlobalAccountLoader>
    )}
  </Route>
);

const globalLobbyPage: RouteObject = {
  path: routes.lobby,
  element: page,
  children: [
    {
      path: routes.lobbyTable({ tableId: ':tableId' }),
      element: detail,
      children: [
        {
          path: routes.lobbyTableAccount({
            tableId: ':tableId',
            accountId: ':accountId',
          }),
          element: detailAccount,
        },
      ],
    },
  ],
};

export default globalLobbyPage;
