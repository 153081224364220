import { useQuery } from '@tanstack/react-query';
import { ReactElement } from 'react';
import { loadGlobalBotSettingRule } from '../../api';
import { BotSettingRuleSchema } from '../../api/schemas/botSettingRuleSchema';
import PageAddon from '../../components/PageAddon';
import queryKeys from '../../config/queryKeys';
import useWebSocketMessage from '../../hooks/useWebSocketMessage';
import { updateDetailQueryData } from '../../utils/queryClient';

interface Props {
  botSettingRuleId: BotSettingRuleSchema['id'];
  children: (args: { data: BotSettingRuleSchema }) => ReactElement;
}

const GlobalBotSettingRuleLoader = (props: Props) => {
  const { botSettingRuleId, children } = props;

  const queryKey = [queryKeys.botSettingRule({ botSettingRuleId })];
  const queryFn = () => loadGlobalBotSettingRule({ botSettingRuleId });

  const { data, isPending } = useQuery({
    queryKey,
    queryFn,
    meta: { ignoredStatuses: [403] },
  });

  useWebSocketMessage<BotSettingRuleSchema>({
    domain: 'GlobalBotSettingsUsageRule',
    onMessage: (message) => {
      if (
        message.payload.id === botSettingRuleId &&
        (message.action === 'Edited' || message.action === 'Deleted')
      ) {
        updateDetailQueryData(queryKey, message.payload);
      }
    },
  });

  if (isPending) return <PageAddon.DetailPlaceholder />;
  if (!data) return null;

  return children({ data });
};

export default GlobalBotSettingRuleLoader;
