import { RouteObject } from 'react-router-dom';
import routes from '../../config/routes';
import GlobalRoleDetail from '../../features/global-role/GlobalRoleDetail';
import GlobalRoleForm from '../../features/global-role/GlobalRoleForm';
import GlobalRoleLoader from '../../features/global-role/GlobalRoleLoader';
import GlobalUserDetail from '../../features/global-user/GlobalUserDetail';
import GlobalUserForm from '../../features/global-user/GlobalUserForm';
import GlobalUserLoader from '../../features/global-user/GlobalUserLoader';
import GlobalWorkspaceDetail from '../../features/global-workspace/GlobalWorkspaceDetail';
import GlobalWorkspaceForm from '../../features/global-workspace/GlobalWorkspaceForm';
import GlobalWorkspaceLoader from '../../features/global-workspace/GlobalWorkspaceLoader';
import UserInviteForm from '../../features/user/UserInviteForm';
import GlobalUsersPage from '../../pages/GlobalUsersPage';
import Route from './Route';

const page = <GlobalUsersPage />;

const inviteForm = (
  <Route>{({ navigate }) => <UserInviteForm onClose={() => navigate(routes.users)} />}</Route>
);

const detail = (
  <Route params={['userId']}>
    {({ userId, navigate }) => (
      <GlobalUserLoader userId={userId}>
        {({ data }) => (
          <GlobalUserDetail
            data={data}
            onClose={() => navigate(routes.users)}
            onEdit={() => navigate(routes.userEdit({ userId }))}
            getRoleRoute={({ roleId }) => routes.userRole({ userId, roleId })}
            getWorkspaceRoute={({ workspaceId }) => routes.userWorkspace({ userId, workspaceId })}
          />
        )}
      </GlobalUserLoader>
    )}
  </Route>
);

const detailRole = (
  <Route params={['userId', 'roleId']}>
    {({ userId, roleId, navigate }) => (
      <GlobalRoleLoader roleId={roleId}>
        {({ data }) => (
          <GlobalRoleDetail
            data={data}
            onClose={() => navigate(routes.user({ userId }))}
            onEdit={() => navigate(routes.userRoleEdit({ userId, roleId }))}
          />
        )}
      </GlobalRoleLoader>
    )}
  </Route>
);

const detailRoleEditForm = (
  <Route params={['userId', 'roleId']}>
    {({ userId, roleId, navigate }) => (
      <GlobalRoleLoader roleId={roleId}>
        {({ data }) => (
          <GlobalRoleForm
            data={data}
            onClose={() => navigate(routes.userRole({ userId, roleId }))}
            onEdit={() => navigate(routes.userRole({ userId, roleId }))}
          />
        )}
      </GlobalRoleLoader>
    )}
  </Route>
);

const detailWorkspace = (
  <Route params={['userId', 'workspaceId']}>
    {({ userId, workspaceId, navigate }) => (
      <GlobalWorkspaceLoader workspaceId={workspaceId}>
        {({ data }) => (
          <GlobalWorkspaceDetail
            data={data}
            onClose={() => navigate(routes.user({ userId }))}
            onEdit={() => navigate(routes.userWorkspaceEdit({ userId, workspaceId }))}
          />
        )}
      </GlobalWorkspaceLoader>
    )}
  </Route>
);

const detailWorkspaceEditForm = (
  <Route params={['userId', 'workspaceId']}>
    {({ userId, workspaceId, navigate }) => (
      <GlobalWorkspaceLoader workspaceId={workspaceId}>
        {({ data, clubsData }) => (
          <GlobalWorkspaceForm
            data={data}
            clubsData={clubsData}
            onClose={() => navigate(routes.userWorkspace({ userId, workspaceId }))}
            onEdit={() => navigate(routes.userWorkspace({ userId, workspaceId }))}
          />
        )}
      </GlobalWorkspaceLoader>
    )}
  </Route>
);

const editForm = (
  <Route params={['userId']}>
    {({ userId, navigate }) => (
      <GlobalUserLoader userId={userId}>
        {({ data }) => (
          <GlobalUserForm data={data} onClose={() => navigate(routes.user({ userId }))} />
        )}
      </GlobalUserLoader>
    )}
  </Route>
);

const globalUsersPage: RouteObject = {
  path: routes.users,
  element: page,
  children: [
    {
      path: routes.usersInvite,
      element: inviteForm,
    },
    {
      path: routes.user({ userId: ':userId' }),
      element: detail,
      children: [
        {
          path: routes.userRole({ userId: ':userId', roleId: ':roleId' }),
          element: detailRole,
        },
        {
          path: routes.userRoleEdit({ userId: ':userId', roleId: ':roleId' }),
          element: detailRoleEditForm,
        },
        {
          path: routes.userWorkspace({ userId: ':userId', workspaceId: ':workspaceId' }),
          element: detailWorkspace,
        },
        {
          path: routes.userWorkspaceEdit({ userId: ':userId', workspaceId: ':workspaceId' }),
          element: detailWorkspaceEditForm,
        },
      ],
    },
    {
      path: routes.userEdit({ userId: ':userId' }),
      element: editForm,
    },
  ],
};

export default globalUsersPage;
