import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { createWorkspaceChannel } from '../../api';
import { ChannelSchema } from '../../api/schemas/channelSchema';
import { WorkspaceSchema } from '../../api/schemas/workspaceSchema';
import Form from '../../components/Form';
import Input from '../../components/Input';
import PageAddon from '../../components/PageAddon';
import PasswordInput from '../../components/PasswordInput';
import SubmitButton from '../../components/SubmitButton';
import { required, maxLength, minLength, max } from '../../utils/form';
import notify from '../../utils/notify';
import { getDefaultValues, getPayload } from './helpers';
import { Fields } from './types';

interface Props {
  workspaceId: WorkspaceSchema['id'];
  onClose: () => void;
  onCreate: (args: { channelId: ChannelSchema['id']; host: ChannelSchema['host'] }) => void;
}

const WorkspaceChannelForm = (props: Props) => {
  const { workspaceId, onClose, onCreate } = props;

  const { t } = useTranslation();

  const form = useForm<Fields>({ defaultValues: getDefaultValues() });

  const {
    control,
    register,
    formState: { errors },
  } = form;

  const onSubmit = async (values: Fields) => {
    const response = await createWorkspaceChannel({ workspaceId, payload: getPayload(values) });

    onCreate({ channelId: response.id, host: values.host });

    notify('success', { title: t('sentences.record_has_been_created') });
  };

  return (
    <PageAddon title={t('common.create_channel')} onClose={onClose}>
      <Form form={form} onSubmit={onSubmit}>
        <Input
          label={`${t('common.host')}*`}
          error={errors.host?.message}
          {...register('host', { validate: { required } })}
        />
        <Controller
          control={control}
          rules={{
            validate: {
              required,
              max: max(t('common.port'), 65535),
            },
          }}
          name="port"
          render={({ field }) => (
            <Input
              label={`${t('common.port')}*`}
              error={errors.port?.message}
              format={{
                thousandSeparator: false,
                onValueChange: field.onChange,
              }}
              {...field}
            />
          )}
        />
        <Input
          label={`${t('common.username')}*`}
          error={errors.username?.message}
          {...register('username', {
            validate: {
              required,
              minLength: minLength(4),
              maxLength: maxLength(),
            },
          })}
        />
        <PasswordInput
          label={`${t('common.password')}*`}
          error={errors.password?.message}
          {...register('password', {
            validate: { required, minLength: minLength(4) },
          })}
        />
        <PageAddon.Controls>
          <SubmitButton>{t('common.create')}</SubmitButton>
        </PageAddon.Controls>
      </Form>
    </PageAddon>
  );
};

export default WorkspaceChannelForm;
