import { RouteObject } from 'react-router-dom';
import routes from '../../config/routes';
import GlobalAccountDetail from '../../features/global-account/GlobalAccountDetail';
import GlobalAccountLoader from '../../features/global-account/GlobalAccountLoader';
import GlobalAccountSchedule from '../../features/global-account/GlobalAccountSchedule';
import GlobalAccountTransactions from '../../features/global-account/GlobalAccountTransactions';
import GlobalUserDetail from '../../features/global-user/GlobalUserDetail';
import GlobalUserForm from '../../features/global-user/GlobalUserForm';
import GlobalUserLoader from '../../features/global-user/GlobalUserLoader';
import GlobalAccountsPage from '../../pages/GlobalAccountsPage';
import Route from './Route';

const page = <GlobalAccountsPage />;

const detail = (
  <Route params={['accountId']}>
    {({ accountId, navigate }) => (
      <GlobalAccountLoader accountId={accountId}>
        {({ data }) => (
          <GlobalAccountDetail
            data={data}
            onClose={() => navigate(routes.accounts)}
            onTransactionsOpen={({ type, id }) =>
              navigate(routes.accountTransactions({ accountId, type, id }))
            }
            getUserRoute={({ userId }) => routes.accountUser({ accountId, userId })}
          />
        )}
      </GlobalAccountLoader>
    )}
  </Route>
);

const detailTransactions = (
  <Route params={['accountId']}>
    {({ accountId, navigate }) => (
      <GlobalAccountTransactions
        accountId={accountId}
        onClose={() => navigate(routes.account({ accountId }))}
      />
    )}
  </Route>
);

const detailUser = (
  <Route params={['accountId', 'userId']}>
    {({ accountId, userId, navigate }) => (
      <GlobalUserLoader userId={userId}>
        {({ data }) => (
          <GlobalUserDetail
            data={data}
            onClose={() => navigate(routes.account({ accountId }))}
            onEdit={() => navigate(routes.accountUserEdit({ accountId, userId }))}
          />
        )}
      </GlobalUserLoader>
    )}
  </Route>
);

const detailUserEditForm = (
  <Route params={['accountId', 'userId']}>
    {({ accountId, userId, navigate }) => (
      <GlobalUserLoader userId={userId}>
        {({ data }) => (
          <GlobalUserForm
            data={data}
            onClose={() => navigate(routes.accountUser({ accountId, userId }))}
          />
        )}
      </GlobalUserLoader>
    )}
  </Route>
);

const schedule = (
  <Route params={['accountId']}>
    {({ accountId }) => <GlobalAccountSchedule accountId={accountId} />}
  </Route>
);

const globalAccountsPage: RouteObject = {
  path: routes.accounts,
  element: page,
  children: [
    {
      path: routes.account({ accountId: ':accountId' }),
      element: detail,
      children: [
        {
          path: routes.accountTransactions({ accountId: ':accountId', type: ':type', id: ':id?' }),
          element: detailTransactions,
        },
        {
          path: routes.accountUser({ accountId: ':accountId', userId: ':userId' }),
          element: detailUser,
        },
        {
          path: routes.accountUserEdit({ accountId: ':accountId', userId: ':userId' }),
          element: detailUserEditForm,
        },
        {
          path: routes.accountSchedule({ accountId: ':accountId' }),
          element: schedule,
        },
      ],
    },
  ],
};

export default globalAccountsPage;
