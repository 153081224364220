import { useTranslation } from 'react-i18next';
import { GlobalClubSchema } from '../../../api/schemas/globalClubSchema';
import { WorkspaceSchema } from '../../../api/schemas/workspaceSchema';
import Collapse from '../../../components/Collapse';
import Empty from '../../../components/Empty';
import { formatAbsoluteDate } from '../../../utils/date';
import { TRAINER_POKER_ROOM_LABELS } from '../../../utils/trainer';
import { can } from '../../permission/helpers';
import { useGlobalWorkspaceClubsQuery } from '../queries';
import styles from './styles.module.scss';

interface Props {
  workspaceId: WorkspaceSchema['id'];
  getClubRoute?: (args: { clubId: GlobalClubSchema['id'] }) => string;
}

const GlobalWorkspaceDetailClubsTab = (props: Props) => {
  const { workspaceId, getClubRoute } = props;

  const { t } = useTranslation();

  const { data } = useGlobalWorkspaceClubsQuery({ workspaceId });

  const getContent = () => {
    if (!data.length) return <Empty />;

    return (
      <Collapse.Group>
        {data.map((item) => (
          <Collapse
            key={item.id}
            label={item.name}
            caption={<Collapse.Caption>{item.code}</Collapse.Caption>}
            {...(can.club.read && getClubRoute && { to: getClubRoute({ clubId: item.id }) })}
          >
            <Collapse.InfoField label={t('common.poker_room')}>
              {TRAINER_POKER_ROOM_LABELS[item.poker_room]}
            </Collapse.InfoField>
            {item.league && (
              <Collapse.InfoField label={t('common.league')}>{item.league.name}</Collapse.InfoField>
            )}
            {item.league && item.league.super_league && (
              <Collapse.InfoField label={t('common.super_league')}>
                {item.league.super_league.name}
              </Collapse.InfoField>
            )}
            <Collapse.InfoField label={t('common.modified')}>
              {formatAbsoluteDate(item.modified_on)}
            </Collapse.InfoField>
            <Collapse.InfoField label={t('common.created')}>
              {formatAbsoluteDate(item.created_on)}
            </Collapse.InfoField>
          </Collapse>
        ))}
      </Collapse.Group>
    );
  };

  return <div className={styles.container}>{getContent()}</div>;
};

export default GlobalWorkspaceDetailClubsTab;
