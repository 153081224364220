import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { loadGlobalWorkspaceBalance } from '../../../api';
import { WorkspaceBalanceSchema } from '../../../api/schemas/workspaceBalanceSchema';
import { WorkspaceSchema } from '../../../api/schemas/workspaceSchema';
import PageAddon from '../../../components/PageAddon';
import queryKeys from '../../../config/queryKeys';
import useWebSocketMessage from '../../../hooks/useWebSocketMessage';
import { convertCentsToUSDT } from '../../../utils/data';
import { updateDetailQueryData } from '../../../utils/queryClient';

interface Props {
  workspaceId: WorkspaceSchema['id'];
}

const GlobalWorkspaceDetailBalance = (props: Props) => {
  const { workspaceId } = props;

  const { t } = useTranslation();

  const queryKey = [queryKeys.globalWorkspaceBalance({ workspaceId })];
  const queryFn = () => loadGlobalWorkspaceBalance({ workspaceId });

  const { data, isPending, isError, refetch } = useQuery({
    queryKey,
    queryFn,
  });

  useWebSocketMessage<WorkspaceBalanceSchema>({
    domain: 'GlobalMaintenance',
    onMessage: (message) => {
      if (message.action === 'BalanceState' && message.payload.id === workspaceId) {
        updateDetailQueryData(queryKey, message.payload);
      }
    },
  });

  if (isError) return <PageAddon.Collapse.Error onClick={() => refetch()} />;

  return (
    <>
      <PageAddon.Collapse.Field loading={isPending} label={t('common.balance_USDT')}>
        {data && convertCentsToUSDT(data.balance)}
      </PageAddon.Collapse.Field>
      <PageAddon.Collapse.Field loading={isPending} label={t('common.hold_USDT')}>
        {data && convertCentsToUSDT(data.balance_hold)}
      </PageAddon.Collapse.Field>
    </>
  );
};

export default GlobalWorkspaceDetailBalance;
