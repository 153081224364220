import { GlobalSessionSchema } from '../../../api/schemas/session/globalSessionSchema';
import PageDetailLink from '../../../components/PageDetailLink';
import routes from '../../../config/routes';
import { getTitle } from '../../session/helpers';
import styles from './styles.module.scss';

interface Props {
  data: GlobalSessionSchema;
}

const GlobalSessionDetailLink = (props: Props) => {
  const { data } = props;

  const { id, start, end, account } = data;

  const { mga_id } = account;

  const label = getTitle({ start, end });

  return (
    <div className={styles.container}>
      <PageDetailLink label={label} to={routes.session({ accountId: account.id, sessionId: id })} />
      <span>{mga_id}</span>
    </div>
  );
};

export default GlobalSessionDetailLink;
