import { useTranslation } from 'react-i18next';
import { Outlet } from 'react-router-dom';
import { GlobalRoleSchema } from '../../api/schemas/globalRoleSchema';
import { GlobalUserSchema } from '../../api/schemas/globalUserSchema';
import { WorkspaceSchema } from '../../api/schemas/workspaceSchema';
import Button from '../../components/Button';
import Confirm from '../../components/Confirm';
import PageAddon from '../../components/PageAddon';
import Tooltip from '../../components/Tooltip';
import icon from '../../static/icons';
import { can } from '../permission/helpers';
import GlobalUserDetailDetailsTab from './GlobalUserDetailDetailsTab';
import GlobalUserDetailWorkspacesTab from './GlobalUserDetailWorkspacesTab';
import { inviteUser, resetPassword } from './helpers';

interface Props {
  data: GlobalUserSchema;
  onClose: () => void;
  onEdit: () => void;
  getRoleRoute?: (args: { roleId: GlobalRoleSchema['id'] }) => string;
  getWorkspaceRoute?: (args: { workspaceId: WorkspaceSchema['id'] }) => string;
}

const GlobalUserDetail = (props: Props) => {
  const { data, onClose, onEdit, getRoleRoute, getWorkspaceRoute } = props;

  const { t } = useTranslation();

  const { id, username, is_deleted, verified } = data;

  const titleIcons = (
    <>
      {is_deleted && icon('trash', 12)}
      {!verified && <Tooltip label={t('common.invite_not_accepted')}>{icon('clock', 12)}</Tooltip>}
    </>
  );

  return (
    <PageAddon.Group>
      <Outlet />
      <PageAddon
        title={username}
        icons={titleIcons}
        onClose={onClose}
        tabs={[
          {
            label: t('common.details'),
            content: <GlobalUserDetailDetailsTab data={data} getRoleRoute={getRoleRoute} />,
          },
          {
            label: t('common.workspaces'),
            content: (
              <GlobalUserDetailWorkspacesTab data={data} getWorkspaceRoute={getWorkspaceRoute} />
            ),
          },
        ]}
      >
        {!is_deleted && (
          <PageAddon.Controls>
            <PageAddon.Controls.Group>
              {!verified && (
                <Tooltip
                  label={
                    can.user.createUpdate ? t('common.send_invite_link') : t('common.no_permission')
                  }
                  hideOnMobile={can.user.createUpdate}
                >
                  <Confirm
                    danger={false}
                    confirmText={t('common.send')}
                    onConfirm={() => inviteUser({ email: username })}
                  >
                    {({ confirming }) => (
                      <Button
                        focused={confirming}
                        icon={icon('mail', 16)}
                        variant="transparent"
                        data-testid="send-invite-link"
                        disabled={!can.user.createUpdate}
                      />
                    )}
                  </Confirm>
                </Tooltip>
              )}
              <Tooltip
                label={
                  can.user.createUpdate ? t('common.reset_password') : t('common.no_permission')
                }
                hideOnMobile={can.user.createUpdate}
              >
                <Confirm
                  danger={false}
                  confirmText={t('common.reset')}
                  onConfirm={() => resetPassword({ id, username })}
                >
                  {() => (
                    <Button
                      icon={icon('unlock', 16)}
                      variant="transparent"
                      data-testid="reset-password"
                      disabled={!can.user.createUpdate}
                    />
                  )}
                </Confirm>
              </Tooltip>
              <Tooltip
                label={
                  can.user.createUpdate && can.role.read
                    ? t('common.edit')
                    : t('common.no_permission')
                }
                hideOnMobile={can.user.createUpdate && can.role.read}
              >
                <div>
                  <Button
                    variant="transparent"
                    icon={icon('edit', 16)}
                    onClick={onEdit}
                    data-testid="edit"
                    disabled={!can.user.createUpdate || !can.role.read}
                  />
                </div>
              </Tooltip>
            </PageAddon.Controls.Group>
          </PageAddon.Controls>
        )}
      </PageAddon>
    </PageAddon.Group>
  );
};

export default GlobalUserDetail;
