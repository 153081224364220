import { useQuery } from '@tanstack/react-query';
import { loadGlobalAccountStrategyProfile } from '../../api';
import { GlobalAccountSchema } from '../../api/schemas/account/globalAccountSchema';
import PageAddon from '../../components/PageAddon';
import queryKeys from '../../config/queryKeys';
import AccountStrategyProfile from '../account/AccountStrategyProfile';

interface Props {
  account: GlobalAccountSchema;
}

const GlobalAccountDetailStrategyProfileTab = (props: Props) => {
  const { account } = props;

  const { id: accountId } = account;

  const queryKey = [queryKeys.accountStrategyProfile({ accountId })];
  const queryFn = () => loadGlobalAccountStrategyProfile({ accountId });

  const { data, isPending } = useQuery({
    queryKey,
    queryFn,
    meta: { ignoredStatuses: [403] },
  });

  if (isPending) return <PageAddon.DetailPlaceholder wrapper={false} />;
  if (!data) return null;

  return <AccountStrategyProfile disabled data={data} />;
};

export default GlobalAccountDetailStrategyProfileTab;
