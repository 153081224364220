import {
  useClick,
  useDismiss,
  useFloating,
  useInteractions,
  flip,
  offset,
  autoUpdate,
  FloatingOverlay,
  FloatingPortal,
} from '@floating-ui/react';
import { forwardRef, useState } from 'react';
import { DAY, DAY_TIME } from '../../constants/DATE_FORMATS';
import useBreakpoints from '../../hooks/useBreakpoints';
import icon from '../../static/icons';
import Input from '../Input';
import { DatePickerProvider } from './DatePickerContext';
import DatePickerDays from './DatePickerDays';
import DatePickerFooter from './DatePickerFooter';
import DatePickerHeader from './DatePickerHeader';
import DatePickerInput from './DatePickerInput';
import DatePickerMonths from './DatePickerMonths';
import DatePickerNavigation from './DatePickerNavigation';
import DatePickerTime from './DatePickerTime';
import DatePickerYears from './DatePickerYears';
import styles from './styles.module.scss';
import { Props } from './types';
import useDatePicker from './useDatePicker';

const OFFSET = 4;

const DatePicker = forwardRef<HTMLInputElement, Props>((props, ref) => {
  const { value, size, label, disabled, placeholder, error, withTime, onChange, disabledDate } =
    props;

  const format = withTime ? DAY_TIME : DAY;

  const { isDesktop, isTablet, isPhone } = useBreakpoints();
  const [open, setOpen] = useState(false);
  const datePicker = useDatePicker({ value, open, format, onChange });

  const { refs, context, floatingStyles } = useFloating({
    open,
    strategy: 'fixed',
    placement: 'bottom-start',
    onOpenChange: setOpen,
    whileElementsMounted: autoUpdate,
    middleware: [
      offset({ mainAxis: OFFSET }),
      flip({ fallbackPlacements: ['bottom-end', 'top-start', 'top-end', 'right', 'left'] }),
    ],
  });

  const { getReferenceProps, getFloatingProps } = useInteractions([
    useClick(context),
    useDismiss(context, {
      outsidePressEvent: 'click',
    }),
  ]);

  const { picker } = datePicker;

  const content = (
    <div
      ref={refs.setFloating}
      className={styles.container}
      {...getFloatingProps()}
      {...((isDesktop || isTablet) && { style: floatingStyles })}
    >
      <div className={styles.wrapper}>
        <div className={styles.datePicker}>
          {isPhone && <DatePickerHeader label={label} placeholder={placeholder} />}
          <DatePickerNavigation />
          {picker === 'day' && <DatePickerDays withTime={withTime} disabledDate={disabledDate} />}
          {picker === 'month' && <DatePickerMonths />}
          {picker === 'year' && <DatePickerYears />}
        </div>
        {withTime && <DatePickerTime />}
      </div>
      <DatePickerFooter />
    </div>
  );

  const closeDatePicker = () => setOpen(false);

  return (
    <DatePickerProvider {...datePicker} closeDatePicker={closeDatePicker}>
      <DatePickerInput
        ref={ref}
        size={size}
        label={label}
        disabled={disabled}
        placeholder={placeholder}
        error={error}
        extra={
          <Input.Button
            ref={refs.setReference}
            variant="bordered"
            disabled={disabled}
            {...getReferenceProps()}
          >
            {icon('calendar', 20)}
          </Input.Button>
        }
      />
      <FloatingPortal id="root">
        {open &&
          (isDesktop || isTablet ? (
            content
          ) : (
            <FloatingOverlay lockScroll className={styles.overlay}>
              {content}
            </FloatingOverlay>
          ))}
      </FloatingPortal>
    </DatePickerProvider>
  );
});

export default DatePicker;
