import { RouteObject } from 'react-router-dom';
import routes from '../../config/routes';
import GlobalAccountDetail from '../../features/global-account/GlobalAccountDetail';
import GlobalAccountLoader from '../../features/global-account/GlobalAccountLoader';
import GlobalBotSettingDeleteError from '../../features/global-bot-setting/GlobalBotSettingDeleteError';
import GlobalBotSettingDetail from '../../features/global-bot-setting/GlobalBotSettingDetail';
import GlobalBotSettingForm from '../../features/global-bot-setting/GlobalBotSettingForm';
import GlobalBotSettingLoader from '../../features/global-bot-setting/GlobalBotSettingLoader';
import GlobalBotSettingRuleDetail from '../../features/global-bot-setting-rule/GlobalBotSettingRuleDetail';
import GlobalBotSettingRuleForm from '../../features/global-bot-setting-rule/GlobalBotSettingRuleForm';
import GlobalBotSettingRuleLoader from '../../features/global-bot-setting-rule/GlobalBotSettingRuleLoader';
import GlobalBotSettingsPage from '../../pages/GlobalBotSettingsPage';
import Route from './Route';

const page = <GlobalBotSettingsPage />;

const createForm = (
  <Route>
    {({ navigate }) => (
      <GlobalBotSettingForm
        onClose={() => navigate(routes.botSettings)}
        onCreate={({ botSettingId }) => navigate(routes.botSetting({ botSettingId }))}
      />
    )}
  </Route>
);

const detail = (
  <Route params={['botSettingId']}>
    {({ botSettingId, navigate }) => (
      <GlobalBotSettingLoader botSettingId={botSettingId}>
        {({ data }) => (
          <GlobalBotSettingDetail
            data={data}
            onClose={() => navigate(routes.botSettings)}
            onEdit={() => navigate(routes.botSettingEdit({ botSettingId }))}
          />
        )}
      </GlobalBotSettingLoader>
    )}
  </Route>
);

const deleteError = (
  <Route params={['botSettingId']}>
    {({ botSettingId, navigate }) => (
      <GlobalBotSettingLoader botSettingId={botSettingId}>
        {({ data }) => (
          <GlobalBotSettingDeleteError
            data={data}
            onClose={() => navigate(routes.botSetting({ botSettingId }))}
          />
        )}
      </GlobalBotSettingLoader>
    )}
  </Route>
);

const deleteErrorAccount = (
  <Route params={['botSettingId', 'accountId']}>
    {({ botSettingId, accountId, navigate }) => (
      <GlobalAccountLoader accountId={accountId}>
        {(args) => (
          <GlobalAccountDetail
            data={args.data}
            onClose={() => navigate(routes.botSettingError({ botSettingId }))}
          />
        )}
      </GlobalAccountLoader>
    )}
  </Route>
);

const deleteErrorBotSettingRule = (
  <Route params={['botSettingId', 'botSettingRuleId']}>
    {({ botSettingId, botSettingRuleId, navigate }) => (
      <GlobalBotSettingRuleLoader botSettingRuleId={botSettingRuleId}>
        {(args) => (
          <GlobalBotSettingRuleDetail
            data={args.data}
            onClose={() => navigate(routes.botSettingError({ botSettingId }))}
            onEdit={() =>
              navigate(
                routes.botSettingErrorBotSettingRuleEdit({
                  botSettingId,
                  botSettingRuleId,
                })
              )
            }
          />
        )}
      </GlobalBotSettingRuleLoader>
    )}
  </Route>
);

const deleteErrorBotSettingRuleEditForm = (
  <Route params={['botSettingId', 'botSettingRuleId']}>
    {({ botSettingId, botSettingRuleId, navigate }) => (
      <GlobalBotSettingRuleLoader botSettingRuleId={botSettingRuleId}>
        {(args) => (
          <GlobalBotSettingRuleForm
            data={args.data}
            onClose={() =>
              navigate(routes.botSettingErrorBotSettingRule({ botSettingId, botSettingRuleId }))
            }
            onEdit={() =>
              navigate(routes.botSettingErrorBotSettingRule({ botSettingId, botSettingRuleId }))
            }
          />
        )}
      </GlobalBotSettingRuleLoader>
    )}
  </Route>
);

const editForm = (
  <Route params={['botSettingId']}>
    {({ botSettingId, navigate }) => (
      <GlobalBotSettingLoader botSettingId={botSettingId}>
        {({ data }) => (
          <GlobalBotSettingForm
            data={data}
            onClose={() => navigate(routes.botSetting({ botSettingId }))}
            onEdit={() => navigate(routes.botSetting({ botSettingId }))}
          />
        )}
      </GlobalBotSettingLoader>
    )}
  </Route>
);

const globalBotSettingsPage: RouteObject = {
  path: routes.botSettings,
  element: page,
  children: [
    {
      path: routes.botSettingsCreate,
      element: createForm,
    },
    {
      path: routes.botSetting({ botSettingId: ':botSettingId' }),
      element: detail,
    },
    {
      path: routes.botSettingError({ botSettingId: ':botSettingId' }),
      element: deleteError,
      children: [
        {
          path: routes.botSettingErrorAccount({
            botSettingId: ':botSettingId',
            accountId: ':accountId',
          }),
          element: deleteErrorAccount,
        },
        {
          path: routes.botSettingErrorBotSettingRule({
            botSettingId: ':botSettingId',
            botSettingRuleId: ':botSettingRuleId',
          }),
          element: deleteErrorBotSettingRule,
        },
        {
          path: routes.botSettingErrorBotSettingRuleEdit({
            botSettingId: ':botSettingId',
            botSettingRuleId: ':botSettingRuleId',
          }),
          element: deleteErrorBotSettingRuleEditForm,
        },
      ],
    },
    {
      path: routes.botSettingEdit({ botSettingId: ':botSettingId' }),
      element: editForm,
    },
  ],
};

export default globalBotSettingsPage;
