import { useTranslation } from 'react-i18next';
import { updateWorkspaceStrategyProfile } from '../../../api';
import { WorkspaceSchema } from '../../../api/schemas/workspaceSchema';
import { WorkspaceSettingsStrategyProfileSchema } from '../../../api/schemas/workspaceSettingsStrategyProfileSchema';
import useQuickForm from '../../../hooks/useQuickForm';
import { useUserStore } from '../../../store/user';
import { handleError } from '../../../utils/form';
import { Fields, getFields, getPayload, getDefaultValues } from '../../account/strategyHelpers';
import styles from './styles.module.scss';

const INPUT_PADDINGS = 9;
const MAX_WIDTH = 80;

interface Props {
  data: WorkspaceSettingsStrategyProfileSchema;
  workspaceId: WorkspaceSchema['id'];
}

const WorkspaceSettingsStrategyProfile = (props: Props) => {
  const { data, workspaceId } = props;

  const { t } = useTranslation();

  const user = useUserStore();

  const owner = user.checkOwner(workspaceId);

  const form = useQuickForm<Fields>({
    data,
    defaultValues: {
      ...getDefaultValues({ data, settings: true }),
      rebuy_below_bb_min: String(data.rebuy_below_bb_min),
      rebuy_below_bb_max: String(data.rebuy_below_bb_max),
      rebuy_below_bb_straddle_table_min: String(data.rebuy_below_bb_straddle_table_min),
      rebuy_below_bb_straddle_table_max: String(data.rebuy_below_bb_straddle_table_max),
      stop_lose_bb_min: String(data.stop_lose_bb_min),
      stop_lose_bb_max: String(data.stop_lose_bb_max),
      stop_win_bb_min: String(data.stop_win_bb_min),
      stop_win_bb_max: String(data.stop_win_bb_max),
      stop_lose_table_bb_min: String(data.stop_lose_table_bb_min),
      stop_lose_table_bb_max: String(data.stop_lose_table_bb_max),
      stop_win_table_bb_min: String(data.stop_win_table_bb_min),
      stop_win_table_bb_max: String(data.stop_win_table_bb_max),
      max_swing_bb_min: String(data.max_swing_bb_min),
      max_swing_bb_max: String(data.max_swing_bb_max),
      effective_stack_limit_bb_min: String(data.effective_stack_limit_bb_min),
      effective_stack_limit_bb_max: String(data.effective_stack_limit_bb_max),
      max_stack_size_bb_min: String(data.max_stack_size_bb_min),
      max_stack_size_bb_max: String(data.max_stack_size_bb_max),
    },
  });

  const onSubmit = async (values: Fields) => {
    try {
      await updateWorkspaceStrategyProfile({
        workspaceId,
        payload: getPayload({ values, settings: true }),
      });
    } catch (error) {
      handleError({ error, form });
    }
  };

  const fields = getFields({
    form,
    onSubmit,
    selectProps: { disabled: !owner, popupSize: 'auto' },
    inputProps: {
      disabled: !owner,
      className: styles.input,
      setInputWidth: (width: number) =>
        width + INPUT_PADDINGS < MAX_WIDTH ? width + INPUT_PADDINGS : MAX_WIDTH,
    },
  });

  const divider = <span className={styles.divider}>—</span>;

  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        <div>
          <span>{t('common.player_type')}</span>
          <div>{fields.player_type}</div>
        </div>
        <div>
          <span>{t('common.seat')}</span>
          <div>{fields.seating_preference}</div>
        </div>
        <div>
          <span>{t('common.min_hands')}</span>
          <div>{fields.table_min_hands}</div>
        </div>
        <div>
          <span>{t('common.auto_rebuy')}</span>
          <div>{fields.auto_rebuy}</div>
        </div>
        <div>
          <span>{t('common.wait_for_bb')}</span>
          <div>{fields.wait_for_big_blind}</div>
        </div>
      </div>
      <div className={styles.wrapper}>
        <div className={styles.heading}>{t('sentences.buy_in_regular')}</div>
        <div>
          <span>{t('common.buy_in_min_bb')}</span>
          <div>{fields.buyin_bb_min}</div>
        </div>
        <div>
          <span>{t('common.buy_in_max_bb')}</span>
          <div>{fields.buyin_bb_max}</div>
        </div>
        <div>
          <span>{t('common.step_bb')}</span>
          <div>{fields.buyin_step_bb}</div>
        </div>
        <div>
          <span>{t('common.rebuy_threshold_bb')}</span>
          <div>
            <div>{fields.rebuy_below_bb_min}</div>
            <div>{divider}</div>
            <div>{fields.rebuy_below_bb_max}</div>
          </div>
        </div>
      </div>
      <div className={styles.wrapper}>
        <div className={styles.heading}>{t('sentences.buy_in_straddle')}</div>
        <div>
          <span>{t('common.buy_in_min_bb')}</span>
          <div>{fields.buyin_bb_straddle_table_min}</div>
        </div>
        <div>
          <span>{t('common.buy_in_max_bb')}</span>
          <div>{fields.buyin_bb_straddle_table_max}</div>
        </div>
        <div>
          <span>{t('common.step_bb')}</span>
          <div>{fields.buyin_step_bb_straddle_table}</div>
        </div>
        <div>
          <span>{t('common.rebuy_threshold_bb')}</span>
          <div>
            {fields.rebuy_below_bb_straddle_table_min}
            {divider}
            {fields.rebuy_below_bb_straddle_table_max}
          </div>
        </div>
      </div>
      <div className={styles.wrapper}>
        <div className={styles.heading}>{t('common.stack_stops')}</div>
        <div>
          <span>{t('common.eff_stack_bb')}</span>
          <div>
            {fields.effective_stack_limit_bb_min}
            {divider}
            {fields.effective_stack_limit_bb_max}
          </div>
        </div>
        <div>
          <span>{t('common.max_stack_bb')}</span>
          <div>
            {fields.max_stack_size_bb_min}
            {divider}
            {fields.max_stack_size_bb_max}
          </div>
        </div>
      </div>
      <div className={styles.wrapper}>
        <div className={styles.heading}>{t('common.MTT')}</div>
        <div>
          <span>{t('common.max_rebuy')}</span>
          <div>{fields.mtt_max_rebuy_min}</div>
          <div>{divider}</div>
          <div>{fields.mtt_max_rebuy_max}</div>
        </div>
        <div>
          <span>{t('common.addon')}</span>
          <div>{fields.mtt_addon}</div>
        </div>
      </div>
      <div className={styles.wrapper}>
        <div className={styles.heading}>{t('sentences.win_loss_stops')}</div>
        <div>
          <span>{t('common.stop_loss_bb')}</span>
          <div>
            {fields.stop_lose_bb_min}
            {divider}
            {fields.stop_lose_bb_max}
          </div>
        </div>
        <div>
          <span>{t('common.stop_win_bb')}</span>
          <div>
            {fields.stop_win_bb_min}
            {divider}
            {fields.stop_win_bb_max}
          </div>
        </div>
        <div>
          <span>{t('common.stop_loss_by_table_bb')}</span>
          <div>
            {fields.stop_lose_table_bb_min}
            {divider}
            {fields.stop_lose_table_bb_max}
          </div>
        </div>
        <div>
          <span>{t('common.stop_win_by_table_bb')}</span>
          <div>
            {fields.stop_win_table_bb_min}
            {divider}
            {fields.stop_win_table_bb_max}
          </div>
        </div>
        <div>
          <span>{t('common.max_swing_bb')}</span>
          <div>
            {fields.max_swing_bb_min}
            {divider}
            {fields.max_swing_bb_max}
          </div>
        </div>
      </div>
    </div>
  );
};

export default WorkspaceSettingsStrategyProfile;
