import { useQuery } from '@tanstack/react-query';
import { lazy, Suspense, useState } from 'react';
import {
  createGlobalAccountSession,
  deleteGlobalAccountSession,
  loadGlobalAccountSessions,
  updateGlobalAccountSession,
} from '../../../api';
import { GlobalAccountSchema } from '../../../api/schemas/account/globalAccountSchema';
import { GlobalSessionSchema } from '../../../api/schemas/session/globalSessionSchema';
import queryKeys from '../../../config/queryKeys';
import routes from '../../../config/routes';
import useURL from '../../../hooks/useURL';
import useWebSocketMessage from '../../../hooks/useWebSocketMessage';
import Error403Page from '../../../pages/Error403Page';
import { handleError } from '../../../utils/form';
import { createQueryData, deleteQueryData, updateQueryData } from '../../../utils/queryClient';
import AccountScheduleSpinner from '../../account/AccountScheduleSpinner';
import { can } from '../../permission/helpers';
import GlobalAccountScheduleDialog from '../GlobalAccountScheduleDialog';
import { useGlobalAccountQuery } from '../queries';

const AccountSchedule = lazy(() => import('../../account/AccountSchedule'));

interface Props {
  accountId: GlobalAccountSchema['id'];
}

const GlobalAccountSchedule = (props: Props) => {
  const { accountId } = props;

  const { navigate } = useURL();

  const [start_ge, setStartGe] = useState('');
  const [start_le, setStartLe] = useState('');

  const queryKey = [queryKeys.accountSchedule({ accountId }), start_ge, start_le];
  const queryFn = () =>
    loadGlobalAccountSessions({ accountId }, { show_completed: 'true', start_ge, start_le });

  const { data, isPending, error } = useQuery({
    queryKey,
    queryFn,
    enabled: Boolean(start_ge) && Boolean(start_le),
    meta: { ignoredStatuses: [403] },
  });

  const { data: account, error: accountError } = useGlobalAccountQuery({ accountId });

  useWebSocketMessage<GlobalSessionSchema>({
    domain: 'GlobalSession',
    onMessage: (message) => {
      if (message.action === 'Created') {
        if (message.payload.account.id === accountId) {
          createQueryData(queryKey, message.payload);
        }
      }

      if (message.action === 'Edited') {
        updateQueryData(queryKey, message.payload, (item) => item.id === message.payload.id);
      }

      if (message.action === 'Deleted') {
        deleteQueryData<GlobalSessionSchema>(queryKey, (item) => item.id === message.payload.id);
      }
    },
  });

  if (error?.response?.status === 403 || accountError?.response?.status === 403) {
    return <Error403Page />;
  }

  if (!account) return <AccountScheduleSpinner />;

  return (
    <Suspense fallback={<AccountScheduleSpinner />}>
      <AccountSchedule
        onFilter={(args) => {
          setStartGe(args.start_ge);
          setStartLe(args.start_le);
        }}
        account={account}
        heading={account.mga_id}
        data={data}
        loading={isPending}
        canCreateAndEdit={can.session.createUpdate}
        canDelete={can.session.delete}
        onBack={() => navigate(routes.account({ accountId }))}
        onDelete={async (sessionId) => {
          try {
            await deleteGlobalAccountSession({ accountId, sessionId });
          } catch (err) {
            handleError({ error: err });
          }
        }}
        onSelect={async (start, end, type) => {
          try {
            if (can.session.createUpdate) {
              await createGlobalAccountSession({
                accountId,
                payload: { start, end, session_type: type },
              });
            }
          } catch (err) {
            handleError({ error: err });
          }
        }}
        onEventChange={async (start, end, id) => {
          try {
            if (can.session.createUpdate) {
              await updateGlobalAccountSession({
                accountId,
                sessionId: id,
                payload: { start, end },
              });
            }
          } catch (err) {
            handleError({ error: err });
          }
        }}
      >
        {({ id, onClose }) => (
          <GlobalAccountScheduleDialog sessionId={id} account={account} onClose={onClose} />
        )}
      </AccountSchedule>
    </Suspense>
  );
};

export default GlobalAccountSchedule;
