import { ReactElement } from 'react';
import { Navigate as RouterNavigate, useParams } from 'react-router-dom';
import routes from '../../config/routes';
import useURL, { Navigate } from '../../hooks/useURL';

interface ParamsAndBrands {
  workspaceId: 'brand_workspaceId';
  accountId: 'brand_accountId';
  identityId: 'brand_identityId';
  userId: 'brand_userId';
  botSettingId: 'brand_botSettingId';
  botSettingRuleId: 'brand_botSettingRuleId';
  seatControlRuleId: 'brand_seatControlRuleId';
  roleId: 'brand_roleId';
  orderId: 'brand_orderId';
  dealId: 'brand_dealId';
  agentId: 'brand_agentId';
  managerId: 'brand_managerId';
  memberId: 'brand_memberId';
  whitelistMemberId: 'brand_whitelistMemberId';
  transactionId: 'brand_transactionId';
  channelId: 'brand_channelId';
  sessionId: 'brand_sessionId';
  walletId: 'brand_walletId';
  tokenId: 'brand_tokenId';
  tagId: 'brand_tagId';
  tableId: 'brand_tableId';
  clubId: 'brand_clubId';
  leagueId: 'brand_leagueId';
  superLeagueId: 'brand_superLeagueId';
}

type Params = Array<keyof ParamsAndBrands>;

type ChildrenParams<T extends Params> = {
  [Property in T[number]]: ParamsAndBrands[Property];
} & { navigate: Navigate };

interface Props<T extends Params> {
  params?: T;
  children: (params: ChildrenParams<T>) => ReactElement;
}

const Route = <T extends Params = []>(props: Props<T>) => {
  const { params, children } = props;

  const { navigate } = useURL();
  const routeParams = useParams();

  if (params?.some((param) => !Object.keys(routeParams).includes(param))) {
    return <RouterNavigate to={routes.error404} />;
  }

  return children({ ...routeParams, navigate } as ChildrenParams<T>);
};

export default Route;
