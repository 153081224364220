import { ReactElement } from 'react';
import { deleteGlobalDealNote, loadGlobalDealNotes } from '../../../api';
import { GlobalDealSchema } from '../../../api/schemas/deal/globalDealSchema';
import { Props as NotesProps } from '../../../components/Notes';
import queryKeys from '../../../config/queryKeys';
import usePaginatedDataNew from '../../../hooks/usePaginatedDataNew';
import { useCreateGlobalDealNoteMutation, useUpdateGlobalDealNoteMutation } from './mutations';

interface Props {
  dealId: GlobalDealSchema['id'];
  children: (args: NotesProps) => ReactElement;
}

const GlobalDealNotes = (props: Props) => {
  const { dealId, children } = props;

  const queryKey = [queryKeys.dealNotes({ dealId })];

  const query = usePaginatedDataNew({
    queryKey,
    queryFn: (params) => loadGlobalDealNotes({ dealId }, { ...params, order_by: 'desc' }),
  });

  const createNote = useCreateGlobalDealNoteMutation();
  const updateNote = useUpdateGlobalDealNoteMutation();

  return children({
    query,
    creating: createNote.isPending,
    updating: updateNote.isPending,
    onCreate: ({ payload }) => createNote.mutateAsync({ dealId, payload }),
    onUpdate: ({ noteId, payload }) => updateNote.mutateAsync({ dealId, noteId, payload }),
    onDelete: ({ noteId }) => deleteGlobalDealNote({ dealId, noteId }),
    onCreateMessage: (message) => message.payload.instance.id === dealId,
  });
};

export default GlobalDealNotes;
