import { useTranslation } from 'react-i18next';
import { PermissionSchema } from '../../api/schemas/permissionSchema';
import PageAddon from '../../components/PageAddon';
import Tag from '../../components/Tag';
import { permissionsGroups } from './helpers';

interface Props {
  permissions: PermissionSchema[];
}

const PermissionField = (props: Props) => {
  const { permissions } = props;

  const { t } = useTranslation();

  return (
    <>
      {permissionsGroups.map((item) => {
        const currentPermissions = item.permissions.flat().filter((el) => permissions.includes(el));

        if (currentPermissions.length === 0) return null;

        return (
          <PageAddon.Field key={item.label} label={item.label}>
            <PageAddon.Tags>
              {currentPermissions.map((el) => (
                <Tag key={el}>{t(`permissions.${el}`)}</Tag>
              ))}
            </PageAddon.Tags>
          </PageAddon.Field>
        );
      })}
    </>
  );
};

export default PermissionField;
