import { useTranslation } from 'react-i18next';
import { Outlet, useParams } from 'react-router-dom';
import { loadWorkspaceClubWhitelist } from '../../api';
import { WorkspaceClubWhitelistSchema } from '../../api/schemas/workspaceClubWhitelistSchema';
import { WorkspaceSchema } from '../../api/schemas/workspaceSchema';
import Card from '../../components/Card';
import Page from '../../components/Page';
import PageControls from '../../components/PageControls';
import PageTop from '../../components/PageTop';
import Table, { getTableProps } from '../../components/Table';
import queryKeys from '../../config/queryKeys';
import routes from '../../config/routes';
import WorkspaceClubWhitelistCreateButton from '../../features/workspace-club-whitelist/WorkspaceClubWhitelistCreateButton';
import WorkspaceClubWhitelistDetailLink from '../../features/workspace-club-whitelist/WorkspaceClubWhitelistDetailLink';
import WorkspaceClubWhitelistDropdown from '../../features/workspace-club-whitelist/WorkspaceClubWhitelistDropdown';
import WorkspaceClubWhitelistFilter from '../../features/workspace-club-whitelist/WorkspaceClubWhitelistFilter';
import useBreakpoints from '../../hooks/useBreakpoints';
import usePaginatedDataNew from '../../hooks/usePaginatedDataNew';
import useURL from '../../hooks/useURL';
import useWebSocketPaginatedActions from '../../hooks/useWebSocketPaginatedActions';
import { RouteParams } from '../../utils/generics';
import { TRAINER_POKER_ROOM_LABELS } from '../../utils/trainer';
import Error403Page from '../Error403Page';
import styles from './styles.module.scss';

type Params = RouteParams<typeof routes.workspaceClubWhitelistMember>;

interface Props {
  workspaceId: WorkspaceSchema['id'];
}

const WorkspaceClubWhitelistPage = (props: Props) => {
  const { workspaceId } = props;

  const { t } = useTranslation();
  const { isDesktop, isTablet, isPhone } = useBreakpoints();
  const { whitelistMemberId } = useParams<Params>();
  const { urlParams } = useURL();
  const { search } = urlParams;

  const queryKey = [queryKeys.workspaceClubWhitelist({ workspaceId }), urlParams];

  const query = usePaginatedDataNew({
    queryKey,
    queryFn: (params) => loadWorkspaceClubWhitelist({ workspaceId }, { ...urlParams, ...params }),
  });

  const { data, error, checkIsInView } = query;

  const { checkIsCreated } = useWebSocketPaginatedActions<
    WorkspaceClubWhitelistSchema,
    WorkspaceClubWhitelistSchema
  >({
    queryKey,
    domain: 'WhiteList',
    enabled: (message) => message.meta_info?.maintenance_id === workspaceId,
    select: (item) => item,
  });

  if (error?.response?.status === 403) return <Error403Page />;

  return (
    <Page
      heading={t('common.whitelist')}
      filter={<WorkspaceClubWhitelistFilter />}
      tabs={[
        {
          to: routes.workspaceClubManagers({ workspaceId }),
          label: t('common.managers'),
        },
        {
          to: routes.workspaceClubMembers({ workspaceId }),
          label: t('common.members'),
        },
        {
          to: routes.workspaceClubWhitelist({ workspaceId }),
          label: t('common.whitelist'),
          active: true,
        },
        {
          to: routes.workspaceClubTransactions({ workspaceId }),
          label: t('common.transactions'),
        },
      ]}
      extra={
        <PageControls>
          <WorkspaceClubWhitelistCreateButton workspaceId={workspaceId} />
        </PageControls>
      }
      addon={<Outlet />}
    >
      {isPhone && (
        <PageTop
          sorting={[
            { key: 'player_id', title: t('common.pid'), default: true },
            { key: 'created_on', title: t('common.created') },
            { key: 'poker_room', title: t('common.poker_room') },
          ]}
        />
      )}
      {(isDesktop || isTablet) && (
        <Table
          data={data}
          search={search}
          {...getTableProps(query)}
          className={styles.table}
          columns={[
            {
              key: 'player_id',
              title: t('common.pid'),
              sorting: true,
              render: ({ values }) => (
                <WorkspaceClubWhitelistDetailLink data={values} workspaceId={workspaceId} />
              ),
            },
            {
              key: 'poker_room',
              title: t('common.poker_room'),
              sorting: true,
              render: ({ values }) => TRAINER_POKER_ROOM_LABELS[values.room],
            },
            {
              render: ({ values }) => (
                <WorkspaceClubWhitelistDropdown data={values} workspaceId={workspaceId} />
              ),
              align: 'right',
              controls: true,
            },
          ]}
          renderRow={(row, index) => (
            <Table.Row
              highlighted={checkIsCreated(row.data.id)}
              active={row.data.id === whitelistMemberId}
              danger={row.data.is_deleted}
              {...checkIsInView(index)}
              {...row}
            />
          )}
        />
      )}
      {isPhone && (
        <Card.Group>
          {data.map((item, index) => (
            <Card
              key={item.id}
              highlighted={checkIsCreated(item.id)}
              danger={item.is_deleted}
              {...checkIsInView(index)}
            >
              <Card.Top>
                <WorkspaceClubWhitelistDetailLink data={item} workspaceId={workspaceId} />
                <Card.Top.Controls>
                  <WorkspaceClubWhitelistDropdown data={item} workspaceId={workspaceId} />
                </Card.Top.Controls>
              </Card.Top>
              <Card.Field.Group>
                <Card.Field label={t('common.poker_room')}>
                  {TRAINER_POKER_ROOM_LABELS[item.room]}
                </Card.Field>
              </Card.Field.Group>
            </Card>
          ))}
        </Card.Group>
      )}
    </Page>
  );
};

export default WorkspaceClubWhitelistPage;
