import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { deleteGlobalBotSetting } from '../../api';
import botSettingDeleteErrorSchema from '../../api/schemas/botSettingDeleteErrorSchema';
import { BotSettingSchema } from '../../api/schemas/botSettingSchema';
import routes from '../../config/routes';
import useURL from '../../hooks/useURL';
import { isApiError400 } from '../../services/api';
import { handleError } from '../../utils/form';
import { RouteParams } from '../../utils/generics';
import notify from '../../utils/notify';
import { useGlobalBotSettingContext } from './GlobalBotSettingContext';

type Params = RouteParams<typeof routes.botSetting>;

interface Args {
  data: BotSettingSchema;
}

const useActions = (args: Args) => {
  const { data } = args;

  const { id } = data;

  const { t } = useTranslation();
  const { navigate } = useURL();
  const { botSettingId } = useParams<Params>();
  const { setDeleteError } = useGlobalBotSettingContext();

  const deleteBotSetting = async () => {
    try {
      await deleteGlobalBotSetting({ botSettingId: id });

      if (id === botSettingId) {
        navigate(routes.botSettings);
      }

      notify('success', { title: t('sentences.record_has_been_deleted') });
    } catch (error) {
      handleError({ error });

      if (
        isApiError400(error) &&
        error.response &&
        botSettingDeleteErrorSchema.parse(error.response.data.error[0].payload)
      ) {
        navigate(routes.botSettingError({ botSettingId: id }));
        setDeleteError(botSettingDeleteErrorSchema.parse(error.response.data.error[0].payload));
      }
    }
  };

  return { deleteBotSetting };
};

export default useActions;
