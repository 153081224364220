import { useTranslation } from 'react-i18next';
import { Outlet } from 'react-router-dom';
import { GlobalClubSchema } from '../../api/schemas/globalClubSchema';
import { WorkspaceSchema } from '../../api/schemas/workspaceSchema';
import { WorkspaceUserSchema } from '../../api/schemas/workspaceUserSchema';
import Button from '../../components/Button';
import PageAddon from '../../components/PageAddon';
import Tooltip from '../../components/Tooltip';
import icon from '../../static/icons';
import { addWhen } from '../../utils';
import { can } from '../permission/helpers';
import GlobalWorkspaceDetailClubsTab from './GlobalWorkspaceDetailClubsTab';
import GlobalWorkspaceDetailDetailsTab from './GlobalWorkspaceDetailDetailsTab';
import GlobalWorkspaceDetailDropdown from './GlobalWorkspaceDetailDropdown';
import GlobalWorkspaceDetailUsersTab from './GlobalWorkspaceDetailUsersTab';

interface Props {
  data: WorkspaceSchema;
  onClose: () => void;
  onEdit: () => void;
  getUserRoute?: (args: { userId: WorkspaceUserSchema['id'] }) => string;
  getClubRoute?: (args: { clubId: GlobalClubSchema['id'] }) => string;
}

const GlobalWorkspaceDetail = (props: Props) => {
  const { data, onClose, onEdit, getUserRoute, getClubRoute } = props;

  const { t } = useTranslation();

  const { id, name, is_deleted } = data;

  return (
    <PageAddon.Group>
      <Outlet />
      <PageAddon
        title={name}
        onClose={onClose}
        tabs={[
          {
            label: t('common.details'),
            content: <GlobalWorkspaceDetailDetailsTab data={data} />,
          },
          ...addWhen(
            {
              label: t('common.members'),
              content: (
                <GlobalWorkspaceDetailUsersTab workspaceId={id} getUserRoute={getUserRoute} />
              ),
            },
            can.user.read
          ),
          ...addWhen(
            {
              label: t('common.clubs'),
              content: (
                <GlobalWorkspaceDetailClubsTab workspaceId={id} getClubRoute={getClubRoute} />
              ),
            },
            can.club.read
          ),
        ]}
      >
        {!is_deleted && (
          <PageAddon.Controls>
            <PageAddon.Controls.Group>
              <Tooltip
                label={can.workspace.createUpdate ? t('common.edit') : t('common.no_permission')}
                hideOnMobile={can.workspace.createUpdate}
              >
                <div>
                  <Button
                    variant="transparent"
                    icon={icon('edit', 16)}
                    onClick={onEdit}
                    data-testid="edit"
                    disabled={!can.workspace.createUpdate}
                  />
                </div>
              </Tooltip>
              <GlobalWorkspaceDetailDropdown data={data} />
            </PageAddon.Controls.Group>
          </PageAddon.Controls>
        )}
      </PageAddon>
    </PageAddon.Group>
  );
};

export default GlobalWorkspaceDetail;
