import { cloneElement } from 'react';
import { WorkspaceSchema, WORKSPACE_TYPES } from '../../../api/schemas/workspaceSchema';
import Tooltip from '../../../components/Tooltip';
import {
  WORKSPACE_TYPES_LABELS,
  WORKSPACE_TYPES_CROSSED_ICONS,
  WORKSPACE_TYPES_ICONS,
} from '../helpers';
import styles from './styles.module.scss';

interface Props {
  types: WorkspaceSchema['maintenance_types'];
}

const GlobalWorkspaceTypes = (props: Props) => {
  const { types } = props;

  const allTypes = Object.values(WORKSPACE_TYPES);

  return (
    <div className={styles.container}>
      {allTypes.map((item) => {
        const isTypeExist = types.includes(item);

        return item ? (
          <Tooltip key={item} label={WORKSPACE_TYPES_LABELS[item]}>
            {isTypeExist
              ? cloneElement(WORKSPACE_TYPES_ICONS[item], { className: styles.icon })
              : WORKSPACE_TYPES_CROSSED_ICONS[item]}
          </Tooltip>
        ) : null;
      })}
    </div>
  );
};

export default GlobalWorkspaceTypes;
