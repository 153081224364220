import { z } from 'zod';

export const PLAYER_TYPE = {
  DEFAULT: 0,
  REG: 1,
  MIDDLE: 2,
  MIDDLE_FISH: 3,
  STRONG_FISH: 4,
  WEAK_FISH: 5,
} as const;

export const SEATING_PREFERENCE = {
  UNKNOWN: 0,
  BEST_SEAT: 1,
  RANDOM_SEAT: 2,
} as const;

const accountStrategyProfileSchema = z.object({
  player_type: z.nativeEnum(PLAYER_TYPE),
  buyin_bb_min: z.number(),
  buyin_bb_max: z.number(),
  buyin_step_bb: z.number(),
  buyin_bb_straddle_table_min: z.number(),
  buyin_bb_straddle_table_max: z.number(),
  buyin_step_bb_straddle_table: z.number(),
  rebuy_below_bb: z.number(),
  rebuy_below_bb_straddle_table: z.number(),
  stop_lose_bb: z.number(),
  stop_win_bb: z.number(),
  stop_lose_table_bb: z.number(),
  stop_win_table_bb: z.number(),
  max_swing_bb: z.number(),
  effective_stack_limit_bb: z.number(),
  max_stack_size_bb: z.number(),
  table_min_hands: z.number(),
  mtt_max_rebuy: z.number(),
  wait_for_big_blind: z.boolean(),
  mtt_addon: z.boolean(),
  auto_rebuy: z.boolean(),
  seating_preference: z.nativeEnum(SEATING_PREFERENCE),
});

export type AccountStrategyProfileSchema = z.infer<typeof accountStrategyProfileSchema>;

export { accountStrategyProfileSchema };
