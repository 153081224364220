import { GlobalAccountSchema } from '../../api/schemas/account/globalAccountSchema';
import Dropdown from '../../components/Dropdown';
import useDropdown from './useDropdown';

interface Props {
  data: GlobalAccountSchema;
}

const GlobalAccountDetailDropdown = (props: Props) => {
  const { title, options } = useDropdown(props);

  return (
    <Dropdown
      title={title}
      buttonSize="medium"
      options={[options.resetCache, options.divider, options.closePokerRoom]}
    />
  );
};

export default GlobalAccountDetailDropdown;
