import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { STATUS } from '../../../api/schemas/channelSchema';
import PageAddon from '../../../components/PageAddon';
import Tooltip from '../../../components/Tooltip';
import icon from '../../../static/icons';
import { CommonAccountSchema } from '../types';
import styles from './styles.module.scss';

type Args = { channelId: CommonAccountSchema['channels'][number]['id'] };

interface Props {
  data: CommonAccountSchema;
  children?: ReactNode;
  controls?: (args: Args) => ReactNode;
  onDelete?: (args: Args) => void;
}

const AccountDetailChannels = (props: Props) => {
  const { data, children, controls, onDelete } = props;

  const { channels } = data;

  const { t } = useTranslation();

  return (
    <PageAddon.QuickField.Group>
      {channels.map((item) => {
        const inactive = item.status === STATUS.UNKNOWN || item.status === STATUS.INACTIVE;

        return (
          <PageAddon.QuickField
            key={item.id}
            title={item.host}
            subTitle={item.port}
            danger={inactive}
            {...(onDelete && { onDelete: () => onDelete({ channelId: item.id }) })}
          >
            {inactive && (
              <Tooltip label={t('common.inactive')}>
                {icon('alertTriangle', 16, { className: styles.icon })}
              </Tooltip>
            )}
            {controls && controls({ channelId: item.id })}
          </PageAddon.QuickField>
        );
      })}
      {children}
    </PageAddon.QuickField.Group>
  );
};

export default AccountDetailChannels;
