import { entries, omit, pick } from 'remeda';
import { TRAINER_GAME_TYPE, TrainerGameTypeSchema } from '../api/schemas/trainerGameTypeSchema';
import { TRAINER_LIMITS, TrainerLimitSchema } from '../api/schemas/trainerLimitSchema';
import { TRAINER_POKER_ROOM, TrainerPokerRoomSchema } from '../api/schemas/trainerPokerRoomSchema';
import { TRAINER_TECH, TrainerTechSchema } from '../api/schemas/trainerTechSchema';
import { Options } from '../components/Select';

export const TRAINER_POKER_ROOM_LABELS: Record<TrainerPokerRoomSchema, string> = {
  [TRAINER_POKER_ROOM.UNKNOWN]: '—',
  [TRAINER_POKER_ROOM.PARTYPOKER]: 'PartyPoker',
  [TRAINER_POKER_ROOM.PPPOKER]: 'PPPoker',
  [TRAINER_POKER_ROOM.POKERRRR2]: 'Pokerrrr2',
  [TRAINER_POKER_ROOM.CLUBGG]: 'ClubGG',
  [TRAINER_POKER_ROOM.SUPREMA]: 'Suprema',
  [TRAINER_POKER_ROOM.POKERBROS]: 'PokerBros',
  [TRAINER_POKER_ROOM.RULER]: 'Ruler',
};

export const TRAINER_GAME_TYPE_LABELS: Record<TrainerGameTypeSchema, string> = {
  [TRAINER_GAME_TYPE.UNSUPPORTED]: 'UNSUPPORTED',
  [TRAINER_GAME_TYPE.FL]: 'FL',
  [TRAINER_GAME_TYPE.NL]: 'NL',
  [TRAINER_GAME_TYPE.FLR]: 'FLR',
  [TRAINER_GAME_TYPE.NLR]: 'NLR',
  [TRAINER_GAME_TYPE.MTT]: 'MTT',
  [TRAINER_GAME_TYPE.PLO]: 'PLO',
  [TRAINER_GAME_TYPE.NLD]: 'NLD',
  [TRAINER_GAME_TYPE.NLC]: 'NLC',
  [TRAINER_GAME_TYPE.SNG]: 'SNG',
  [TRAINER_GAME_TYPE.PLOR]: 'PLOR',
  [TRAINER_GAME_TYPE.PLOD]: 'PLOD',
  [TRAINER_GAME_TYPE.PLOHL]: 'PLOHL',
  [TRAINER_GAME_TYPE.SNGHU]: 'SNGHU',
  [TRAINER_GAME_TYPE.SNGDON]: 'SNGDON',
  [TRAINER_GAME_TYPE.FIFTY]: 'FIFTY',
  [TRAINER_GAME_TYPE.SPIN]: 'SPIN',
  [TRAINER_GAME_TYPE.STUD]: 'STUD',
  [TRAINER_GAME_TYPE.RAZZ]: 'RAZZ',
  [TRAINER_GAME_TYPE.MTTPLO]: 'MTTPLO',
  [TRAINER_GAME_TYPE.NLO]: 'NLO',
  [TRAINER_GAME_TYPE.MTTSNG]: 'MTTSNG',
  [TRAINER_GAME_TYPE.SPNL]: 'SPNL',
  [TRAINER_GAME_TYPE.BTC]: 'BTC',
  [TRAINER_GAME_TYPE.NLS]: 'NLS',
  [TRAINER_GAME_TYPE.NLP]: 'NLP',
  [TRAINER_GAME_TYPE.SPINPLO]: 'SPINPLO',
  [TRAINER_GAME_TYPE.CSDNL]: 'CSDNL',
  [TRAINER_GAME_TYPE.POFC]: 'POFC',
  [TRAINER_GAME_TYPE.AANL]: 'AANL',
  [TRAINER_GAME_TYPE.AANLR]: 'AANLR',
  [TRAINER_GAME_TYPE.PLO5C]: 'PLO5C',
  [TRAINER_GAME_TYPE.PLOC]: 'PLOC',
  [TRAINER_GAME_TYPE.NLB]: 'NLB',
  [TRAINER_GAME_TYPE.CSDNLB]: 'CSDNLB',
  [TRAINER_GAME_TYPE.PLO6C]: 'PLO6C',
  [TRAINER_GAME_TYPE.PLOB]: 'PLOB',
  [TRAINER_GAME_TYPE.SNGTON]: 'SNGTON',
  [TRAINER_GAME_TYPE.PLO6CB]: 'PLO6CB',
  [TRAINER_GAME_TYPE.PLO5CB]: 'PLO5CB',
  [TRAINER_GAME_TYPE.PLOBHL]: 'PLOBHL',
  [TRAINER_GAME_TYPE.PLO5CHL]: 'PLO5CHL',
  [TRAINER_GAME_TYPE.PLO5CBHL]: 'PLO5CBHL',
  [TRAINER_GAME_TYPE.ROE]: 'ROE',
  [TRAINER_GAME_TYPE.NLAOF]: 'NLAOF',
  [TRAINER_GAME_TYPE.NLBAOF]: 'NLBAOF',
  [TRAINER_GAME_TYPE.PLO6CHL]: 'PLO6CHL',
  [TRAINER_GAME_TYPE.PLO6CBHL]: 'PLO6CBHL',
  [TRAINER_GAME_TYPE.PLO6CDB]: 'PLO6CDB',
  [TRAINER_GAME_TYPE.PLO6CDBB]: 'PLO6CDBB',
  [TRAINER_GAME_TYPE.PLO5CDB]: 'PLO5CDB',
  [TRAINER_GAME_TYPE.PLO5CDBB]: 'PLO5CDBB',
  [TRAINER_GAME_TYPE.PLODB]: 'PLODB',
  [TRAINER_GAME_TYPE.PLODBB]: 'PLODBB',
  [TRAINER_GAME_TYPE.NLDB]: 'NLDB',
  [TRAINER_GAME_TYPE.NLDBB]: 'NLDBB',
};

export const TRAINER_LIMITS_LABELS: Record<TrainerLimitSchema, string> = {
  [TRAINER_LIMITS.UNKNOWN]: '—',
  [TRAINER_LIMITS.C2]: 'C2',
  [TRAINER_LIMITS.C10]: 'C10',
  [TRAINER_LIMITS.C25]: 'C25',
  [TRAINER_LIMITS.C50]: 'C50',
  [TRAINER_LIMITS.C100]: 'C100',
  [TRAINER_LIMITS.C200]: 'C200',
  [TRAINER_LIMITS.C400]: 'C400',
  [TRAINER_LIMITS.C600]: 'C600',
  [TRAINER_LIMITS.C1000]: 'C1000',
  [TRAINER_LIMITS.C2000]: 'C2000',
};

export const TRAINER_TECH_LABELS: Record<TrainerTechSchema, string> = {
  [TRAINER_TECH.UNKNOWN]: '—',
  [TRAINER_TECH.PARTYPOKER_DESKTOP]: 'PartyPoker desktop',
  [TRAINER_TECH.PPPPOKER_HEADLESS]: 'PPPoker headless',
  [TRAINER_TECH.PPPPOKER_DESKTOP]: 'PPPoker desktop',
  [TRAINER_TECH.POKERRRR2_HEADLESS]: 'Pokerrrr2 headless',
  [TRAINER_TECH.POKERRRR2_DROP_MANUAL]: 'Pokerrrr2 drop manual',
  [TRAINER_TECH.RULER_HEADLESS]: 'Ruler headless',
  [TRAINER_TECH.CLUBGG_DESKTOP]: 'ClubGG desktop',
};

export const getTrainerPokerRoomOptions = (values: TrainerPokerRoomSchema[]) =>
  values.map((item) => ({
    value: item,
    label: TRAINER_POKER_ROOM_LABELS[item],
  }));

export const trainerPokerRoomOptions = getTrainerPokerRoomOptions(
  Object.values(TRAINER_POKER_ROOM).filter((item) => item !== TRAINER_POKER_ROOM.UNKNOWN)
);

const POPULAR_GAME_TYPES: (keyof typeof TRAINER_GAME_TYPE)[] = [
  'NL',
  'NLB',
  'PLO',
  'PLOB',
  'PLO5C',
  'PLO5CB',
  'PLO5CDB',
  'PLO5CDBB',
  'PLO6C',
  'PLO6CB',
  'PLO6CDB',
  'PLO6CDBB',
  'POFC',
  'ROE',
];

export const trainerGameTypeOptions: Options<number> = entries({
  ...pick(TRAINER_GAME_TYPE, POPULAR_GAME_TYPES),
  ...omit(TRAINER_GAME_TYPE, POPULAR_GAME_TYPES),
})
  .filter(([, value]) => value !== TRAINER_GAME_TYPE.UNSUPPORTED)
  .map(([label, value], index) => ({
    value,
    label,
    ...(index === POPULAR_GAME_TYPES.length && { separator: true }),
  }));

export const trainerTechOptions = Object.values(TRAINER_TECH)
  .filter((item) => item !== TRAINER_TECH.UNKNOWN)
  .map((item) => ({
    value: item,
    label: TRAINER_TECH_LABELS[item],
  }));
