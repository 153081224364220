import { RouteObject } from 'react-router-dom';
import routes from '../../config/routes';
import GlobalRoleDetail from '../../features/global-role/GlobalRoleDetail';
import GlobalRoleForm from '../../features/global-role/GlobalRoleForm';
import GlobalRoleLoader from '../../features/global-role/GlobalRoleLoader';
import GlobalRolesPage from '../../pages/GlobalRolesPage';
import Route from './Route';

const page = <GlobalRolesPage />;

const createForm = (
  <Route>
    {({ navigate }) => (
      <GlobalRoleForm
        onClose={() => navigate(routes.roles)}
        onCreate={({ roleId }) => navigate(routes.role({ roleId }))}
      />
    )}
  </Route>
);

const detail = (
  <Route params={['roleId']}>
    {({ roleId, navigate }) => (
      <GlobalRoleLoader roleId={roleId}>
        {({ data }) => (
          <GlobalRoleDetail
            data={data}
            onClose={() => navigate(routes.roles)}
            onEdit={() => navigate(routes.roleEdit({ roleId }))}
          />
        )}
      </GlobalRoleLoader>
    )}
  </Route>
);

const editForm = (
  <Route params={['roleId']}>
    {({ roleId, navigate }) => (
      <GlobalRoleLoader roleId={roleId}>
        {({ data }) => (
          <GlobalRoleForm
            data={data}
            onClose={() => navigate(routes.role({ roleId }))}
            onEdit={() => navigate(routes.role({ roleId }))}
          />
        )}
      </GlobalRoleLoader>
    )}
  </Route>
);

const globalRolesPage: RouteObject = {
  path: routes.roles,
  element: page,
  children: [
    {
      path: routes.rolesCreate,
      element: createForm,
    },
    {
      path: routes.role({ roleId: ':roleId' }),
      element: detail,
    },
    {
      path: routes.roleEdit({ roleId: ':roleId' }),
      element: editForm,
    },
  ],
};

export default globalRolesPage;
