import { useQuery } from '@tanstack/react-query';
import { ReactElement } from 'react';
import { loadGlobalLobbyTable } from '../../api';
import { LobbySchema } from '../../api/schemas/lobbySchema';
import PageAddon from '../../components/PageAddon';
import queryKeys from '../../config/queryKeys';
import useWebSocketMessage from '../../hooks/useWebSocketMessage';
import { mutateDetailQueryData, updateDetailQueryData } from '../../utils/queryClient';
import { AccountMetrics } from '../account/types';

interface Props {
  tableId: LobbySchema['table']['id'];
  children: (args: { data: LobbySchema }) => ReactElement;
}

const GlobalLobbyLoader = (props: Props) => {
  const { tableId, children } = props;

  const queryKey = [queryKeys.lobbyTable({ tableId })];
  const queryFn = () => loadGlobalLobbyTable({ tableId });

  const { data, isPending } = useQuery({
    queryFn,
    queryKey,
    meta: { ignoredStatuses: [403] },
  });

  useWebSocketMessage<LobbySchema>({
    domain: 'RoomLobby',
    enabled: () => !isPending,
    onMessage: (message) => {
      if (
        message.action === 'TableUpdated' ||
        message.action === 'AccountAdded' ||
        message.action === 'AccountRemoved' ||
        message.action === 'StatsUpdated'
      ) {
        if (message.payload.table.id === tableId) {
          updateDetailQueryData(queryKey, message.payload);
        }
      }
    },
  });

  useWebSocketMessage<AccountMetrics>({
    domain: 'AccountStats',
    enabled: () => !isPending,
    onMessage: (message) => {
      if (message.action === 'Updated') {
        mutateDetailQueryData<LobbySchema>(queryKey, (prevData) => ({
          ...prevData,
          accounts: prevData.accounts.map((item) => {
            if (item.id === message.payload.account_id) {
              return { ...item, metrics: message.payload.metrics };
            }

            return item;
          }),
        }));
      }
    },
  });

  if (isPending) return <PageAddon.DetailPlaceholder />;
  if (!data) return null;

  return children({ data });
};

export default GlobalLobbyLoader;
