import { useTranslation } from 'react-i18next';
import { WorkspaceClubManagerSchema } from '../../api/schemas/workspaceClubManagerSchema';
import { WorkspaceSchema } from '../../api/schemas/workspaceSchema';
import Button from '../../components/Button';
import PageAddon from '../../components/PageAddon';
import Tooltip from '../../components/Tooltip';
import icon from '../../static/icons';
import { formatAbsoluteDate } from '../../utils/date';
import { TRAINER_POKER_ROOM_LABELS } from '../../utils/trainer';
import ClubPageAddonTagsOld from '../club/ClubPageAddonTagsOld';
import { CLUB_MANAGER_TYPES_LABELS } from './helpers';
import WorkspaceClubManagerBalance from './WorkspaceClubManagerBalance';
import WorkspaceClubManagerBalanceThreshold from './WorkspaceClubManagerBalanceThreshold';
import WorkspaceClubManagerChannel from './WorkspaceClubManagerChannel';
import WorkspaceClubManagerDetailDropdown from './WorkspaceClubManagerDetailDropdown';
import WorkspaceClubManagerStartButton from './WorkspaceClubManagerStartButton';
import WorkspaceClubManagerStatus from './WorkspaceClubManagerStatus';

interface Props {
  workspaceId: WorkspaceSchema['id'];
  data: WorkspaceClubManagerSchema;
  onClose: () => void;
  onEdit: () => void;
}

const WorkspaceClubManagerDetail = (props: Props) => {
  const { workspaceId, data, onClose, onEdit } = props;

  const { t } = useTranslation();

  const { id, login, type, room, clubs, is_deleted, created_on, modified_on } = data;

  const titleIcons = is_deleted && icon('trash', 12);

  return (
    <PageAddon title={login} icons={titleIcons} onClose={onClose}>
      <PageAddon.Fields>
        <PageAddon.Id id={id} />
        <PageAddon.Field label={t('common.status')}>
          <WorkspaceClubManagerStatus data={data} />
        </PageAddon.Field>
        <PageAddon.Field label={t('common.type')}>
          {CLUB_MANAGER_TYPES_LABELS[type]}
        </PageAddon.Field>
        <PageAddon.Field label={t('common.poker_room')}>
          {TRAINER_POKER_ROOM_LABELS[room]}
        </PageAddon.Field>
        <PageAddon.Field label={t('common.clubs')}>
          <ClubPageAddonTagsOld data={clubs} />
        </PageAddon.Field>
        <WorkspaceClubManagerChannel data={data} />
        <PageAddon.Field label={t('common.balance')}>
          <WorkspaceClubManagerBalance data={data} />
        </PageAddon.Field>
        <PageAddon.Field label={t('common.balance_threshold')}>
          <WorkspaceClubManagerBalanceThreshold data={data} />
        </PageAddon.Field>
        <PageAddon.Field label={t('common.modified')}>
          {formatAbsoluteDate(modified_on)}
        </PageAddon.Field>
        <PageAddon.Field label={t('common.created')}>
          {formatAbsoluteDate(created_on)}
        </PageAddon.Field>
      </PageAddon.Fields>
      {!is_deleted && (
        <PageAddon.Controls>
          <PageAddon.Controls.Group>
            <WorkspaceClubManagerStartButton data={data} workspaceId={workspaceId} isDetail />
            <Tooltip label={t('common.edit')} hideOnMobile>
              <Button
                variant="transparent"
                icon={icon('edit', 16)}
                onClick={onEdit}
                data-testid="edit"
              />
            </Tooltip>
            <WorkspaceClubManagerDetailDropdown data={data} workspaceId={workspaceId} />
          </PageAddon.Controls.Group>
        </PageAddon.Controls>
      )}
    </PageAddon>
  );
};

export default WorkspaceClubManagerDetail;
