import { useQuery } from '@tanstack/react-query';
import { ReactElement } from 'react';
import { loadGlobalUser } from '../../api';
import { GlobalUserSchema } from '../../api/schemas/globalUserSchema';
import PageAddon from '../../components/PageAddon';
import queryKeys from '../../config/queryKeys';
import useWebSocketMessage from '../../hooks/useWebSocketMessage';
import { updateDetailQueryData } from '../../utils/queryClient';

interface Props {
  userId: GlobalUserSchema['id'];
  children: (args: { data: GlobalUserSchema }) => ReactElement;
}

const GlobalUserLoader = (props: Props) => {
  const { userId, children } = props;

  const queryKey = [queryKeys.user({ userId })];
  const queryFn = () => loadGlobalUser({ userId });

  const { data, isPending } = useQuery({
    queryKey,
    queryFn,
    meta: { ignoredStatuses: [403] },
  });

  useWebSocketMessage<GlobalUserSchema>({
    domain: 'GlobalUser',
    onMessage: (message) => {
      if (
        (message.action === 'Edited' || message.action === 'EmailVerified') &&
        message.payload.id === userId
      ) {
        updateDetailQueryData(queryKey, message.payload);
      }
    },
  });

  if (isPending) return <PageAddon.DetailPlaceholder />;
  if (!data) return null;

  return children({ data });
};

export default GlobalUserLoader;
