import { useTranslation } from 'react-i18next';
import { LobbySchema } from '../../../api/schemas/lobbySchema';
import PageAddon from '../../../components/PageAddon';
import PrettyNumber from '../../../components/PrettyNumber';
import Tooltip from '../../../components/Tooltip';
import icon from '../../../static/icons';
import { convertCentsToDollars } from '../../../utils/data';
import {
  TRAINER_GAME_TYPE_LABELS,
  TRAINER_LIMITS_LABELS,
  TRAINER_POKER_ROOM_LABELS,
} from '../../../utils/trainer';
import { getLimitBlind } from '../../account/helpers';
import { can } from '../../permission/helpers';
import GlobalLobbyTablePlayers from '../GlobalLobbyTablePlayers';
import styles from './styles.module.scss';

interface Props {
  data: LobbySchema;
}

const GlobalLobbyDetailDetailsTab = (props: Props) => {
  const { data } = props;

  const { t } = useTranslation();

  const { table, accounts } = data;

  const {
    id,
    league_id,
    club_id,
    player_count,
    max_no_of_seats,
    small_blind,
    big_blind,
    room,
    comparative_limit,
    game_type,
    currency,
    ante,
    extra_fields,
  } = table;

  const seatsLabel = (
    <>
      {t('common.seats')}
      <Tooltip label={t('sentences.bots/players/seats')}>
        {icon('helpCircle', 16, { className: styles.seatsLabelHelp })}
      </Tooltip>
    </>
  );

  return (
    <PageAddon.Fields>
      <PageAddon.Id id={id} />
      <PageAddon.Field label={t('common.poker_room')}>
        {TRAINER_POKER_ROOM_LABELS[room]}
      </PageAddon.Field>
      <PageAddon.Field label={t('common.league')}>
        {league_id ? <PageAddon.Copy>{league_id}</PageAddon.Copy> : '—'}
      </PageAddon.Field>
      <PageAddon.Field label={t('common.club')}>
        <PageAddon.Copy>{club_id}</PageAddon.Copy>
      </PageAddon.Field>
      <PageAddon.Field label={t('common.currency')}>{currency}</PageAddon.Field>
      <PageAddon.Field label={t('common.game_type')}>
        {TRAINER_GAME_TYPE_LABELS[game_type]}
      </PageAddon.Field>
      <PageAddon.Field label={t('common.limit')}>
        <Tooltip label={getLimitBlind(comparative_limit)}>
          <span>{TRAINER_LIMITS_LABELS[comparative_limit]}</span>
        </Tooltip>
      </PageAddon.Field>
      <PageAddon.Field label={t('common.sb/bb')}>
        <PrettyNumber>{convertCentsToDollars(small_blind)}</PrettyNumber>
        &nbsp;/&nbsp;
        <PrettyNumber>{convertCentsToDollars(big_blind)}</PrettyNumber>
      </PageAddon.Field>
      <PageAddon.Field label={t('common.ante')}>
        {ante ? <PrettyNumber>{convertCentsToDollars(ante)}</PrettyNumber> : '—'}
      </PageAddon.Field>
      <PageAddon.Field label={seatsLabel}>
        {`${accounts.length} / ${player_count} / ${max_no_of_seats}`}
      </PageAddon.Field>
      <PageAddon.ExtraFields data={extra_fields} />
      {can.lobby.playersRead && (
        <PageAddon.Collapse label={t('common.table_state')}>
          <div className={styles.collapseWrapper}>
            <GlobalLobbyTablePlayers data={data} variant="secondary" />
          </div>
        </PageAddon.Collapse>
      )}
    </PageAddon.Fields>
  );
};

export default GlobalLobbyDetailDetailsTab;
