import { format } from 'date-fns';
import { NotificationSchema } from '../../../../api/schemas/notificationSchema';
import { TIME } from '../../../../constants/DATE_FORMATS';
import { getDate } from '../../../../utils/date';
import { getActionTypeContent } from '../helpers';
import styles from './styles.module.scss';

interface Props {
  data: NotificationSchema;
}

const PageNotificationsItem = (props: Props) => {
  const { data } = props;

  const { message, author, workspace, is_read, created_on } = data;

  return (
    <div className={styles.container}>
      <div className={styles.top}>
        <div className={styles.author}>
          {workspace.name}
          {author && (
            <>
              {' '}
              <span>•</span> {author.username}
            </>
          )}
        </div>
        <div className={styles.side}>
          {!is_read && <div className={styles.indicator} />}
          <div className={styles.time}>{format(getDate(created_on), TIME)}</div>
        </div>
      </div>
      <div className={styles.action}>{getActionTypeContent(data)}</div>
      {Boolean(message) && <div className={styles.message}>{message}</div>}
    </div>
  );
};

export default PageNotificationsItem;
