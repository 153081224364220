import { useQuery, keepPreviousData } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { loadGlobalUsersFilterValues } from '../../api';
import Filter from '../../components/Filter';
import queryKeys from '../../config/queryKeys';
import useFilter from '../../hooks/useFilter';
import useURL from '../../hooks/useURL';

interface Fields {
  maintenances_in: string[];
  roles_in: string[];
  updated_ge: string;
  updated_le: string;
  created_ge: string;
  created_le: string;
  show_unverified: boolean;
}

interface Props {
  total?: number;
}

const GlobalUserFilter = (props: Props) => {
  const { total } = props;

  const { t } = useTranslation();
  const { urlParams } = useURL();

  const queryKey = [queryKeys.usersFilterValues, urlParams.show_unverified];
  const queryFn = () => loadGlobalUsersFilterValues({ show_unverified: urlParams.show_unverified });

  const { data, isPending } = useQuery({
    queryKey,
    queryFn,
    placeholderData: keepPreviousData,
    meta: { ignoredStatuses: [403] },
  });

  const filter = useFilter<Fields>({
    maintenances_in: 'multi',
    roles_in: 'multi',
    updated_ge: 'single',
    updated_le: 'single',
    created_ge: 'single',
    created_le: 'single',
    show_unverified: 'toggle',
  });

  const { values, setValue, clearValue } = filter;

  const {
    maintenances_in,
    roles_in,
    updated_ge,
    updated_le,
    created_ge,
    created_le,
    show_unverified,
  } = values;

  const filterProps = { total, filter };

  if (isPending) {
    return (
      <Filter {...filterProps}>
        <Filter.Placeholder />
      </Filter>
    );
  }

  if (!data) return null;

  const { maintenances, roles } = data;

  return (
    <Filter {...filterProps}>
      <div>
        {maintenances.length !== 0 && (
          <Filter.Collapse
            label={t('common.workspace')}
            count={maintenances_in.length}
            onClear={clearValue('maintenances_in')}
          >
            <Filter.MultiCheckbox
              config={maintenances.map((item) => ({
                value: item.id,
                label: item.name,
                onChange: setValue('maintenances_in'),
                checked: maintenances_in.includes(item.id),
              }))}
            />
          </Filter.Collapse>
        )}
        {roles.length !== 0 && (
          <Filter.Collapse
            label={t('common.role')}
            count={roles_in.length}
            onClear={clearValue('roles_in')}
          >
            <Filter.MultiCheckbox
              config={roles.map((item) => ({
                value: item.id,
                label: item.name,
                onChange: setValue('roles_in'),
                checked: roles_in.includes(item.id),
              }))}
            />
          </Filter.Collapse>
        )}
        <Filter.Collapse
          label={t('common.modified')}
          {...((updated_ge || updated_le) && {
            onClear: clearValue(['updated_ge', 'updated_le']),
          })}
        >
          <Filter.Date
            from={{ value: updated_ge, onChange: setValue('updated_ge') }}
            to={{ value: updated_le, onChange: setValue('updated_le') }}
          />
        </Filter.Collapse>
        <Filter.Collapse
          label={t('common.created')}
          {...((created_ge || created_le) && {
            onClear: clearValue(['created_ge', 'created_le']),
          })}
        >
          <Filter.Date
            from={{ value: created_ge, onChange: setValue('created_ge') }}
            to={{ value: created_le, onChange: setValue('created_le') }}
          />
        </Filter.Collapse>
      </div>
      <Filter.Switcher checked={show_unverified} onChange={setValue('show_unverified')}>
        {t('common.show_unverified')}
      </Filter.Switcher>
    </Filter>
  );
};

export default GlobalUserFilter;
