import { loadGlobalClubWorkspaces } from '../../api';
import { GlobalClubSchema } from '../../api/schemas/globalClubSchema';
import queryKeys from '../../config/queryKeys';
import usePaginatedData from '../../hooks/usePaginatedData';

export const useGlobalClubWorkspacesQuery = (args: {
  clubId: GlobalClubSchema['id'];
  enabled?: boolean;
}) => {
  const { clubId, enabled = false } = args;

  const queryKey = [queryKeys.clubWorkspaces({ clubId })];

  return {
    queryKey,
    ...usePaginatedData({
      queryKey,
      queryFn: ({ page }) =>
        loadGlobalClubWorkspaces({ clubId }, { size: '2000', ...(page && { page }) }),
      enabled,
    }),
  };
};
