import { forwardRef, ReactNode } from 'react';
import Input from '../../Input';
import { useDatePickerContext } from '../DatePickerContext';
import { Props as DatePickerProps } from '../types';
import styles from './styles.module.scss';

interface Props
  extends Pick<DatePickerProps, 'label' | 'disabled' | 'placeholder' | 'size' | 'error'> {
  extra: ReactNode;
}

const DatePickerInput = forwardRef<HTMLInputElement, Props>((props, ref) => {
  const { label, disabled, placeholder, size = 'large', error, extra } = props;

  const { inputValue, setInputValue } = useDatePickerContext();

  return (
    <Input
      readOnly
      ref={ref}
      size={size}
      label={label}
      disabled={disabled}
      placeholder={placeholder}
      error={error}
      className={styles.input}
      extra={extra}
      value={inputValue}
      onChange={(event) => setInputValue(event.target.value)}
    />
  );
});

export default DatePickerInput;
