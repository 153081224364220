import { useFormContext } from 'react-hook-form';
import { loadWorkspaceDeals } from '../../api';
import { WorkspaceAccountSchema } from '../../api/schemas/account/workspaceAccountSchema';
import { WorkspaceDealSchema } from '../../api/schemas/deal/workspaceDealSchema';
import { WorkspaceSchema } from '../../api/schemas/workspaceSchema';
import PageAddon from '../../components/PageAddon';
import Select from '../../components/Select';
import useURL from '../../hooks/useURL';
import { WorkspaceAccountFields as Fields } from '../account/types';

interface Props {
  workspaceId: WorkspaceSchema['id'];
  data: WorkspaceAccountSchema;
  onSubmit: () => Promise<void>;
  getDealRoute?: (args: { dealId: WorkspaceDealSchema['id'] }) => string;
}

const WorkspaceAccountDetailDeals = (props: Props) => {
  const { workspaceId, data, onSubmit, getDealRoute } = props;

  const { room, deals } = data;

  const { navigate } = useURL();

  const { watch, setValue } = useFormContext<Fields>();

  const dealIds = watch('deal_ids');

  return (
    <PageAddon.QuickField.Group>
      {deals.map((item) => (
        <PageAddon.QuickField
          key={item.id}
          title={item.code}
          subTitle={item.agent.name}
          onDelete={() => {
            setValue(
              'deal_ids',
              dealIds.filter((el) => el.value !== item.id)
            );

            onSubmit();
          }}
        >
          {getDealRoute && (
            <Select.Quick.Link onClick={() => navigate(getDealRoute({ dealId: item.id }))} />
          )}
        </PageAddon.QuickField>
      ))}
      <Select.AsyncV2
        onLoad={(args) =>
          loadWorkspaceDeals({ workspaceId }, { ...args, poker_room_in: `${room}` })
        }
        select={(item) => ({
          value: item.id,
          label: item.code,
          caption: `${item.agent.name} • ${item.club.code}${item.holder ? ` • ${item.holder?.username}` : ''}`,
        })}
      >
        {({ options, ...selectAsyncProps }) => (
          <Select.Quick
            name="deal_ids"
            options={options.filter((item) => !dealIds.map((el) => el.value).includes(item.value))}
            onChange={(value) => {
              setValue('deal_ids', value ? [...dealIds, value] : []);

              onSubmit();
            }}
            {...selectAsyncProps}
          />
        )}
      </Select.AsyncV2>
    </PageAddon.QuickField.Group>
  );
};

export default WorkspaceAccountDetailDeals;
