import { FieldValues, useController, UseControllerProps } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Tag from '../../Tag';
import SelectMulti from '../SelectMulti';
import { Option, Props as SelectProps } from '../types';
import styles from './styles.module.scss';

type Props<T extends FieldValues, Name extends string> = UseControllerProps<T> &
  SelectProps<FieldValues[Name]> & {
    onSubmit: () => void;
  };

const SelectMultiQuick = <T extends FieldValues, Name extends string>(props: Props<T, Name>) => {
  const { name, control, rules, onSubmit, label, options, disabled, ...rest } = props;

  const { t } = useTranslation();

  const {
    field,
    formState: { errors, isValid },
  } = useController({ name, control, rules });

  if (disabled) {
    if (!field.value.length) return '—';

    return (
      <div className={styles.tags}>
        {field.value.map((item: Option) => (
          <Tag>{item?.label}</Tag>
        ))}
      </div>
    );
  }

  const error = errors[name];

  return (
    <SelectMulti
      quick
      renderValueAsTag
      placeholder={t('common.select')}
      label={label}
      options={options}
      value={field.value}
      onChange={(value) => {
        field.onChange(value);

        if (isValid) onSubmit();
      }}
      {...(error && { error: String(error.message) })}
      {...rest}
    />
  );
};

export default SelectMultiQuick;
