import { ReactNode } from 'react';
import Card from '../../components/Card';
import FadingText from '../../components/FadingText';
import Link from '../../components/Link';
import { Column } from '../../components/Table';
import { t } from '../../i18n';
import { renderNumber } from '../../utils/data';
import { formatAbsoluteDate, formatRelativeDate } from '../../utils/date';
import { TRAINER_POKER_ROOM_LABELS } from '../../utils/trainer';
import DealBalance from './DealBalance';
import DealBalanceUSD from './DealBalanceUSD';
import DealCredit from './DealCredit';
import DealDetailLink from './DealDetailLink';
import DealProtection from './DealProtection';
import DealRole from './DealRole';
import DealStatus from './DealStatus';
import {
  renderClubCode,
  renderClubLeague,
  renderClubName,
  renderClubSuperLeague,
  renderPaymentSystems,
} from './helpers';
import { CommonDealSchema } from './types';

export const columns = {
  code: (to) => ({
    key: 'code',
    sorting: true,
    title: String(t('common.code')),
    render: ({ values }) => <DealDetailLink data={values} to={to(values)} />,
    pin: 'left',
    resize: true,
    style: { width: 80 },
  }),
  agent_name: (to) => ({
    key: 'agent_name',
    sorting: true,
    title: String(t('common.agent')),
    render: ({ values }) => {
      const linkTo = to(values) || '';

      const content = <FadingText text={values.agent.name} />;

      return linkTo ? <Link to={linkTo}>{content}</Link> : content;
    },
    resize: true,
    style: { width: 80 },
  }),
  poker_room: {
    key: 'poker_room',
    sorting: true,
    title: String(t('common.poker_room')),
    render: ({ values }) => TRAINER_POKER_ROOM_LABELS[values.poker_room],
    resize: true,
    style: { width: 100 },
  },
  club_name: {
    key: 'club_name',
    sorting: true,
    title: String(t('common.club_name')),
    render: ({ values }) => renderClubName(values),
    resize: true,
    style: { width: 100 },
  },
  club_id: {
    key: 'club_id',
    title: String(t('common.club_id')),
    render: ({ values }) => renderClubCode(values),
    resize: true,
    style: { width: 80 },
  },
  league: {
    key: 'league',
    title: String(t('common.union')),
    render: ({ values }) => renderClubLeague(values),
    resize: true,
    style: { width: 80 },
  },
  super_league: {
    key: 'super_league',
    title: String(t('common.su')),
    render: ({ values }) => renderClubSuperLeague(values),
    resize: true,
    style: { width: 80 },
  },
  area_id: {
    key: 'area_id',
    sorting: true,
    title: String(t('common.area')),
    render: ({ values }) => <FadingText text={values.area_id || '—'} />,
    resize: true,
    style: { width: 80 },
  },
  area2_id: {
    key: 'area2_id',
    sorting: true,
    title: String(t('common.area_2')),
    render: ({ values }) => <FadingText text={values.area2_id || '—'} />,
    resize: true,
    style: { width: 80 },
  },
  holder_username: {
    key: 'holder_username',
    sorting: true,
    title: String(t('common.holder')),
    render: ({ values }) => <FadingText text={values.holder?.username || '—'} />,
    resize: true,
    style: { width: 80 },
  },
  owner: {
    key: 'owner',
    sorting: true,
    title: String(t('common.owner')),
    render: ({ values }) => <FadingText text={values.owner || '—'} />,
    resize: true,
    style: { width: 130 },
  },
  role: {
    key: 'role',
    sorting: true,
    title: String(t('common.role')),
    render: ({ values }) => <DealRole data={values} />,
    resize: true,
    style: { width: 80 },
  },
  status: {
    key: 'status',
    sorting: true,
    title: String(t('common.status')),
    render: ({ values }) => <DealStatus data={values} />,
    resize: true,
    style: { width: 80 },
  },
  credit: {
    key: 'credit',
    sorting: true,
    title: String(t('common.credit')),
    render: ({ values }) => <DealCredit data={values} />,
    resize: true,
    style: { width: 80 },
  },
  protection: {
    key: 'protection',
    sorting: true,
    title: String(t('common.protection')),
    render: ({ values }) => <DealProtection data={values} />,
    resize: true,
    style: { width: 100 },
  },
  currency: {
    key: 'currency',
    sorting: true,
    title: String(t('common.currency')),
    render: ({ values }) => <FadingText text={values.currency || '—'} />,
    resize: true,
    style: { width: 100 },
  },
  chip_rate: {
    key: 'chip_rate',
    sorting: true,
    title: String(t('common.chip_rate')),
    render: ({ values }) => <FadingText text={String(renderNumber(values.chip_rate))} />,
    resize: true,
    style: { width: 100 },
  },
  rake_back: {
    key: 'rake_back',
    sorting: true,
    title: String(t('common.rakeback')),
    render: ({ values }) => <FadingText text={String(renderNumber(values.rake_back))} />,
    resize: true,
    style: { width: 100 },
  },
  action: {
    key: 'action',
    sorting: true,
    title: String(t('common.action')),
    render: ({ values }) => <FadingText text={String(renderNumber(values.action))} />,
    resize: true,
    style: { width: 80 },
  },
  rev_share: {
    key: 'rev_share',
    sorting: true,
    title: String(t('common.revshare')),
    render: ({ values }) => <FadingText text={String(renderNumber(values.rev_share))} />,
    resize: true,
    style: { width: 100 },
  },
  win_share: {
    key: 'win_share',
    sorting: true,
    title: String(t('common.winshare')),
    render: ({ values }) => <FadingText text={String(renderNumber(values.win_share))} />,
    resize: true,
    style: { width: 100 },
  },
  rebate: {
    key: 'rebate',
    sorting: true,
    title: String(t('common.rebate')),
    render: ({ values }) => <FadingText text={String(renderNumber(values.rebate))} />,
    resize: true,
    style: { width: 80 },
  },
  agent_fee: {
    key: 'agent_fee',
    sorting: true,
    title: String(t('common.agent_fee')),
    render: ({ values }) => <FadingText text={String(renderNumber(values.agent_fee))} />,
    resize: true,
    style: { width: 100 },
  },
  withdraw_fee: {
    key: 'withdraw_fee',
    sorting: true,
    title: String(t('common.withdraw_fee')),
    render: ({ values }) => <FadingText text={String(renderNumber(values.withdraw_fee))} />,
    resize: true,
    style: { width: 120 },
  },
  payment_system: {
    key: 'payment_system',
    title: String(t('common.payment_system')),
    render: ({ values }) => renderPaymentSystems(values),
    resize: true,
    style: { width: 140 },
  },
  balance: {
    key: 'balance',
    title: String(t('common.balance')),
    render: ({ values }) => <DealBalance data={values} />,
    resize: true,
    style: { width: 100 },
  },
  balance_usd: {
    key: 'balance_usd',
    title: String(t('common.balance_$')),
    render: ({ values }) => <DealBalanceUSD data={values} />,
    resize: true,
    style: { width: 100 },
  },
  modified: {
    key: 'modified',
    title: String(t('common.modified')),
    render: ({ values }) => formatAbsoluteDate(values.modified_on),
    resize: true,
    style: { width: 100 },
  },
  created_on: {
    key: 'created_on',
    sorting: true,
    title: String(t('common.created')),
    render: ({ values }) => formatAbsoluteDate(values.created_on),
    resize: true,
    style: { width: 100 },
  },
} satisfies Record<
  string,
  | Column<CommonDealSchema>
  | ((to: (values: CommonDealSchema) => string) => Column<CommonDealSchema>)
>;

export const cardFields = {
  code: (data) => <Card.Field label={String(t('common.code'))}>{data.code}</Card.Field>,
  agent_name: (data) => (
    <Card.Field label={String(t('common.agent'))}>{data.agent.name}</Card.Field>
  ),
  poker_room: (data) => (
    <Card.Field label={String(t('common.poker_room'))}>
      {TRAINER_POKER_ROOM_LABELS[data.poker_room]}
    </Card.Field>
  ),
  club_name: (data) => (
    <Card.Field label={String(t('common.club_name'))}>{renderClubName(data)}</Card.Field>
  ),
  club_id: (data) => (
    <Card.Field label={String(t('common.club_id'))}>{renderClubCode(data)}</Card.Field>
  ),
  league: (data) => (
    <Card.Field label={String(t('common.union'))}>{renderClubLeague(data)}</Card.Field>
  ),
  super_league: (data) => (
    <Card.Field label={String(t('common.su'))}>{renderClubSuperLeague(data)}</Card.Field>
  ),
  area_id: (data) => (
    <Card.Field label={String(t('common.area'))}>{data.area_id || '—'}</Card.Field>
  ),
  area2_id: (data) => (
    <Card.Field label={String(t('common.area_2'))}>{data.area2_id || '—'}</Card.Field>
  ),
  holder_username: (data) => (
    <Card.Field label={String(t('common.holder'))}>{data.holder?.username || '—'}</Card.Field>
  ),
  owner: (data) => <Card.Field label={String(t('common.owner'))}>{data.owner || '—'}</Card.Field>,
  role: (data) => (
    <Card.Field label={String(t('common.role'))}>
      <DealRole data={data} />
    </Card.Field>
  ),
  status: (data) => (
    <Card.Field label={String(t('common.status'))}>
      <DealStatus data={data} />
    </Card.Field>
  ),
  credit: (data) => (
    <Card.Field label={String(t('common.credit'))}>
      <DealCredit data={data} />
    </Card.Field>
  ),
  protection: (data) => (
    <Card.Field label={String(t('common.protection'))}>
      <DealProtection data={data} />
    </Card.Field>
  ),
  currency: (data) => (
    <Card.Field label={String(t('common.currency'))}>{data.currency || '—'}</Card.Field>
  ),
  chip_rate: (data) => (
    <Card.Field label={String(t('common.chip_rate'))}>{data.chip_rate || '—'}</Card.Field>
  ),
  rake_back: (data) => (
    <Card.Field label={String(t('common.rakeback'))}>{data.rake_back || '—'}</Card.Field>
  ),
  action: (data) => (
    <Card.Field label={String(t('common.action'))}>{data.action || '—'}</Card.Field>
  ),
  rev_share: (data) => (
    <Card.Field label={String(t('common.revshare'))}>{data.rev_share || '—'}</Card.Field>
  ),
  win_share: (data) => (
    <Card.Field label={String(t('common.winshare'))}>{data.win_share || '—'}</Card.Field>
  ),
  rebate: (data) => (
    <Card.Field label={String(t('common.rebate'))}>{data.rebate || '—'}</Card.Field>
  ),
  agent_fee: (data) => (
    <Card.Field label={String(t('common.agent_fee'))}>{data.agent_fee || '—'}</Card.Field>
  ),
  withdraw_fee: (data) => (
    <Card.Field label={String(t('common.withdraw_fee'))}>{data.withdraw_fee || '—'}</Card.Field>
  ),
  payment_system: (data) => (
    <Card.Field label={String(t('common.payment_system'))}>{renderPaymentSystems(data)}</Card.Field>
  ),
  balance: (data) => (
    <Card.Field label={String(t('common.balance'))}>
      <DealBalance data={data} />
    </Card.Field>
  ),
  balance_usd: (data) => (
    <Card.Field label={String(t('common.balance_'))}>
      <DealBalanceUSD data={data} />
    </Card.Field>
  ),
  modified: (data) => (
    <Card.Field label={String(t('common.modified'))}>
      {formatRelativeDate(data.modified_on)}
    </Card.Field>
  ),
  created_on: (data) => (
    <Card.Field label={String(t('common.created'))}>
      {formatRelativeDate(data.created_on)}
    </Card.Field>
  ),
} satisfies Record<string, (data: CommonDealSchema) => ReactNode>;
