import { useTranslation } from 'react-i18next';
import { Outlet, useParams } from 'react-router-dom';
import { loadWorkspaceChannels } from '../../api';
import { ChannelSchema } from '../../api/schemas/channelSchema';
import { WorkspaceSchema } from '../../api/schemas/workspaceSchema';
import Card from '../../components/Card';
import Page from '../../components/Page';
import PageControls from '../../components/PageControls';
import PageTop from '../../components/PageTop';
import Table, { getTableProps } from '../../components/Table';
import queryKeys from '../../config/queryKeys';
import routes from '../../config/routes';
import WorkspaceChannelCreateButton from '../../features/workspace-channel/WorkspaceChannelCreateButton';
import WorkspaceChannelDetailLink from '../../features/workspace-channel/WorkspaceChannelDetailLink';
import WorkspaceChannelDropdown from '../../features/workspace-channel/WorkspaceChannelDropdown';
import WorkspaceChannelFilter from '../../features/workspace-channel/WorkspaceChannelFilter';
import WorkspaceChannelStatus from '../../features/workspace-channel/WorkspaceChannelStatus';
import WorkspaceChannelTableSelectionActions from '../../features/workspace-channel/WorkspaceChannelTableSelectionActions';
import useBreakpoints from '../../hooks/useBreakpoints';
import usePaginatedDataNew from '../../hooks/usePaginatedDataNew';
import useURL from '../../hooks/useURL';
import useWebSocketPaginatedActions from '../../hooks/useWebSocketPaginatedActions';
import icon from '../../static/icons';
import { RouteParams } from '../../utils/generics';
import Error403Page from '../Error403Page';
import styles from './styles.module.scss';

const checkIcon = icon('check', 16, { className: styles.icon });
const crossIcon = icon('cross', 16, { className: styles.icon });

const getUsed = (data: ChannelSchema) => (data.is_used ? checkIcon : crossIcon);

type Params = RouteParams<typeof routes.workspaceChannel>;

interface Props {
  workspaceId: WorkspaceSchema['id'];
}

const WorkspaceChannelsPage = (props: Props) => {
  const { workspaceId } = props;

  const { t } = useTranslation();
  const { isDesktop, isTablet, isPhone } = useBreakpoints();
  const { channelId } = useParams<Params>();
  const { urlParams } = useURL();
  const { search, ...rest } = urlParams;

  const queryKey = [queryKeys.workspaceChannels({ workspaceId }), urlParams];

  const query = usePaginatedDataNew({
    queryKey,
    queryFn: (params) => loadWorkspaceChannels({ workspaceId }, { ...urlParams, ...params }),
  });

  const { data, error, checkIsInView } = query;

  const { checkIsCreated } = useWebSocketPaginatedActions<ChannelSchema, ChannelSchema>({
    queryKey,
    domain: 'Channel',
    enabled: (message) => message.meta_info?.maintenance_id === workspaceId,
    updateAction: ['Edited', 'Enabled', 'Disabled'],
    select: (item) => item,
  });

  if (error?.response?.status === 403) return <Error403Page />;

  return (
    <Table.Provider
      key={JSON.stringify(rest)}
      ids={data.map((item) => item.id)}
      disabledSelectAll={Boolean(search)}
    >
      <Page
        heading={t('common.channels')}
        filter={<WorkspaceChannelFilter workspaceId={workspaceId} />}
        extra={
          <PageControls>
            <WorkspaceChannelCreateButton workspaceId={workspaceId} />
          </PageControls>
        }
        addon={<Outlet />}
      >
        {isPhone && (
          <PageTop
            sorting={[
              { key: 'host', title: t('common.host'), default: true },
              { key: 'port', title: t('common.port') },
              { key: 'created_on', title: t('common.created') },
              { key: 'avg_latency', title: t('common.avg_latency_ms') },
              { key: 'packet_loss_percent', title: t('common.packet_loss_%') },
            ]}
          />
        )}
        {(isDesktop || isTablet) && (
          <Table
            data={data}
            search={search}
            {...getTableProps(query)}
            className={styles.table}
            columns={[
              {
                key: 'host',
                title: t('common.host'),
                render: ({ values }) => (
                  <WorkspaceChannelDetailLink data={values} workspaceId={workspaceId} />
                ),
                pin: 'left',
                select: true,
                sorting: true,
              },
              {
                title: t('common.status'),
                render: ({ values }) => <WorkspaceChannelStatus data={values} />,
              },
              {
                title: t('common.country'),
                render: ({ values }) => values.country || '—',
              },
              {
                title: t('common.ip'),
                render: ({ values }) => values.ip || '—',
              },
              {
                key: 'avg_latency',
                title: t('common.avg_latency_ms'),
                render: ({ values }) => values.avg_latency || '—',
                sorting: true,
              },
              {
                key: 'packet_loss_percent',
                title: t('common.packet_loss_%'),
                render: ({ values }) => values.packet_loss_percent,
                sorting: true,
              },
              {
                title: t('common.used'),
                render: ({ values }) => getUsed(values),
              },
              {
                render: ({ values }) => (
                  <WorkspaceChannelDropdown data={values} workspaceId={workspaceId} />
                ),
                align: 'right',
                controls: true,
                pin: 'right',
              },
            ]}
            renderRow={(row, index) => (
              <Table.Row
                highlighted={checkIsCreated(row.data.id)}
                active={row.data.id === channelId}
                danger={row.data.is_deleted}
                {...checkIsInView(index)}
                {...row}
              />
            )}
            selectionActions={<WorkspaceChannelTableSelectionActions workspaceId={workspaceId} />}
          />
        )}
        {isPhone && (
          <Card.Group>
            {data.map((item, index) => (
              <Card
                key={item.id}
                highlighted={checkIsCreated(item.id)}
                danger={item.is_deleted}
                {...checkIsInView(index)}
              >
                <Card.Top>
                  <WorkspaceChannelDetailLink data={item} workspaceId={workspaceId} />
                  <Card.Top.Controls>
                    <WorkspaceChannelDropdown data={item} workspaceId={workspaceId} />
                  </Card.Top.Controls>
                </Card.Top>
                <Card.Field.Group>
                  <Card.Field label={t('common.status')}>
                    <WorkspaceChannelStatus data={item} />
                  </Card.Field>
                  <Card.Field label={t('common.country')}>{item.country || '—'}</Card.Field>
                  <Card.Field label={t('common.ip')}>{item.ip || '—'}</Card.Field>
                  <Card.Field label={t('common.avg_latency_ms')}>
                    {item.avg_latency || '—'}
                  </Card.Field>
                  <Card.Field label={t('common.packet_loss_%')}>
                    {item.packet_loss_percent}
                  </Card.Field>
                  <Card.Field label={t('common.used')}>{getUsed(item)}</Card.Field>
                </Card.Field.Group>
              </Card>
            ))}
          </Card.Group>
        )}
      </Page>
    </Table.Provider>
  );
};

export default WorkspaceChannelsPage;
