import { useForm, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { loadGlobalRoles, updateGlobalUser } from '../../api';
import { GlobalUserSchema } from '../../api/schemas/globalUserSchema';
import Form from '../../components/Form';
import PageAddon from '../../components/PageAddon';
import Select, { Options } from '../../components/Select';
import SubmitButton from '../../components/SubmitButton';
import notify from '../../utils/notify';

interface Fields {
  role_ids: Options;
}

interface Props {
  data: GlobalUserSchema;
  onClose: () => void;
}

const GlobalUserForm = (props: Props) => {
  const { data, onClose } = props;

  const { t } = useTranslation();

  const form = useForm<Fields>({
    defaultValues: { role_ids: data.roles.map((item) => ({ value: item.id, label: item.name })) },
  });

  const {
    control,
    formState: { errors },
  } = form;

  const onSubmit = async (values: Fields) => {
    const payload = { role_ids: values.role_ids.map((item) => item.value) };

    await updateGlobalUser({ userId: data.id, payload });

    onClose();
    notify('success', { title: t('sentences.changes_have_been_saved') });
  };

  return (
    <PageAddon onClose={onClose} title={`${t('common.edit')} ${data.username}`}>
      <Form form={form} onSubmit={onSubmit}>
        <Controller
          name="role_ids"
          control={control}
          render={({ field }) => (
            <Select.Async
              onLoad={async (args) => {
                const response = await loadGlobalRoles(args);

                const options = response.items.map((item) => ({
                  value: item.id,
                  label: item.name,
                }));

                return options;
              }}
            >
              {({ options, ...rest }) => (
                <Select.Multi
                  renderValueAsTag
                  label={t('common.roles')}
                  options={options}
                  value={field.value}
                  onChange={field.onChange}
                  error={errors.role_ids?.message}
                  {...rest}
                />
              )}
            </Select.Async>
          )}
        />
        <PageAddon.Controls>
          <SubmitButton>{t('common.save')}</SubmitButton>
        </PageAddon.Controls>
      </Form>
    </PageAddon>
  );
};

export default GlobalUserForm;
