import { Routes } from './types';

const routes: Routes = {
  home: '/',

  registration: '/registration',
  invite: '/invite',
  login: '/login',
  recovery: '/recovery',
  reset: '/reset',

  storybook: '/storybook',

  error404: '/404',

  workspaceUsers: ({ workspaceId }) => `/workspaces/${workspaceId}/members`,
  workspaceUser: ({ workspaceId, userId }) => `/workspaces/${workspaceId}/members/${userId}`,
  workspaceUserInvite: ({ workspaceId }) => `/workspaces/${workspaceId}/members/invite`,

  workspaceAccounts: ({ workspaceId }) => `/workspaces/${workspaceId}/accounts`,
  workspaceAccount: ({ workspaceId, accountId }) =>
    `/workspaces/${workspaceId}/accounts/${accountId}`,
  workspaceAccountsCreate: ({ workspaceId }) => `/workspaces/${workspaceId}/accounts/create`,
  workspaceAccountsAutoCreate: ({ workspaceId }) =>
    `/workspaces/${workspaceId}/accounts/auto-create`,
  workspaceAccountsImport: ({ workspaceId }) => `/workspaces/${workspaceId}/accounts/import`,
  workspaceAccountNotes: ({ workspaceId, accountId }) =>
    `/workspaces/${workspaceId}/accounts/${accountId}/notes`,
  workspaceAccountTransactions: ({ workspaceId, accountId, type, id }) =>
    `/workspaces/${workspaceId}/accounts/${accountId}/transactions/${type}${id ? `/${id}` : ''}`,
  workspaceAccountBotSetting: ({ workspaceId, accountId, botSettingId }) =>
    `/workspaces/${workspaceId}/accounts/${accountId}/bot-setting/${botSettingId}`,
  workspaceAccountChannel: ({ workspaceId, accountId, channelId }) =>
    `/workspaces/${workspaceId}/accounts/${accountId}/channel/${channelId}`,
  workspaceAccountDeal: ({ workspaceId, accountId, dealId }) =>
    `/workspaces/${workspaceId}/accounts/${accountId}/deal/${dealId}`,
  workspaceAccountUser: ({ workspaceId, accountId, userId }) =>
    `/workspaces/${workspaceId}/accounts/${accountId}/user/${userId}`,
  workspaceAccountIdentity: ({ workspaceId, accountId, identityId }) =>
    `/workspaces/${workspaceId}/accounts/${accountId}/identity/${identityId}`,
  workspaceAccountIdentityEdit: ({ workspaceId, accountId, identityId }) =>
    `/workspaces/${workspaceId}/accounts/${accountId}/identity/${identityId}/edit`,
  workspaceAccountSchedule: ({ workspaceId, accountId }) =>
    `/workspaces/${workspaceId}/accounts/${accountId}/schedule`,
  workspaceAccountsBulkEdit: ({ workspaceId }) => `/workspaces/${workspaceId}/accounts/bulk-edit`,
  workspaceAccountsBulkStrategyProfileEdit: ({ workspaceId }) =>
    `/workspaces/${workspaceId}/accounts/bulk-edit-strategy-profile`,
  workspaceAccountsBulkRoomConfig: ({ workspaceId }) =>
    `/workspaces/${workspaceId}/accounts/bulk-room-config`,
  workspaceAccountsBulkSuccess: ({ workspaceId }) =>
    `/workspaces/${workspaceId}/accounts/bulk-success`,
  workspaceAccountsBulkError: ({ workspaceId }) => `/workspaces/${workspaceId}/accounts/bulk-error`,
  workspaceAccountsBulkErrorAccount: ({ workspaceId, accountId }) =>
    `/workspaces/${workspaceId}/accounts/bulk-error/account/${accountId}`,

  workspaceSessions: ({ workspaceId }) => `/workspaces/${workspaceId}/accounts/sessions`,
  workspaceSession: ({ workspaceId, accountId, sessionId }) =>
    `/workspaces/${workspaceId}/accounts/sessions/${accountId}/${sessionId}`,
  workspaceSessionEdit: ({ workspaceId, accountId, sessionId }) =>
    `/workspaces/${workspaceId}/accounts/sessions/${accountId}/${sessionId}/edit`,
  workspaceSessionAccount: ({ workspaceId, accountId, sessionId }) =>
    `/workspaces/${workspaceId}/accounts/sessions/${accountId}/${sessionId}/account/${accountId}`,

  workspaceChannels: ({ workspaceId }) => `/workspaces/${workspaceId}/channels`,
  workspaceChannelsCreate: ({ workspaceId }) => `/workspaces/${workspaceId}/channels/create`,
  workspaceChannelsImport: ({ workspaceId }) => `/workspaces/${workspaceId}/channels/import`,
  workspaceChannel: ({ workspaceId, channelId }) =>
    `/workspaces/${workspaceId}/channels/${channelId}`,

  workspaceClubManagers: ({ workspaceId }) => `/workspaces/${workspaceId}/club-managers`,
  workspaceClubManagersCreate: ({ workspaceId }) =>
    `/workspaces/${workspaceId}/club-managers/create`,
  workspaceClubManager: ({ workspaceId, managerId }) =>
    `/workspaces/${workspaceId}/club-managers/${managerId}`,
  workspaceClubManagerEdit: ({ workspaceId, managerId }) =>
    `/workspaces/${workspaceId}/club-managers/${managerId}/edit`,

  workspaceClubMembers: ({ workspaceId }) => `/workspaces/${workspaceId}/club-managers/members`,
  workspaceClubMembersAccept: ({ workspaceId }) =>
    `/workspaces/${workspaceId}/club-managers/members/accept`,
  workspaceClubMember: ({ workspaceId, memberId }) =>
    `/workspaces/${workspaceId}/club-managers/members/${memberId}`,
  workspaceClubMemberDeposit: ({ workspaceId, memberId }) =>
    `/workspaces/${workspaceId}/club-managers/members/${memberId}/deposit`,
  workspaceClubMemberWithdraw: ({ workspaceId, memberId }) =>
    `/workspaces/${workspaceId}/club-managers/members/${memberId}/withdraw`,
  workspaceClubMemberManager: ({ workspaceId, memberId, managerId }) =>
    `/workspaces/${workspaceId}/club-managers/members/${memberId}/manager/${managerId}`,
  workspaceClubMemberManagerEdit: ({ workspaceId, memberId, managerId }) =>
    `/workspaces/${workspaceId}/club-managers/members/${memberId}/manager/${managerId}/edit`,

  workspaceClubWhitelist: ({ workspaceId }) => `/workspaces/${workspaceId}/club-managers/whitelist`,
  workspaceClubWhitelistCreate: ({ workspaceId }) =>
    `/workspaces/${workspaceId}/club-managers/whitelist/create`,
  workspaceClubWhitelistMember: ({ workspaceId, whitelistMemberId }) =>
    `/workspaces/${workspaceId}/club-managers/whitelist/${whitelistMemberId}`,

  workspaceClubTransactions: ({ workspaceId }) =>
    `/workspaces/${workspaceId}/club-managers/transactions`,
  workspaceClubTransaction: ({ workspaceId, transactionId }) =>
    `/workspaces/${workspaceId}/club-managers/transactions/${transactionId}`,
  workspaceClubTransactionMember: ({ workspaceId, transactionId, memberId }) =>
    `/workspaces/${workspaceId}/club-managers/transactions/${transactionId}/member/${memberId}`,
  workspaceClubTransactionMemberDeposit: ({ workspaceId, transactionId, memberId }) =>
    `/workspaces/${workspaceId}/club-managers/transactions/${transactionId}/member/${memberId}/deposit`,
  workspaceClubTransactionMemberWithdraw: ({ workspaceId, transactionId, memberId }) =>
    `/workspaces/${workspaceId}/club-managers/transactions/${transactionId}/member/${memberId}/withdraw`,

  workspaceIdentities: ({ workspaceId }) => `/workspaces/${workspaceId}/identities`,
  workspaceIdentitiesCreate: ({ workspaceId }) => `/workspaces/${workspaceId}/identities/create`,
  workspaceIdentity: ({ workspaceId, identityId }) =>
    `/workspaces/${workspaceId}/identities/${identityId}`,
  workspaceIdentityEdit: ({ workspaceId, identityId }) =>
    `/workspaces/${workspaceId}/identities/${identityId}/edit`,
  workspaceIdentityAccount: ({ workspaceId, identityId, accountId }) =>
    `/workspaces/${workspaceId}/identities/${identityId}/account/${accountId}`,

  workspaceMarketIdentities: ({ workspaceId }) => `/workspaces/${workspaceId}/market/identities`,
  workspaceMarketIdentitiesCart: ({ workspaceId }) =>
    `/workspaces/${workspaceId}/market/identities/cart`,
  workspaceMarketIdentity: ({ workspaceId, identityId }) =>
    `/workspaces/${workspaceId}/market/identities/${identityId}`,
  workspaceMarketIdentityCart: ({ workspaceId, identityId }) =>
    `/workspaces/${workspaceId}/market/identities/${identityId}/cart`,

  workspaceOrders: ({ workspaceId }) => `/workspaces/${workspaceId}/orders`,
  workspaceOrder: ({ workspaceId, orderId }) => `/workspaces/${workspaceId}/orders/${orderId}`,
  workspaceOrderAccount: ({ workspaceId, orderId, accountId }) =>
    `/workspaces/${workspaceId}/orders/${orderId}/account/${accountId}`,

  workspaceDeals: ({ workspaceId }) => `/workspaces/${workspaceId}/deals`,
  workspaceDeal: ({ workspaceId, dealId }) => `/workspaces/${workspaceId}/deals/${dealId}`,
  workspaceDealClub: ({ workspaceId, dealId, clubId }) =>
    `/workspaces/${workspaceId}/deals/${dealId}/club/${clubId}`,
  workspaceDealClubNotes: ({ workspaceId, dealId, clubId }) =>
    `/workspaces/${workspaceId}/deals/${dealId}/club/${clubId}/notes`,
  workspaceDealClubEdit: ({ workspaceId, dealId, clubId }) =>
    `/workspaces/${workspaceId}/deals/${dealId}/club/${clubId}/edit`,

  workspaceSettings: ({ workspaceId }) => `/workspaces/${workspaceId}/settings`,
  workspaceSettingsWallets: ({ workspaceId }) => `/workspaces/${workspaceId}/settings/wallets`,
  workspaceSettingsStrategyProfile: ({ workspaceId }) =>
    `/workspaces/${workspaceId}/settings/strategy-profile`,
  workspaceSettingsTokens: ({ workspaceId }) => `/workspaces/${workspaceId}/settings/tokens`,
  workspaceSettingsTags: ({ workspaceId }) => `/workspaces/${workspaceId}/settings/tags`,
  workspaceSettingsRules: ({ workspaceId }) =>
    `/workspaces/${workspaceId}/settings/seat-control-rules`,

  globalWorkspaces: '/global-workspaces',
  globalWorkspacesCreate: '/global-workspaces/create',
  globalWorkspace: ({ workspaceId }) => `/global-workspaces/${workspaceId}`,
  globalWorkspaceEdit: ({ workspaceId }) => `/global-workspaces/${workspaceId}/edit`,
  globalWorkspaceUser: ({ workspaceId, userId }) =>
    `/global-workspaces/${workspaceId}/user/${userId}`,
  globalWorkspaceUserEdit: ({ workspaceId, userId }) =>
    `/global-workspaces/${workspaceId}/user/${userId}/edit`,
  globalWorkspaceClub: ({ workspaceId, clubId }) =>
    `/global-workspaces/${workspaceId}/club/${clubId}`,
  globalWorkspaceClubEdit: ({ workspaceId, clubId }) =>
    `/global-workspaces/${workspaceId}/club/${clubId}/edit`,

  orders: '/orders',
  order: ({ orderId }) => `/orders/${orderId}`,
  orderAccount: ({ orderId, accountId }) => `/orders/${orderId}/account/${accountId}`,

  deals: '/deals',
  dealsCreate: '/deals/create',
  deal: ({ dealId }) => `/deals/${dealId}`,
  dealEdit: ({ dealId }) => `/deals/${dealId}/edit`,
  dealNotes: ({ dealId }) => `/deals/${dealId}/notes`,
  dealAgent: ({ dealId, agentId }) => `/deals/${dealId}/agent/${agentId}`,
  dealAgentNotes: ({ dealId, agentId }) => `/deals/${dealId}/agent/${agentId}/notes`,
  dealAgentEdit: ({ dealId, agentId }) => `/deals/${dealId}/agent/${agentId}/edit`,
  dealClub: ({ dealId, clubId }) => `/deals/${dealId}/club/${clubId}`,
  dealClubNotes: ({ dealId, clubId }) => `/deals/${dealId}/club/${clubId}/notes`,
  dealClubEdit: ({ dealId, clubId }) => `/deals/${dealId}/club/${clubId}/edit`,

  agents: '/agents',
  agentsCreate: '/agents/create',
  agent: ({ agentId }) => `/agents/${agentId}`,
  agentEdit: ({ agentId }) => `/agents/${agentId}/edit`,
  agentNotes: ({ agentId }) => `/agents/${agentId}/notes`,

  seatConrolRules: '/seat-control-rules',
  seatConrolRule: ({ seatControlRuleId }) => `/seat-control-rules/${seatControlRuleId}`,
  seatConrolRuleEdit: ({ seatControlRuleId }) => `/seat-control-rules/${seatControlRuleId}/edit`,

  users: '/members',
  usersInvite: '/members/invite',
  user: ({ userId }) => `/members/${userId}`,
  userEdit: ({ userId }) => `/members/${userId}/edit`,
  userRole: ({ userId, roleId }) => `/members/${userId}/role/${roleId}`,
  userRoleEdit: ({ userId, roleId }) => `/members/${userId}/role/${roleId}/edit`,
  userWorkspace: ({ userId, workspaceId }) => `/members/${userId}/workspace/${workspaceId}`,
  userWorkspaceEdit: ({ userId, workspaceId }) =>
    `/members/${userId}/workspace/${workspaceId}/edit`,

  roles: '/members/roles',
  rolesCreate: '/members/roles/create',
  role: ({ roleId }) => `/members/roles/${roleId}`,
  roleEdit: ({ roleId }) => `/members/roles/${roleId}/edit`,

  accounts: '/accounts',
  account: ({ accountId }) => `/accounts/${accountId}`,
  accountTransactions: ({ accountId, type, id }) =>
    `/accounts/${accountId}/transactions/${type}${id ? `/${id}` : ''}`,
  accountUser: ({ accountId, userId }) => `/accounts/${accountId}/user/${userId}`,
  accountUserEdit: ({ accountId, userId }) => `/accounts/${accountId}/user/${userId}/edit`,
  accountSchedule: ({ accountId }) => `/accounts/${accountId}/schedule`,

  sessions: `/accounts/sessions`,
  session: ({ accountId, sessionId }) => `/accounts/sessions/${accountId}/${sessionId}`,
  sessionEdit: ({ accountId, sessionId }) => `/accounts/sessions/${accountId}/${sessionId}/edit`,
  sessionAccount: ({ accountId, sessionId }) =>
    `/accounts/sessions/${accountId}/${sessionId}/account/${accountId}`,

  lobby: '/lobby',
  lobbyTable: ({ tableId }) => `/lobby/${tableId}`,
  lobbyTableAccount: ({ tableId, accountId }) => `/lobby/${tableId}/account/${accountId}`,

  botSettings: '/bot-settings',
  botSettingsCreate: '/bot-settings/create',
  botSetting: ({ botSettingId }) => `/bot-settings/${botSettingId}`,
  botSettingError: ({ botSettingId }) => `/bot-settings/${botSettingId}/error`,
  botSettingErrorAccount: ({ botSettingId, accountId }) =>
    `/bot-settings/${botSettingId}/error/account/${accountId}`,
  botSettingErrorBotSettingRule: ({ botSettingId, botSettingRuleId }) =>
    `/bot-settings/${botSettingId}/error/bot-setting-rule/${botSettingRuleId}`,
  botSettingErrorBotSettingRuleEdit: ({ botSettingId, botSettingRuleId }) =>
    `/bot-settings/${botSettingId}/error/bot-setting-rule/${botSettingRuleId}/edit`,
  botSettingEdit: ({ botSettingId }) => `/bot-settings/${botSettingId}/edit`,

  botSettingsRules: '/bot-settings-rules',
  botSettingsRulesCreate: '/bot-settings-rules/create',
  botSettingRule: ({ botSettingRuleId }) => `/bot-settings-rules/${botSettingRuleId}`,
  botSettingRuleEdit: ({ botSettingRuleId }) => `/bot-settings-rules/${botSettingRuleId}/edit`,
  botSettingRuleBotSetting: ({ botSettingRuleId, botSettingId }) =>
    `/bot-settings-rules/${botSettingRuleId}/bot-setting/${botSettingId}`,
  botSettingRuleBotSettingEdit: ({ botSettingRuleId, botSettingId }) =>
    `/bot-settings-rules/${botSettingRuleId}/bot-setting/${botSettingId}/edit`,

  clubs: '/clubs',
  clubsCreate: '/clubs/create',
  club: ({ clubId }) => `/clubs/${clubId}`,
  clubEdit: ({ clubId }) => `/clubs/${clubId}/edit`,
  clubNotes: ({ clubId }) => `/clubs/${clubId}/notes`,
  clubLeague: ({ clubId, leagueId }) => `/clubs/${clubId}/league/${leagueId}`,
  clubLeagueEdit: ({ clubId, leagueId }) => `/clubs/${clubId}/league/${leagueId}/edit`,
  clubLeagueSuperLeague: ({ clubId, leagueId, superLeagueId }) =>
    `/clubs/${clubId}/league/${leagueId}/super-league/${superLeagueId}`,
  clubLeagueSuperLeagueEdit: ({ clubId, leagueId, superLeagueId }) =>
    `/clubs/${clubId}/league/${leagueId}/super-league/${superLeagueId}/edit`,
  clubWorkspace: ({ clubId, workspaceId }) => `/clubs/${clubId}/workspace/${workspaceId}`,
  clubWorkspaceEdit: ({ clubId, workspaceId }) => `/clubs/${clubId}/workspace/${workspaceId}/edit`,
  clubError: ({ clubId }) => `/clubs/${clubId}/error`,
  clubErrorAccount: ({ clubId, accountId }) => `/clubs/${clubId}/error/account/${accountId}`,

  leagues: '/clubs/leagues',
  leaguesCreate: '/clubs/leagues/create',
  league: ({ leagueId }) => `/clubs/leagues/${leagueId}`,
  leagueEdit: ({ leagueId }) => `/clubs/leagues/${leagueId}/edit`,
  leagueSuperLeague: ({ leagueId, superLeagueId }) =>
    `/clubs/leagues/${leagueId}/super-league/${superLeagueId}`,
  leagueSuperLeagueEdit: ({ leagueId, superLeagueId }) =>
    `/clubs/leagues/${leagueId}/super-league/${superLeagueId}/edit`,

  superLeagues: '/clubs/super-leagues',
  superLeaguesCreate: '/clubs/super-leagues/create',
  superLeague: ({ superLeagueId }) => `/clubs/super-leagues/${superLeagueId}`,
  superLeagueEdit: ({ superLeagueId }) => `/clubs/super-leagues/${superLeagueId}/edit`,
};

export default routes;
