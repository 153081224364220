import { z } from 'zod';
import dateTimeSchema from './dateTimeSchema';
import globalUserSchema from './globalUserSchema';
import workspaceSchema from './workspaceSchema';

export const ACTION_TYPE = {
  UNKNOWN: 0,
  NOTE_CREATED: 1,
  NOTE_UPDATED: 2,
  ACCOUNT_MOVED: 3,
  CM_MANAGER_STOPPED: 4,
  CHANNEL_ACTIVATED: 5,
  CHANNEL_DEACTIVATED: 6,
  CM_TRANSACTION_FAILED: 7,
  ANNOUNCEMENT_CHANGED: 8,
  ACCOUNT_START_FAILED: 9,
  ACCOUNT_CRITICAL_ERROR: 10,
} as const;

export const INSTANCE_TYPE = {
  UNKNOWN: 0,
  ACCOUNT: 1,
  IDENTITY: 2,
  CHANNEL: 3,
  USER: 4,
  CM_MANAGER: 5,
  CM_TRANSACTION: 6,
  /*  STRATEGY_PROFILE: 7, */
  ANNOUNCEMENT: 8,
} as const;

const instanceSchema = z.object({
  id: z.string(),
  type: z.nativeEnum(INSTANCE_TYPE),
  displayed_name: z.string(),
});

const authorSchema = globalUserSchema.pick({ id: true, username: true });

const notificationSchema = z.object({
  id: z.string(),
  user_id: globalUserSchema.shape.id,
  message: z.string().or(z.null()),
  action_type: z.nativeEnum(ACTION_TYPE),
  author: authorSchema.or(z.null()),
  workspace: workspaceSchema.pick({ id: true, name: true }),
  instance: instanceSchema,
  is_read: z.boolean(),
  read_date: dateTimeSchema.or(z.null()),
  created_on: dateTimeSchema,
});

export const notificationFilterValuesSchema = z.object({
  workspace: z.array(
    z.object({
      id: notificationSchema.shape.workspace.shape.id,
      name: notificationSchema.shape.workspace.shape.name,
    })
  ),
  action_type: z.array(notificationSchema.shape.action_type),
  author: z.array(
    z.object({
      id: authorSchema.shape.id,
      name: authorSchema.shape.username,
    })
  ),
});

export type NotificationSchema = z.infer<typeof notificationSchema>;

export default notificationSchema;
