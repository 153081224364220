import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { updateWorkspaceAccountsStage } from '../../../../api';
import { WorkspaceStageSchema } from '../../../../api/schemas/stage/workspaceStageSchema';
import { WorkspaceSchema } from '../../../../api/schemas/workspaceSchema';
import Button from '../../../../components/Button';
import Confirm from '../../../../components/Confirm';
import Dialog from '../../../../components/Dialog';
import SortableList from '../../../../components/SortableList';
import Spinner from '../../../../components/Spinner';
import Tooltip from '../../../../components/Tooltip';
import useURL from '../../../../hooks/useURL';
import icon from '../../../../static/icons';
import AccountStageTooltip from '../../../account/AccountStageTooltip';
import { Editing } from '../types';
import WorkspaceAccountStagesForm from '../WorkspaceAccountStagesForm';
import { useWorkspaceAccountStageDelete, useWorkspaceAccountStageUpdate } from './mutations';
import styles from './styles.module.scss';

interface Props {
  workspaceId: WorkspaceSchema['id'];
  data: WorkspaceStageSchema;
  editing: Editing;
  index: number;
}

const WorkspaceAccountStage = (props: Props) => {
  const { workspaceId, editing, index, data } = props;

  const { id: stageId, name, is_system, order } = data;

  const { t } = useTranslation();
  const { urlParams, setURLParams } = useURL();

  const workspaceAccountStageUpdate = useWorkspaceAccountStageUpdate();
  const workspaceAccountStageDelete = useWorkspaceAccountStageDelete();

  const loading = workspaceAccountStageUpdate.isPending || workspaceAccountStageDelete.isPending;

  const { stage_order_eq } = urlParams;

  const getActive = () => {
    if (!stage_order_eq && index === 0) return true;

    return Number(stage_order_eq) === order;
  };

  if (loading) {
    return (
      <div className={styles.spinner}>
        <Spinner />
      </div>
    );
  }

  if (editing) {
    return (
      <SortableList.Item id={stageId} className={clsx(styles.container, styles.editing)}>
        <SortableList.Item.Handle id={stageId}>
          {({ attributes, listeners, setActivatorNodeRef }) => (
            <Tooltip hideOnMobile label={t('common.move')}>
              <Button
                ref={setActivatorNodeRef}
                size="extra-small"
                icon={icon('switch', 16)}
                variant="transparent"
                className={styles.handle}
                {...attributes}
                {...listeners}
              />
            </Tooltip>
          )}
        </SortableList.Item.Handle>
        <span>{name}</span>
        {!is_system && (
          <Dialog
            label={({ referenceProps }) => (
              <Tooltip hideOnMobile label={t('common.edit')}>
                <Button
                  size="extra-small"
                  icon={icon('edit', 16)}
                  variant="transparent"
                  {...referenceProps}
                />
              </Tooltip>
            )}
          >
            {({ close }) => (
              <>
                <Dialog.Top>
                  <Dialog.Title>{data.name}</Dialog.Title>
                  <Confirm
                    onConfirm={() => workspaceAccountStageDelete.mutate({ workspaceId, stageId })}
                    title={t('sentences.delete_this_stage')}
                    confirmText={t('common.delete')}
                  >
                    {({ confirming }) => (
                      <Button
                        danger
                        size="small"
                        focused={confirming}
                        icon={icon('trash', 16)}
                        variant="secondary"
                        className={styles.delete}
                      />
                    )}
                  </Confirm>
                </Dialog.Top>
                <WorkspaceAccountStagesForm
                  data={data}
                  onSubmit={async (values) => {
                    await updateWorkspaceAccountsStage({ workspaceId, stageId, payload: values });

                    close();
                  }}
                />
              </>
            )}
          </Dialog>
        )}
      </SortableList.Item>
    );
  }

  return (
    <Tooltip delay={1} hideOnMobile label={<AccountStageTooltip data={data} />}>
      <button
        type="button"
        onClick={() => setURLParams({ stage_order_eq: order })}
        className={clsx(styles.container, getActive() && styles.active)}
      >
        <span>{name}</span>
      </button>
    </Tooltip>
  );
};

export default WorkspaceAccountStage;
