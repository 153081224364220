import { useTranslation } from 'react-i18next';
import { Outlet, useParams } from 'react-router-dom';
import { loadGlobalBotSettingsRules } from '../../api';
import { BotSettingRuleSchema } from '../../api/schemas/botSettingRuleSchema';
import Card from '../../components/Card';
import Page from '../../components/Page';
import PageControls from '../../components/PageControls';
import PageTop from '../../components/PageTop';
import Table, { getTableProps } from '../../components/Table';
import queryKeys from '../../config/queryKeys';
import routes from '../../config/routes';
import GlobalBotSettingRuleBotSetting from '../../features/global-bot-setting-rule/GlobalBotSettingRuleBotSetting';
import GlobalBotSettingRuleCreateButton from '../../features/global-bot-setting-rule/GlobalBotSettingRuleCreateButton';
import GlobalBotSettingRuleDetailLink from '../../features/global-bot-setting-rule/GlobalBotSettingRuleDetailLink';
import GlobalBotSettingRuleDropdown from '../../features/global-bot-setting-rule/GlobalBotSettingRuleDropdown';
import GlobalBotSettingRuleFilter from '../../features/global-bot-setting-rule/GlobalBotSettingRuleFilter';
import { getPokerRoom, getWorkspace } from '../../features/global-bot-setting-rule/helpers';
import { can } from '../../features/permission/helpers';
import useBreakpoints from '../../hooks/useBreakpoints';
import usePaginatedData from '../../hooks/usePaginatedData';
import useURL from '../../hooks/useURL';
import useWebSocketPaginatedActions from '../../hooks/useWebSocketPaginatedActions';
import { addWhen } from '../../utils';
import { RouteParams } from '../../utils/generics';
import { TRAINER_GAME_TYPE_LABELS } from '../../utils/trainer';
import Error403Page from '../Error403Page';
import styles from './styles.module.scss';

type Params = RouteParams<typeof routes.botSettingRule>;

const GlobalBotSettingsRulesPage = () => {
  const { t } = useTranslation();
  const { isDesktop, isTablet, isPhone } = useBreakpoints();
  const { botSettingRuleId } = useParams<Params>();
  const { urlParams } = useURL();
  const { search } = urlParams;

  const queryKey = [queryKeys.botSettingsRules, urlParams];

  const query = usePaginatedData({
    queryKey,
    queryFn: (params) => loadGlobalBotSettingsRules({ ...urlParams, ...params }),
  });

  const { data, total, loading, error, checkIsInView } = query;

  const { checkIsCreated, ...meta } = useWebSocketPaginatedActions<
    BotSettingRuleSchema,
    BotSettingRuleSchema
  >({
    queryKey,
    domain: 'GlobalBotSettingsUsageRule',
    select: (item) => item,
  });

  if (error?.response?.status === 403) return <Error403Page />;

  return (
    <Page
      heading={t('common.usage_rules')}
      tabs={[
        ...addWhen(
          { to: routes.botSettings, label: t('common.bot_settings') },
          can.botSetting.read
        ),
        ...addWhen(
          { to: routes.botSettingsRules, label: t('common.usage_rules'), active: true },
          can.botSetting.ruleRead
        ),
      ]}
      extra={
        <PageControls>
          <GlobalBotSettingRuleCreateButton />
        </PageControls>
      }
      filter={<GlobalBotSettingRuleFilter total={total} />}
      addon={<Outlet />}
    >
      <PageTop
        total={total}
        loading={loading}
        {...(isPhone && {
          sorting: [
            { key: 'game_type', title: t('common.game_type'), default: true },
            { key: 'name', title: t('common.name') },
            { key: 'poker_room', title: t('common.poker_room') },
          ],
        })}
        meta={meta}
      />
      {(isDesktop || isTablet) && (
        <Table
          data={data}
          search={search}
          {...getTableProps(query)}
          className={styles.table}
          columns={[
            {
              key: 'name',
              sorting: true,
              title: t('common.name'),
              render: ({ values }) => <GlobalBotSettingRuleDetailLink data={values} />,
            },
            {
              key: 'game_type',
              sorting: true,
              title: t('common.game_type'),
              render: ({ values }) => TRAINER_GAME_TYPE_LABELS[values.game_type],
            },
            {
              title: t('common.bot_settings'),
              render: ({ values }) => <GlobalBotSettingRuleBotSetting data={values} />,
            },
            {
              key: 'poker_room',
              sorting: true,
              title: t('common.poker_room'),
              render: ({ values }) => getPokerRoom(values),
            },
            {
              title: t('common.workspace'),
              render: ({ values }) => getWorkspace(values),
            },
            {
              render: ({ values }) => <GlobalBotSettingRuleDropdown data={values} />,
              align: 'right',
              controls: true,
            },
          ]}
          renderRow={(row, index) => (
            <Table.Row
              highlighted={checkIsCreated(row.data.id)}
              active={row.data.id === botSettingRuleId}
              danger={row.data.is_deleted}
              {...checkIsInView(index)}
              {...row}
            />
          )}
        />
      )}
      {isPhone && (
        <Card.Group>
          {data.map((item, index) => (
            <Card
              key={item.id}
              highlighted={checkIsCreated(item.id)}
              danger={item.is_deleted}
              {...checkIsInView(index)}
            >
              <Card.Top>
                <GlobalBotSettingRuleDetailLink data={item} />
                <Card.Top.Controls>
                  <GlobalBotSettingRuleDropdown data={item} />
                </Card.Top.Controls>
              </Card.Top>
              <Card.Field.Group>
                <Card.Field label={t('common.game_type')}>
                  {TRAINER_GAME_TYPE_LABELS[item.game_type]}
                </Card.Field>
                <Card.Field label={t('common.bot_settings')}>
                  <GlobalBotSettingRuleBotSetting data={item} />
                </Card.Field>
                <Card.Field label={t('common.poker_room')}>{getPokerRoom(item)}</Card.Field>
                <Card.Field label={t('common.workspace')}>{getWorkspace(item)}</Card.Field>
              </Card.Field.Group>
            </Card>
          ))}
        </Card.Group>
      )}
    </Page>
  );
};

export default GlobalBotSettingsRulesPage;
