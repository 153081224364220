import { RouteObject } from 'react-router-dom';
import routes from '../../config/routes';
import GlobalAgentDetail from '../../features/global-agent/GlobalAgentDetail';
import GlobalAgentForm from '../../features/global-agent/GlobalAgentForm';
import GlobalAgentLoader from '../../features/global-agent/GlobalAgentLoader';
import GlobalAgentNotes from '../../features/global-agent/GlobalAgentNotes';
import GlobalAgentsPage from '../../pages/GlobalAgentsPage';
import Notes from '../Notes';
import Route from './Route';

const page = <GlobalAgentsPage />;

const createForm = (
  <Route>
    {({ navigate }) => (
      <GlobalAgentForm
        onClose={() => navigate(routes.agents)}
        onCreate={({ agentId }) => navigate(routes.agent({ agentId }))}
      />
    )}
  </Route>
);

const detail = (
  <Route params={['agentId']}>
    {({ agentId, navigate }) => (
      <GlobalAgentLoader agentId={agentId}>
        {({ data }) => (
          <GlobalAgentDetail
            data={data}
            onClose={() => navigate(routes.agents)}
            onEdit={() => navigate(routes.agentEdit({ agentId }))}
            getNotesRoute={() => navigate(routes.agentNotes({ agentId }))}
          />
        )}
      </GlobalAgentLoader>
    )}
  </Route>
);

const detailNotes = (
  <Route params={['agentId']}>
    {({ agentId, navigate }) => (
      <GlobalAgentNotes agentId={agentId}>
        {(loader) => <Notes onClose={() => navigate(routes.agent({ agentId }))} {...loader} />}
      </GlobalAgentNotes>
    )}
  </Route>
);

const editForm = (
  <Route params={['agentId']}>
    {({ agentId, navigate }) => (
      <GlobalAgentLoader agentId={agentId}>
        {({ data }) => (
          <GlobalAgentForm
            data={data}
            onClose={() => navigate(routes.agent({ agentId }))}
            onEdit={() => navigate(routes.agent({ agentId }))}
          />
        )}
      </GlobalAgentLoader>
    )}
  </Route>
);

const globalAgentsPage: RouteObject = {
  path: routes.agents,
  element: page,
  children: [
    {
      path: routes.agentsCreate,
      element: createForm,
    },
    {
      path: routes.agent({ agentId: ':agentId' }),
      element: detail,
      children: [
        {
          path: routes.agentNotes({ agentId: ':agentId' }),
          element: detailNotes,
        },
      ],
    },
    {
      path: routes.agentEdit({ agentId: ':agentId' }),
      element: editForm,
    },
  ],
};

export default globalAgentsPage;
