import { useTranslation } from 'react-i18next';
import { Outlet, useParams } from 'react-router-dom';
import { loadGlobalWorkspaces } from '../../api';
import { WorkspaceSchema } from '../../api/schemas/workspaceSchema';
import Card from '../../components/Card';
import Page from '../../components/Page';
import PageControls from '../../components/PageControls';
import PageTop from '../../components/PageTop';
import Table, { getTableProps } from '../../components/Table';
import Tag from '../../components/Tag';
import queryKeys from '../../config/queryKeys';
import routes from '../../config/routes';
import { GlobalClubProvider } from '../../features/global-club/GlobalClubContext';
import GlobalWorkspaceCreateButton from '../../features/global-workspace/GlobalWorkspaceCreateButton';
import GlobalWorkspaceDetailLink from '../../features/global-workspace/GlobalWorkspaceDetailLink';
import GlobalWorkspaceDropdown from '../../features/global-workspace/GlobalWorkspaceDropdown';
import GlobalWorkspaceFilter from '../../features/global-workspace/GlobalWorkspaceFilter';
import GlobalWorkspaceTypes from '../../features/global-workspace/GlobalWorkspaceTypes';
import useBreakpoints from '../../hooks/useBreakpoints';
import usePaginatedData from '../../hooks/usePaginatedData';
import useURL from '../../hooks/useURL';
import useWebSocketPaginatedActions from '../../hooks/useWebSocketPaginatedActions';
import { RouteParams } from '../../utils/generics';
import { TRAINER_POKER_ROOM_LABELS, TRAINER_TECH_LABELS } from '../../utils/trainer';
import Error403Page from '../Error403Page';
import styles from './styles.module.scss';

type Params = RouteParams<typeof routes.globalWorkspace>;

const GlobalWorkspacesPage = () => {
  const { t } = useTranslation();
  const { isDesktop, isTablet, isPhone } = useBreakpoints();
  const { workspaceId } = useParams<Params>();
  const { urlParams } = useURL();
  const { search } = urlParams;

  const queryKey = [queryKeys.globalWorkspaces, urlParams];

  const query = usePaginatedData({
    queryKey,
    queryFn: (params) => loadGlobalWorkspaces({ ...urlParams, ...params }),
  });

  const { data, total, loading, error, checkIsInView } = query;

  const { checkIsCreated, ...meta } = useWebSocketPaginatedActions<
    WorkspaceSchema,
    WorkspaceSchema
  >({
    queryKey,
    domain: 'GlobalMaintenance',
    updateAction: ['Edited', 'NegativeBalancePilotAllowed', 'NegativeBalancePilotDisallowed'],
    select: (item) => item,
  });

  if (error?.response?.status === 403) return <Error403Page />;

  const renderTrainerTech = (trainerTech: WorkspaceSchema['trainer_tech']) => {
    if (trainerTech.length) {
      const [first, ...rest] = trainerTech;

      return (
        <Tag
          count={trainerTech.length}
          countTooltip={rest.map((item) => TRAINER_TECH_LABELS[item]).join(', ')}
        >
          <Tag.FadingText text={TRAINER_TECH_LABELS[first]} />
        </Tag>
      );
    }

    return '—';
  };

  const renderPokerRooms = (pokerRooms: WorkspaceSchema['poker_rooms']) => {
    if (pokerRooms.length) {
      const [first, ...rest] = pokerRooms;

      return (
        <Tag
          count={pokerRooms.length}
          countTooltip={rest.map((item) => TRAINER_POKER_ROOM_LABELS[item]).join(', ')}
        >
          <Tag.FadingText text={TRAINER_POKER_ROOM_LABELS[first]} />
        </Tag>
      );
    }

    return '—';
  };

  return (
    <GlobalClubProvider>
      <Page
        heading={t('common.workspaces')}
        filter={<GlobalWorkspaceFilter total={total} />}
        extra={
          <PageControls filter={false}>
            <GlobalWorkspaceCreateButton />
          </PageControls>
        }
        addon={<Outlet />}
      >
        <PageTop
          total={total}
          loading={loading}
          {...(isPhone && {
            sorting: [{ key: 'name', title: t('common.name'), default: true }],
          })}
          meta={meta}
        />
        {(isDesktop || isTablet) && (
          <Table
            data={data}
            search={search}
            {...getTableProps(query)}
            className={styles.table}
            columns={[
              {
                key: 'name',
                title: t('common.name'),
                sorting: true,
                render: ({ values }) => <GlobalWorkspaceDetailLink data={values} />,
              },
              {
                title: t('common.types'),
                render: ({ values }) => <GlobalWorkspaceTypes types={values.maintenance_types} />,
              },
              {
                title: t('common.trainer_technologies'),
                render: ({ values }) => renderTrainerTech(values.trainer_tech),
              },
              {
                title: t('common.poker_rooms'),
                render: ({ values }) => renderPokerRooms(values.poker_rooms),
              },
              {
                render: ({ values }) => <GlobalWorkspaceDropdown data={values} />,
                align: 'right',
                controls: true,
              },
            ]}
            renderRow={(row, index) => (
              <Table.Row
                highlighted={checkIsCreated(row.data.id)}
                active={row.data.id === workspaceId}
                danger={row.data.is_deleted}
                {...checkIsInView(index)}
                {...row}
              />
            )}
          />
        )}
        {isPhone && (
          <Card.Group>
            {data.map((item, index) => (
              <Card
                key={item.id}
                highlighted={checkIsCreated(item.id)}
                danger={item.is_deleted}
                {...checkIsInView(index)}
              >
                <Card.Top>
                  <GlobalWorkspaceDetailLink data={item} />
                  <Card.Top.Controls>
                    <GlobalWorkspaceDropdown data={item} />
                  </Card.Top.Controls>
                </Card.Top>
                <Card.Field.Group>
                  <Card.Field label={t('common.types')}>
                    <GlobalWorkspaceTypes types={item.maintenance_types} />
                  </Card.Field>
                  <Card.Field label={t('common.trainer_technologies')}>
                    {renderTrainerTech(item.trainer_tech)}
                  </Card.Field>
                  <Card.Field label={t('common.poker_rooms')}>
                    {renderPokerRooms(item.poker_rooms)}
                  </Card.Field>
                </Card.Field.Group>
              </Card>
            ))}
          </Card.Group>
        )}
      </Page>
    </GlobalClubProvider>
  );
};

export default GlobalWorkspacesPage;
