import { FieldValues, useController, UseControllerProps } from 'react-hook-form';
import Checkbox from '.';

type Props<T extends FieldValues> = UseControllerProps<T> & {
  onSubmit: () => void;
  disabled?: boolean;
};

const CheckboxQuick = <T extends FieldValues>(props: Props<T>) => {
  const { name, control, rules, onSubmit, disabled } = props;

  const {
    field,
    formState: { isValid },
  } = useController({ name, control, rules });

  return (
    <Checkbox
      variant="switch"
      checked={field.value}
      disabled={disabled}
      {...field}
      onChange={(event) => {
        if (isValid) {
          field.onChange(event);
          onSubmit();
        }
      }}
    />
  );
};

export default CheckboxQuick;
