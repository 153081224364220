import { useQuery } from '@tanstack/react-query';
import { ReactElement } from 'react';
import { loadGlobalAccountSession } from '../../api';
import { GlobalAccountSchema } from '../../api/schemas/account/globalAccountSchema';
import { GlobalSessionSchema } from '../../api/schemas/session/globalSessionSchema';
import PageAddon from '../../components/PageAddon';
import queryKeys from '../../config/queryKeys';
import useWebSocketMessage from '../../hooks/useWebSocketMessage';
import { updateDetailQueryData } from '../../utils/queryClient';

interface Props {
  accountId: GlobalAccountSchema['id'];
  sessionId: GlobalSessionSchema['id'];
  children: (args: {
    data: GlobalSessionSchema;
    sessionId: GlobalSessionSchema['id'];
  }) => ReactElement;
}

const GlobalSessionLoader = (props: Props) => {
  const { accountId, sessionId, children } = props;

  const queryKey = [queryKeys.session({ sessionId })];
  const queryFn = () => loadGlobalAccountSession({ accountId, sessionId });

  const { data, isPending } = useQuery({
    queryKey,
    queryFn,
    meta: { ignoredStatuses: [403] },
  });

  useWebSocketMessage<GlobalSessionSchema>({
    domain: 'GlobalSession',
    onMessage: (message) => {
      if (
        (message.action === 'Edited' ||
          message.action === 'Deleted' ||
          message.action === 'SessionStarted' ||
          message.action === 'SessionFinished' ||
          message.action === 'SwitchOnRoom' ||
          message.action === 'SwitchOffRoom') &&
        message.payload.id === sessionId
      ) {
        updateDetailQueryData(queryKey, message.payload);
      }
    },
  });

  if (isPending) return <PageAddon.DetailPlaceholder />;
  if (!data) return null;

  return children({ data, sessionId });
};

export default GlobalSessionLoader;
