import { useTranslation } from 'react-i18next';
import useTimeZone from '../../../hooks/useTimeZone';
import { useTimeZoneStore } from '../../../store/timeZone';
import { isDateAfter, isDateBefore } from '../../../utils/date';
import { timeZone as timeZoneRegExp } from '../../../utils/regExp';
import DatePicker from '../../DatePicker';
import styles from './styles.module.scss';

const getValue = (value: string) => (value ? value.replace(timeZoneRegExp, '') : '');

interface Field {
  value: string;
  onChange: (value: string) => void;
}

interface Props {
  from: Field;
  to: Field;
}

const FilterDate = (props: Props) => {
  const { from, to } = props;

  const { t } = useTranslation();
  const { timeZone } = useTimeZoneStore((state) => ({ timeZone: state.timeZone }));
  const { formatTimeZone } = useTimeZone();

  const convertValue = (value: string) => {
    if (!value) return '';

    return `${value}${formatTimeZone(timeZone).replace('UTC', '')}`;
  };

  return (
    <div className={styles.container}>
      <DatePicker
        withTime
        size="medium"
        placeholder={t('common.start')}
        value={getValue(from.value)}
        disabledDate={(date) => isDateAfter(date, to.value)}
        onChange={(value) => from.onChange(convertValue(value))}
      />
      <span>—</span>
      <DatePicker
        withTime={{ selectEndOfDay: true }}
        size="medium"
        placeholder={t('common.end')}
        value={getValue(to.value)}
        disabledDate={(date) => isDateBefore(date, from.value)}
        onChange={(value) => to.onChange(convertValue(value))}
      />
    </div>
  );
};

export default FilterDate;
