import { useTranslation } from 'react-i18next';
import icon from '../../../../static/icons';
import Button from '../../../Button';
import Tooltip from '../../../Tooltip';
import styles from './styles.module.scss';

interface Props {
  onClick: () => void;
}

const SelectQuickLink = (props: Props) => {
  const { onClick } = props;

  const { t } = useTranslation();

  return (
    <Tooltip label={t('common.open')}>
      <Button
        size="extra-small"
        variant="light"
        className={styles.link}
        icon={icon('externalLink', 16)}
        onClick={onClick}
      />
    </Tooltip>
  );
};

export default SelectQuickLink;
