import { ReactElement } from 'react';
import { deleteGlobalAgentNote, loadGlobalAgentNotes } from '../../../api';
import { AgentSchema } from '../../../api/schemas/agentSchema';
import { Props as NotesProps } from '../../../components/Notes';
import queryKeys from '../../../config/queryKeys';
import usePaginatedDataNew from '../../../hooks/usePaginatedDataNew';
import { useCreateGlobalAgentNoteMutation, useUpdateGlobalAgentNoteMutation } from './mutations';

interface Props {
  agentId: AgentSchema['id'];
  children: (args: NotesProps) => ReactElement;
}

const GlobalAgentNotes = (props: Props) => {
  const { agentId, children } = props;

  const queryKey = [queryKeys.agentNotes({ agentId })];

  const query = usePaginatedDataNew({
    queryKey,
    queryFn: (params) => loadGlobalAgentNotes({ agentId }, { ...params, order_by: 'desc' }),
  });

  const createNote = useCreateGlobalAgentNoteMutation();
  const updateNote = useUpdateGlobalAgentNoteMutation();

  return children({
    query,
    creating: createNote.isPending,
    updating: updateNote.isPending,
    onCreate: ({ payload }) => createNote.mutateAsync({ agentId, payload }),
    onUpdate: ({ noteId, payload }) => updateNote.mutateAsync({ agentId, noteId, payload }),
    onDelete: ({ noteId }) => deleteGlobalAgentNote({ agentId, noteId }),
    onCreateMessage: (message) => message.payload.instance.id === agentId,
  });
};

export default GlobalAgentNotes;
