import { useTranslation } from 'react-i18next';
import { WorkspaceAccountSchema } from '../../../api/schemas/account/workspaceAccountSchema';
import { WorkspaceSchema } from '../../../api/schemas/workspaceSchema';
import PageDetailLink from '../../../components/PageDetailLink';
import Tooltip from '../../../components/Tooltip';
import routes from '../../../config/routes';
import icon from '../../../static/icons';
import AccountScale from '../../account/AccountScale';
import styles from './styles.module.scss';

interface Props {
  workspaceId: WorkspaceSchema['id'];
  data: WorkspaceAccountSchema;
}

const WorkspaceAccountDetailLink = (props: Props) => {
  const { workspaceId, data } = props;

  const { id, login, on_verification, notes_count } = data;

  const { t } = useTranslation();

  return (
    <div className={styles.container}>
      <PageDetailLink
        label={login}
        to={routes.workspaceAccount({ workspaceId, accountId: id })}
        extra={
          <>
            {Boolean(notes_count) && (
              <Tooltip label={t('common.notes')}>{icon('notes', 12)}</Tooltip>
            )}
            {on_verification && (
              <Tooltip label={t('common.pending_verification')}>{icon('checkCircle', 12)}</Tooltip>
            )}
          </>
        }
      />
      <AccountScale data={data} />
    </div>
  );
};

export default WorkspaceAccountDetailLink;
