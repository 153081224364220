import { RouteObject } from 'react-router-dom';
import routes from '../../config/routes';
import WorkspaceSettingsGeneral from '../../features/workspace-settings/WorkspaceSettingsGeneral';
import WorkspaceSettingsRules from '../../features/workspace-settings/WorkspaceSettingsRules';
import WorkspaceSettingsRulesLoader from '../../features/workspace-settings/WorkspaceSettingsRules/WorkspaceSettingsRulesLoader';
import WorkspaceSettingsStrategyProfile from '../../features/workspace-settings/WorkspaceSettingsStrategyProfile';
import WorkspaceSettingsStrategyProfileLoader from '../../features/workspace-settings/WorkspaceSettingsStrategyProfile/WorkspaceSettingsStrategyProfileLoader';
import WorkspaceSettingsTags from '../../features/workspace-settings/WorkspaceSettingsTags';
import WorkspaceSettingsTagsLoader from '../../features/workspace-settings/WorkspaceSettingsTags/WorkspaceSettingsTagsLoader';
import WorkspaceSettingsTokens from '../../features/workspace-settings/WorkspaceSettingsTokens';
import WorkspaceSettingsTokensLoader from '../../features/workspace-settings/WorkspaceSettingsTokens/WorkspaceSettingsTokensLoader';
import WorkspaceSettingsWallets from '../../features/workspace-settings/WorkspaceSettingsWallets';
import WorkspaceSettingsWalletsLoader from '../../features/workspace-settings/WorkspaceSettingsWallets/WorkspaceSettingsWalletsLoader';
import WorkspaceSettingsPage from '../../pages/WorkspaceSettingsPage';
import Route from './Route';

const page = (
  <Route params={['workspaceId']}>
    {({ workspaceId }) => <WorkspaceSettingsPage workspaceId={workspaceId} />}
  </Route>
);

const general = (
  <Route params={['workspaceId']}>
    {({ workspaceId }) => <WorkspaceSettingsGeneral workspaceId={workspaceId} />}
  </Route>
);

const wallets = (
  <Route params={['workspaceId']}>
    {({ workspaceId }) => (
      <WorkspaceSettingsWalletsLoader workspaceId={workspaceId}>
        {({ data }) => <WorkspaceSettingsWallets workspaceId={workspaceId} data={data} />}
      </WorkspaceSettingsWalletsLoader>
    )}
  </Route>
);

const strategyProfile = (
  <Route params={['workspaceId']}>
    {({ workspaceId }) => (
      <WorkspaceSettingsStrategyProfileLoader workspaceId={workspaceId}>
        {({ data }) => <WorkspaceSettingsStrategyProfile workspaceId={workspaceId} data={data} />}
      </WorkspaceSettingsStrategyProfileLoader>
    )}
  </Route>
);

const tokens = (
  <Route params={['workspaceId']}>
    {({ workspaceId }) => (
      <WorkspaceSettingsTokensLoader workspaceId={workspaceId}>
        {({ data }) => <WorkspaceSettingsTokens workspaceId={workspaceId} data={data} />}
      </WorkspaceSettingsTokensLoader>
    )}
  </Route>
);

const tags = (
  <Route params={['workspaceId']}>
    {({ workspaceId }) => (
      <WorkspaceSettingsTagsLoader workspaceId={workspaceId}>
        {({ data }) => <WorkspaceSettingsTags workspaceId={workspaceId} data={data} />}
      </WorkspaceSettingsTagsLoader>
    )}
  </Route>
);

const rules = (
  <Route params={['workspaceId']}>
    {({ workspaceId }) => (
      <WorkspaceSettingsRulesLoader workspaceId={workspaceId}>
        {({ data }) => <WorkspaceSettingsRules workspaceId={workspaceId} data={data} />}
      </WorkspaceSettingsRulesLoader>
    )}
  </Route>
);

const workspaceSettingsPage: RouteObject = {
  path: routes.workspaceSettings({ workspaceId: ':workspaceId' }),
  element: page,
  children: [
    {
      index: true,
      element: general,
    },
    {
      path: routes.workspaceSettingsWallets({ workspaceId: ':workspaceId' }),
      element: wallets,
    },
    {
      path: routes.workspaceSettingsStrategyProfile({ workspaceId: ':workspaceId' }),
      element: strategyProfile,
    },
    {
      path: routes.workspaceSettingsTokens({ workspaceId: ':workspaceId' }),
      element: tokens,
    },
    {
      path: routes.workspaceSettingsTags({ workspaceId: ':workspaceId' }),
      element: tags,
    },
    {
      path: routes.workspaceSettingsRules({ workspaceId: ':workspaceId' }),
      element: rules,
    },
  ],
};

export default workspaceSettingsPage;
