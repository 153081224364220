import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { Outlet, useParams } from 'react-router-dom';
import { loadGlobalDeals, loadGlobalDealsFilterValues } from '../../api';
import { GlobalDealSchema } from '../../api/schemas/deal/globalDealSchema';
import Card from '../../components/Card';
import Page from '../../components/Page';
import PageControls from '../../components/PageControls';
import PageTop from '../../components/PageTop';
import Table, { getTableProps } from '../../components/Table';
import queryKeys from '../../config/queryKeys';
import routes from '../../config/routes';
import { cardFields, columns } from '../../features/deal/config';
import DealDetailLink from '../../features/deal/DealDetailLink';
import { sortings } from '../../features/deal/helpers';
import { GlobalClubProvider } from '../../features/global-club/GlobalClubContext';
import GlobalDealCreateButton from '../../features/global-deal/GlobalDealCreateButton';
import GlobalDealDropdown from '../../features/global-deal/GlobalDealDropdown';
import GlobalDealFilter from '../../features/global-deal/GlobalDealFilter';
import GlobalDealRooms from '../../features/global-deal/GlobalDealRooms';
import { renderWorkspaces } from '../../features/global-deal/helpers';
import useTableColumnResize from '../../features/global-deal/useTableColumnResize';
import { can } from '../../features/permission/helpers';
import useBreakpoints from '../../hooks/useBreakpoints';
import usePaginatedDataNew from '../../hooks/usePaginatedDataNew';
import useURL from '../../hooks/useURL';
import useWebSocketMessage from '../../hooks/useWebSocketMessage';
import useWebSocketPaginatedActions from '../../hooks/useWebSocketPaginatedActions';
import { addWhen } from '../../utils';
import { RouteParams } from '../../utils/generics';
import { createInfinitePaginatedQueryData } from '../../utils/queryClient';
import Error403Page from '../Error403Page';

type Params = RouteParams<typeof routes.deal>;

const GlobalDealsPage = () => {
  const { t } = useTranslation();
  const { urlParams } = useURL();
  const { search, poker_room_in, ...rest } = urlParams;
  const { dealId } = useParams<Params>();
  const { isDesktop, isTablet, isPhone } = useBreakpoints();

  const queryKey = [queryKeys.deals, rest, search, poker_room_in];

  const query = usePaginatedDataNew({
    queryKey,
    queryFn: (params) =>
      loadGlobalDeals({
        ...rest,
        ...params,
        ...(search && { search }),
        ...(!search && poker_room_in && { poker_room_in }),
      }),
  });

  const { data, error, checkIsInView } = query;

  const { checkIsCreated } = useWebSocketPaginatedActions<GlobalDealSchema, GlobalDealSchema>({
    queryKey,
    domain: 'GlobalDeal',
    createAction: [],
    updateAction: ['Edited', 'NoteAdded', 'NoteRemoved'],
    select: (item) => item,
  });

  useWebSocketMessage<GlobalDealSchema>({
    domain: 'GlobalDeal',
    onMessage: (message) => {
      if (message.action === 'Created') {
        if (!poker_room_in || message.payload.poker_room === Number(poker_room_in)) {
          createInfinitePaginatedQueryData(queryKey, message.payload);
        }
      }
    },
  });

  const filterQueryKey = [queryKeys.dealsFilterValues, urlParams.show_deleted];
  const filterQueryFn = () => loadGlobalDealsFilterValues({ show_deleted: urlParams.show_deleted });

  const { data: filterData, isPending: filterIsPending } = useQuery({
    queryKey: filterQueryKey,
    queryFn: filterQueryFn,
    placeholderData: keepPreviousData,
    meta: { ignoredStatuses: [403] },
  });

  const columnResize = useTableColumnResize();

  if (error?.response?.status === 403) return <Error403Page />;

  return (
    <GlobalClubProvider>
      <Page
        heading={t('common.deals')}
        tabs={[
          { to: routes.deals, label: t('common.deals'), active: true },
          ...addWhen({ to: routes.agents, label: t('common.agents') }, can.agent.read),
        ]}
        filter={<GlobalDealFilter data={filterData} isPending={filterIsPending} />}
        extra={
          <PageControls>
            <GlobalDealCreateButton />
          </PageControls>
        }
        addon={<Outlet />}
      >
        {!search && <GlobalDealRooms data={filterData} loading={filterIsPending} />}
        {isPhone && <PageTop sorting={sortings} />}
        {(isDesktop || isTablet) && (
          <Table
            data={data}
            search={search}
            {...getTableProps(query)}
            {...columnResize}
            columns={[
              columns.code((values) => routes.deal({ dealId: values.id })),
              columns.agent_name((values) =>
                routes.dealAgent({ dealId: values.id, agentId: values.agent.id })
              ),
              columns.poker_room,
              columns.club_name,
              columns.club_id,
              columns.league,
              columns.super_league,
              columns.area_id,
              columns.area2_id,
              columns.holder_username,
              columns.owner,
              columns.role,
              columns.status,
              columns.credit,
              columns.protection,
              columns.currency,
              columns.chip_rate,
              columns.rake_back,
              columns.action,
              columns.rev_share,
              columns.win_share,
              columns.rebate,
              columns.agent_fee,
              columns.withdraw_fee,
              columns.payment_system,
              {
                key: 'workspaces',
                title: t('common.workspaces'),
                render: ({ values }) => renderWorkspaces(values),
                resize: true,
                style: { width: 120 },
              },
              {
                key: 'accounts',
                title: t('common.accounts'),
                render: ({ values }) => values.accounts.length,
                resize: true,
                style: { width: 100 },
              },
              columns.balance,
              columns.balance_usd,
              columns.modified,
              columns.created_on,
              {
                render: ({ values }) => <GlobalDealDropdown data={values} />,
                align: 'right',
                controls: true,
                pin: 'right',
                style: { width: 32, marginLeft: 'auto' },
              },
            ]}
            renderRow={(row, index) => (
              <Table.Row
                highlighted={checkIsCreated(row.data.id)}
                active={row.data.id === dealId}
                danger={row.data.is_deleted}
                {...checkIsInView(index)}
                {...row}
              />
            )}
          />
        )}
        {isPhone && (
          <Card.Group>
            {data.map((item, index) => (
              <Card
                key={item.id}
                highlighted={checkIsCreated(item.id)}
                danger={item.is_deleted}
                {...checkIsInView(index)}
              >
                <Card.Top>
                  <DealDetailLink data={item} to={routes.deal({ dealId: item.id })} />
                  <Card.Top.Controls>
                    <GlobalDealDropdown data={item} />
                  </Card.Top.Controls>
                </Card.Top>
                <Card.Field.Group>
                  {cardFields.code(item)}
                  {cardFields.agent_name(item)}
                  {cardFields.poker_room(item)}
                  {cardFields.club_name(item)}
                  {cardFields.club_id(item)}
                  {cardFields.league(item)}
                  {cardFields.super_league(item)}
                  {cardFields.area_id(item)}
                  {cardFields.area2_id(item)}
                  {cardFields.holder_username(item)}
                  {cardFields.owner(item)}
                  {cardFields.role(item)}
                  {cardFields.status(item)}
                  {cardFields.credit(item)}
                  {cardFields.protection(item)}
                  {cardFields.currency(item)}
                  {cardFields.chip_rate(item)}
                  {cardFields.rake_back(item)}
                  {cardFields.action(item)}
                  {cardFields.rev_share(item)}
                  {cardFields.win_share(item)}
                  {cardFields.rebate(item)}
                  {cardFields.agent_fee(item)}
                  {cardFields.withdraw_fee(item)}
                  {cardFields.payment_system(item)}
                  <Card.Field label={t('common.workspaces')}>{renderWorkspaces(item)}</Card.Field>
                  <Card.Field label={t('common.accounts')}>{item.accounts.length}</Card.Field>
                  {cardFields.balance(item)}
                  {cardFields.balance_usd(item)}
                  {cardFields.modified(item)}
                  {cardFields.created_on(item)}
                </Card.Field.Group>
              </Card>
            ))}
          </Card.Group>
        )}
      </Page>
    </GlobalClubProvider>
  );
};

export default GlobalDealsPage;
