import { useTranslation } from 'react-i18next';
import { Outlet, useParams } from 'react-router-dom';
import { loadWorkspaceSessions } from '../../api';
import { WorkspaceSessionSchema } from '../../api/schemas/session/workspaceSessionSchema';
import { WorkspaceSchema } from '../../api/schemas/workspaceSchema';
import Card from '../../components/Card';
import Page from '../../components/Page';
import PageControls from '../../components/PageControls';
import PageTop from '../../components/PageTop';
import Table, { getTableProps } from '../../components/Table';
import queryKeys from '../../config/queryKeys';
import routes from '../../config/routes';
import { renderClubs } from '../../features/session/helpers';
import SessionDuration from '../../features/session/SessionDuration';
import SessionStatus from '../../features/session/SessionStatus';
import WorkspaceSessionDetailLink from '../../features/workspace-session/WorkspaceSessionDetailLink';
import WorkspaceSessionDropdown from '../../features/workspace-session/WorkspaceSessionDropdown';
import WorkspaceSessionFilter from '../../features/workspace-session/WorkspaceSessionFilter';
import useBreakpoints from '../../hooks/useBreakpoints';
import usePaginatedDataNew from '../../hooks/usePaginatedDataNew';
import useURL from '../../hooks/useURL';
import useWebSocketPaginatedActions from '../../hooks/useWebSocketPaginatedActions';
import { RouteParams } from '../../utils/generics';
import Error403Page from '../Error403Page';
import styles from './styles.module.scss';

type Params = RouteParams<typeof routes.workspaceSession>;

interface Props {
  workspaceId: WorkspaceSchema['id'];
}

const WorkspaceSessionsPage = (props: Props) => {
  const { workspaceId } = props;

  const { t } = useTranslation();
  const { isDesktop, isTablet, isPhone } = useBreakpoints();
  const { sessionId } = useParams<Params>();
  const { urlParams } = useURL();
  const { search } = urlParams;

  const queryKey = [queryKeys.workspaceSessions({ workspaceId }), urlParams];

  const query = usePaginatedDataNew({
    queryKey,
    queryFn: (params) => loadWorkspaceSessions({ workspaceId }, { ...urlParams, ...params }),
  });

  const { data, error, checkIsInView } = query;

  const { checkIsCreated } = useWebSocketPaginatedActions<
    WorkspaceSessionSchema,
    WorkspaceSessionSchema
  >({
    queryKey,
    domain: 'Session',
    updateAction: ['Edited', 'SessionStarted', 'SessionFinished', 'SwitchOnRoom', 'SwitchOffRoom'],
    select: (item) => item,
    enabled: (message) => workspaceId === message.meta_info?.maintenance_id,
  });

  if (error?.response?.status === 403) return <Error403Page />;

  return (
    <Page
      heading={t('common.sessions')}
      filter={<WorkspaceSessionFilter workspaceId={workspaceId} urlParams={urlParams} />}
      tabs={[
        { to: routes.workspaceAccounts({ workspaceId }), label: t('common.accounts') },
        {
          to: routes.workspaceSessions({ workspaceId }),
          label: t('common.sessions'),
          active: true,
        },
      ]}
      extra={<PageControls />}
      addon={<Outlet />}
    >
      {isPhone && (
        <PageTop
          sorting={[
            { key: 'start', title: t('common.start'), default: true },
            { key: 'end', title: t('common.end') },
          ]}
        />
      )}
      {(isDesktop || isTablet) && (
        <Table
          data={data}
          search={search}
          {...getTableProps(query)}
          className={styles.table}
          columns={[
            {
              key: 'start',
              title: t('common.date'),
              sorting: true,
              render: ({ values }) => (
                <WorkspaceSessionDetailLink data={values} workspaceId={workspaceId} />
              ),
            },
            {
              title: t('common.status'),
              render: ({ values }) => <SessionStatus data={values} />,
            },
            {
              title: t('common.duration'),
              render: ({ values }) => <SessionDuration data={values} />,
            },
            {
              title: t('common.clubs'),
              render: ({ values }) => renderClubs(values.clubs),
            },
            {
              render: ({ values }) => (
                <WorkspaceSessionDropdown data={values} workspaceId={workspaceId} />
              ),
              align: 'right',
              controls: true,
            },
          ]}
          renderRow={(row, index) => (
            <Table.Row
              highlighted={checkIsCreated(row.data.id)}
              active={row.data.id === sessionId}
              danger={row.data.is_deleted}
              {...checkIsInView(index)}
              {...row}
            />
          )}
        />
      )}
      {isPhone && (
        <Card.Group>
          {data.map((item, index) => (
            <Card
              key={item.id}
              highlighted={checkIsCreated(item.id)}
              danger={item.is_deleted}
              {...checkIsInView(index)}
            >
              <Card.Top>
                <WorkspaceSessionDetailLink data={item} workspaceId={workspaceId} />
                <Card.Top.Controls>
                  <WorkspaceSessionDropdown data={item} workspaceId={workspaceId} />
                </Card.Top.Controls>
              </Card.Top>
              <Card.Field.Group>
                <Card.Field label={t('common.status')}>
                  <SessionStatus data={item} />
                </Card.Field>
                <Card.Field label={t('common.duration')}>
                  <SessionDuration data={item} />
                </Card.Field>
                <Card.Field label={t('common.clubs')}>{renderClubs(item.clubs)}</Card.Field>
              </Card.Field.Group>
            </Card>
          ))}
        </Card.Group>
      )}
    </Page>
  );
};

export default WorkspaceSessionsPage;
