import PrettyNumber from '../../components/PrettyNumber';
import { convertCentsToDollars } from '../../utils/data';
import { CommonDealSchema } from './types';

interface Props {
  data: CommonDealSchema;
}

const DealBalance = (props: Props) => {
  const { data } = props;

  const { balance } = data;

  return <PrettyNumber>{convertCentsToDollars(balance)}</PrettyNumber>;
};

export default DealBalance;
