import api from '../../services/api';
import globalDealSchema, { GlobalDealSchema } from '../schemas/deal/globalDealSchema';

type Schema = GlobalDealSchema;

interface Payload {
  agent_id: Schema['agent']['id'];
  poker_room: Schema['poker_room'];
  club_id: Schema['club']['id'];
  area_id?: Schema['area_id'] | null;
  area2_id?: Schema['area2_id'] | null;
  holder_user_id?: Exclude<Schema['holder'], null>['id'] | null;
  owner?: Schema['owner'] | null;
  status?: Schema['status'] | null;
  credit?: Schema['credit'] | null;
  protection?: Schema['protection'] | null;
  role?: Schema['role'] | null;
  currency?: Schema['currency'] | null;
  rake_back?: Schema['rake_back'] | null;
  action?: Schema['action'] | null;
  rev_share?: Schema['rev_share'] | null;
  win_share?: Schema['win_share'] | null;
  rebate?: Schema['rebate'] | null;
  agent_fee?: Schema['agent_fee'] | null;
  withdraw_fee?: Schema['withdraw_fee'] | null;
  payment_system?: Schema['payment_system'];
  workspace_ids?: Schema['workspaces'][number]['id'][];
  total?: Schema['total'] | null;
  financial_conditions?: Schema['financial_conditions'] | null;
  operating_conditions?: Schema['operating_conditions'] | null;
  policy?: Schema['policy'] | null;
  description?: Schema['description'] | null;
  reconciliation?: Schema['reconciliation'] | null;
}

interface Args {
  payload: Payload;
}

const createGlobalDeal = async (args: Args) => {
  const { payload } = args;

  const response = await api.post('/api/deal', payload);

  return globalDealSchema.pick({ id: true }).parse(response.data);
};

export default createGlobalDeal;
