import { useQuery } from '@tanstack/react-query';
import { useForm, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { createGlobalRole, loadPermissions, updateGlobalRole } from '../../../api';
import { GlobalRoleSchema } from '../../../api/schemas/globalRoleSchema';
import Form from '../../../components/Form';
import Input from '../../../components/Input';
import PageAddon from '../../../components/PageAddon';
import SubmitButton from '../../../components/SubmitButton';
import queryKeys from '../../../config/queryKeys';
import { maxLength, minLength, required } from '../../../utils/form';
import notify from '../../../utils/notify';
import PermissionFormField from '../../permission/PermissionFormField';
import { Fields } from '../types';
import styles from './styles.module.scss';

interface CommonProps {
  onClose: () => void;
}

interface CreateProps {
  onCreate: (args: { roleId: GlobalRoleSchema['id'] }) => void;
  data?: never;
  onEdit?: never;
}

interface EditProps {
  data: GlobalRoleSchema;
  onEdit: () => void;
  onCreate?: never;
}

type Props = CommonProps & (CreateProps | EditProps);

const GlobalRoleForm = (props: Props) => {
  const { data, onClose, onCreate, onEdit } = props;

  const { t } = useTranslation();

  const form = useForm<Fields>({
    defaultValues: {
      name: data?.name || '',
      permissions: data?.permissions || [],
    },
  });

  const {
    control,
    register,
    formState: { errors },
  } = form;

  const onSubmit = async (values: Fields) => {
    if (data) {
      await updateGlobalRole({ roleId: data.id, payload: values });

      onEdit();
    } else {
      const response = await createGlobalRole({ payload: values });

      onCreate({ roleId: response.id });
    }

    notify('success', {
      title: data ? t('sentences.changes_have_been_saved') : t('sentences.record_has_been_created'),
    });
  };

  const queryKey = [queryKeys.permissions];
  const queryFn = () => loadPermissions();

  const { data: permissionsData } = useQuery({
    queryKey,
    queryFn,
  });

  return (
    <PageAddon
      onClose={onClose}
      title={data ? `${t('common.edit')} ${data.name}` : t('common.create_role')}
    >
      <Form form={form} onSubmit={onSubmit}>
        <Input
          label={`${t('common.name')}*`}
          error={errors.name?.message}
          {...register('name', {
            validate: {
              required,
              minLength: minLength(),
              maxLength: maxLength(),
            },
          })}
        />
        <PageAddon.Heading level="first" className={styles.heading}>
          {t('common.permissions')}
        </PageAddon.Heading>
        {permissionsData && (
          <Controller
            name="permissions"
            control={control}
            render={({ field }) => (
              <PermissionFormField value={field.value} onChange={field.onChange} />
            )}
          />
        )}
        <PageAddon.Controls>
          <SubmitButton>{data ? t('common.save') : t('common.create')}</SubmitButton>
        </PageAddon.Controls>
      </Form>
    </PageAddon>
  );
};

export default GlobalRoleForm;
