import { useTranslation } from 'react-i18next';
import { Outlet } from 'react-router-dom';
import { BotSettingRuleSchema } from '../../api/schemas/botSettingRuleSchema';
import { BotSettingSchema } from '../../api/schemas/botSettingSchema';
import Button from '../../components/Button';
import FadingText from '../../components/FadingText';
import Link from '../../components/Link';
import PageAddon from '../../components/PageAddon';
import Tooltip from '../../components/Tooltip';
import icon from '../../static/icons';
import { formatAbsoluteDate } from '../../utils/date';
import { TRAINER_GAME_TYPE_LABELS } from '../../utils/trainer';
import { can } from '../permission/helpers';
import GlobalBotSettingRuleDetailDropdown from './GlobalBotSettingRuleDetailDropdown';
import { getPokerRoom, getWorkspace } from './helpers';

interface Props {
  data: BotSettingRuleSchema;
  onClose: () => void;
  onEdit: () => void;
  getBotSettingRoute?: (args: { botSettingId: BotSettingSchema['id'] }) => string;
}

const GlobalBotSettingRuleDetail = (props: Props) => {
  const { data, onClose, onEdit, getBotSettingRoute } = props;

  const { t } = useTranslation();

  const { id, name, game_type, bot_settings, description, is_deleted, modified_on, created_on } =
    data;

  const titleIcons = is_deleted && icon('trash', 12);

  const getBotSetting = () => {
    const content = <FadingText text={bot_settings.name} />;

    if (getBotSettingRoute) {
      return <Link to={getBotSettingRoute({ botSettingId: bot_settings.id })}>{content}</Link>;
    }

    return content;
  };

  return (
    <PageAddon.Group>
      <Outlet />
      <PageAddon title={name} icons={titleIcons} onClose={onClose}>
        <PageAddon.Fields>
          <PageAddon.Id id={id} />
          <PageAddon.Field label={t('common.game_type')}>
            {TRAINER_GAME_TYPE_LABELS[game_type]}
          </PageAddon.Field>
          <PageAddon.Field label={t('common.bot_settings')}>{getBotSetting()}</PageAddon.Field>
          <PageAddon.Field label={t('common.poker_room')}>{getPokerRoom(data)}</PageAddon.Field>
          <PageAddon.Field label={t('common.workspace')}>{getWorkspace(data)}</PageAddon.Field>
          <PageAddon.Text label={t('common.description')}>{description}</PageAddon.Text>
          <PageAddon.Field label={t('common.modified')}>
            {formatAbsoluteDate(modified_on)}
          </PageAddon.Field>
          <PageAddon.Field label={t('common.created')}>
            {formatAbsoluteDate(created_on)}
          </PageAddon.Field>
        </PageAddon.Fields>
        {!is_deleted && (
          <PageAddon.Controls>
            <PageAddon.Controls.Group>
              <Tooltip
                label={
                  can.botSetting.ruleCreateUpdate ? t('common.edit') : t('common.no_permission')
                }
                hideOnMobile={can.botSetting.ruleCreateUpdate}
              >
                <Button
                  variant="transparent"
                  icon={icon('edit', 16)}
                  onClick={onEdit}
                  data-testid="edit"
                  disabled={!can.botSetting.ruleCreateUpdate}
                />
              </Tooltip>
              <GlobalBotSettingRuleDetailDropdown data={data} />
            </PageAddon.Controls.Group>
          </PageAddon.Controls>
        )}
      </PageAddon>
    </PageAddon.Group>
  );
};

export default GlobalBotSettingRuleDetail;
