import { useTranslation } from 'react-i18next';
import { TRAINER_TECH } from '../../../api/schemas/trainerTechSchema';
import { WorkspaceSchema } from '../../../api/schemas/workspaceSchema';
import Filter from '../../../components/Filter';
import Textarea from '../../../components/Textarea';
import useFilter from '../../../hooks/useFilter';
import { useUserStore } from '../../../store/user';
import { convertCentsToDollars, convertDollarsToCents } from '../../../utils/data';
import {
  TRAINER_GAME_TYPE_LABELS,
  TRAINER_LIMITS_LABELS,
  TRAINER_POKER_ROOM_LABELS,
} from '../../../utils/trainer';
import { PILOT_STATUS_LABELS, PLAYER_TYPE_LABELS, TABLE_SIZE_LABELS } from '../../account/helpers';
import { useWorkspaceAccountsFilterValuesQuery } from '../queries';
import { useWorkspaceAccountsContext } from '../WorkspaceAccountsContext';
import styles from './styles.module.scss';

interface Fields {
  stage_name_in: string[];
  login_in: string;
  room_in: string[];
  club_in: string[];
  game_types_in: string[];
  comparative_limits_in: string[];
  table_sizes_in: string[];
  max_tables_ge: string;
  max_tables_le: string;
  pilot_status_in: string[];
  tag_in: string[];
  user_in: string[];
  bot_settings_in: string[];
  strategy_player_type_in: string[];
  hands_played_ge: string;
  hands_played_le: string;
  net_win_sum_ge: string;
  net_win_sum_le: string;
  win_rate_bb_ge: string;
  win_rate_bb_le: string;
  vpip_ge: string;
  vpip_le: string;
  has_geolocation: string;
  has_next_session: string;
  next_session_start_ge: string;
  next_session_start_le: string;
  last_online_ge: string;
  last_online_le: string;
  last_played_ge: string;
  last_played_le: string;
  updated_ge: string;
  updated_le: string;
  created_ge: string;
  created_le: string;
  on_verification: boolean;
  show_no_deals: boolean;
}

interface Props {
  workspaceId: WorkspaceSchema['id'];
  total?: number;
}

const WorkspaceAccountFilter = (props: Props) => {
  const { workspaceId, total } = props;

  const { t } = useTranslation();
  const { workspace } = useUserStore();
  const { displayAll } = useWorkspaceAccountsContext();

  const { data, isPending } = useWorkspaceAccountsFilterValuesQuery({ workspaceId });

  const filter = useFilter<Fields>({
    stage_name_in: 'multi',
    login_in: 'single',
    room_in: 'multi',
    club_in: 'multi',
    game_types_in: 'multi',
    comparative_limits_in: 'multi',
    table_sizes_in: 'multi',
    max_tables_ge: 'single',
    max_tables_le: 'single',
    pilot_status_in: 'multi',
    tag_in: 'multi',
    user_in: 'multi',
    bot_settings_in: 'multi',
    strategy_player_type_in: 'multi',
    hands_played_ge: 'single',
    hands_played_le: 'single',
    net_win_sum_ge: 'single',
    net_win_sum_le: 'single',
    win_rate_bb_ge: 'single',
    win_rate_bb_le: 'single',
    vpip_ge: 'single',
    vpip_le: 'single',
    has_geolocation: 'single',
    has_next_session: 'single',
    next_session_start_ge: 'single',
    next_session_start_le: 'single',
    last_online_ge: 'single',
    last_online_le: 'single',
    last_played_ge: 'single',
    last_played_le: 'single',
    updated_ge: 'single',
    updated_le: 'single',
    created_ge: 'single',
    created_le: 'single',
    on_verification: 'toggle',
    show_no_deals: 'toggle',
  });

  const { values, setValue, clearValue } = filter;

  const {
    stage_name_in,
    login_in,
    room_in,
    club_in,
    game_types_in,
    comparative_limits_in,
    table_sizes_in,
    max_tables_ge,
    max_tables_le,
    pilot_status_in,
    tag_in,
    user_in,
    bot_settings_in,
    strategy_player_type_in,
    hands_played_ge,
    hands_played_le,
    net_win_sum_ge,
    net_win_sum_le,
    win_rate_bb_ge,
    win_rate_bb_le,
    vpip_ge,
    vpip_le,
    has_geolocation,
    has_next_session,
    next_session_start_ge,
    next_session_start_le,
    last_online_ge,
    last_online_le,
    last_played_ge,
    last_played_le,
    updated_ge,
    updated_le,
    created_ge,
    created_le,
    on_verification,
    show_no_deals,
  } = values;

  const filterProps = { total, filter };

  if (isPending) {
    return (
      <Filter {...filterProps}>
        <Filter.Placeholder />
      </Filter>
    );
  }

  if (!data) return null;

  const {
    stage_name,
    room,
    clubs,
    game_types,
    comparative_limits,
    table_sizes,
    max_tables,
    pilot_status,
    tag,
    user,
    bot_settings,
    strategy_player_type,
    hands_played,
    net_win_sum,
    win_rate_bb,
    vpip,
  } = data;

  return (
    <Filter {...filterProps}>
      <div>
        {displayAll && stage_name.length !== 0 && (
          <Filter.Collapse
            label={t('common.stage')}
            count={stage_name_in.length}
            onClear={clearValue('stage_name_in')}
          >
            <Filter.MultiCheckbox
              config={stage_name.map((item) => ({
                value: item.id,
                label: item.name,
                onChange: setValue('stage_name_in'),
                checked: stage_name_in.includes(item.id),
              }))}
            />
          </Filter.Collapse>
        )}
        <Filter.Collapse
          label={t('common.login')}
          count={login_in?.length || 0}
          onClear={clearValue('login_in')}
        >
          <Textarea
            maxLength={0}
            value={login_in || ''}
            className={styles.textarea}
            onChange={(event) => setValue('login_in')(event.target.value)}
          />
        </Filter.Collapse>
        {room.length !== 0 && (
          <Filter.Collapse
            label={t('common.poker_room')}
            count={room_in.length}
            onClear={clearValue('room_in')}
          >
            <Filter.MultiCheckbox
              config={room.map((item) => ({
                value: String(item),
                label: TRAINER_POKER_ROOM_LABELS[item],
                onChange: setValue('room_in'),
                checked: room_in.includes(String(item)),
              }))}
            />
          </Filter.Collapse>
        )}
        {clubs.length !== 0 && (
          <Filter.Collapse
            label={t('common.club')}
            count={club_in.length}
            onClear={clearValue('club_in')}
          >
            <Filter.MultiCheckbox
              config={clubs.map((item) => ({
                value: item.id,
                label: item.name,
                onChange: setValue('club_in'),
                checked: club_in.includes(item.id),
                addon: item.code,
              }))}
              searchKeys={['addon']}
            />
          </Filter.Collapse>
        )}
        {game_types.length !== 0 && (
          <Filter.Collapse
            label={t('common.game_type')}
            count={game_types_in.length}
            onClear={clearValue('game_types_in')}
          >
            <Filter.MultiCheckbox
              config={game_types.map((item) => ({
                value: String(item),
                label: TRAINER_GAME_TYPE_LABELS[item],
                onChange: setValue('game_types_in'),
                checked: game_types_in.includes(String(item)),
              }))}
            />
          </Filter.Collapse>
        )}
        {comparative_limits.length !== 0 && (
          <Filter.Collapse
            label={t('common.limit')}
            count={comparative_limits_in.length}
            onClear={clearValue('comparative_limits_in')}
          >
            <Filter.MultiCheckbox
              config={comparative_limits.map((item) => ({
                value: String(item),
                label: TRAINER_LIMITS_LABELS[item],
                onChange: setValue('comparative_limits_in'),
                checked: comparative_limits_in.includes(String(item)),
              }))}
            />
          </Filter.Collapse>
        )}
        {table_sizes.length !== 0 && (
          <Filter.Collapse
            label={t('common.table_size')}
            count={table_sizes_in.length}
            onClear={clearValue('table_sizes_in')}
          >
            <Filter.MultiCheckbox
              config={table_sizes.map((item) => ({
                value: String(item),
                label: TABLE_SIZE_LABELS[item],
                onChange: setValue('table_sizes_in'),
                checked: table_sizes_in.includes(String(item)),
              }))}
            />
          </Filter.Collapse>
        )}
        <Filter.RangeInput
          label={t('common.max_tables')}
          step={1}
          min={max_tables.min}
          max={max_tables.max}
          valueMin={max_tables_ge}
          valueMax={max_tables_le}
          fieldMin="max_tables_ge"
          fieldMax="max_tables_le"
        />
        {pilot_status.length !== 0 && (
          <Filter.Collapse
            label={t('common.pilot')}
            count={pilot_status_in.length}
            onClear={clearValue('pilot_status_in')}
          >
            <Filter.MultiCheckbox
              config={pilot_status.map((item) => ({
                value: String(item),
                label: PILOT_STATUS_LABELS[item],
                onChange: setValue('pilot_status_in'),
                checked: pilot_status_in.includes(String(item)),
              }))}
            />
          </Filter.Collapse>
        )}
        {tag.length !== 0 && (
          <Filter.Collapse
            label={t('common.tag')}
            count={tag_in.length}
            onClear={clearValue('tag_in')}
          >
            <Filter.MultiCheckbox
              config={tag.map((item) => ({
                value: item.id,
                label: item.name,
                onChange: setValue('tag_in'),
                checked: tag_in.includes(item.id),
                extra: <div style={{ background: item.color }} className={styles.tag} />,
              }))}
            />
          </Filter.Collapse>
        )}
        {user.length !== 0 && (
          <Filter.Collapse
            label={t('common.assign_to')}
            count={user_in.length}
            onClear={clearValue('user_in')}
          >
            <Filter.MultiCheckbox
              config={user.map((item) => ({
                value: item.id,
                label: item.name,
                onChange: setValue('user_in'),
                checked: user_in.includes(item.id),
              }))}
            />
          </Filter.Collapse>
        )}
        {strategy_player_type.length !== 0 && (
          <Filter.Collapse
            label={t('common.player_type')}
            count={strategy_player_type_in.length}
            onClear={clearValue('strategy_player_type_in')}
          >
            <Filter.MultiCheckbox
              config={strategy_player_type.map((item) => ({
                value: String(item),
                label: PLAYER_TYPE_LABELS[item],
                onChange: setValue('strategy_player_type_in'),
                checked: strategy_player_type_in.includes(String(item)),
              }))}
            />
          </Filter.Collapse>
        )}
        {bot_settings.length !== 0 && (
          <Filter.Collapse
            label={t('common.bot_settings')}
            count={bot_settings_in.length}
            onClear={clearValue('bot_settings_in')}
          >
            <Filter.MultiCheckbox
              config={bot_settings.map((item) => ({
                value: item.id,
                label: item.name,
                onChange: setValue('bot_settings_in'),
                checked: bot_settings_in.includes(item.id),
              }))}
            />
          </Filter.Collapse>
        )}
        <Filter.RangeInput
          label={t('common.hands')}
          step={1}
          min={hands_played.min}
          max={hands_played.max}
          valueMin={hands_played_ge}
          valueMax={hands_played_le}
          fieldMin="hands_played_ge"
          fieldMax="hands_played_le"
        />
        <Filter.RangeInput
          label={t('common.net_win')}
          step={1}
          min={net_win_sum.min}
          max={net_win_sum.max}
          valueMin={net_win_sum_ge}
          valueMax={net_win_sum_le}
          fieldMin="net_win_sum_ge"
          fieldMax="net_win_sum_le"
          formatter={convertCentsToDollars}
          valueFormatter={convertDollarsToCents}
        />
        <Filter.RangeInput
          label={t('common.bb/100')}
          step={1}
          min={win_rate_bb.min}
          max={win_rate_bb.max}
          valueMin={win_rate_bb_ge}
          valueMax={win_rate_bb_le}
          fieldMin="win_rate_bb_ge"
          fieldMax="win_rate_bb_le"
        />
        <Filter.RangeInput
          label={t('common.vpip')}
          step={1}
          min={vpip.min}
          max={vpip.max}
          valueMin={vpip_ge}
          valueMax={vpip_le}
          fieldMin="vpip_ge"
          fieldMax="vpip_le"
        />
        <Filter.Collapse
          label={t('common.has_geolocation')}
          {...(has_geolocation && {
            onClear: clearValue('has_geolocation'),
          })}
        >
          <Filter.Radio value={has_geolocation} onChange={setValue('has_geolocation')} />
        </Filter.Collapse>
        <Filter.Collapse
          label={t('common.has_session')}
          {...(has_next_session && {
            onClear: clearValue('has_next_session'),
          })}
        >
          <Filter.Radio value={has_next_session} onChange={setValue('has_next_session')} />
        </Filter.Collapse>
        <Filter.Collapse
          label={t('common.next_session')}
          {...((next_session_start_ge || next_session_start_le) && {
            onClear: clearValue(['next_session_start_ge', 'next_session_start_le']),
          })}
        >
          <Filter.Date
            from={{ value: next_session_start_ge, onChange: setValue('next_session_start_ge') }}
            to={{ value: next_session_start_le, onChange: setValue('next_session_start_le') }}
          />
        </Filter.Collapse>
        <Filter.Collapse
          label={t('common.last_online')}
          {...((last_online_ge || last_online_le) && {
            onClear: clearValue(['last_online_ge', 'last_online_le']),
          })}
        >
          <Filter.Date
            from={{ value: last_online_ge, onChange: setValue('last_online_ge') }}
            to={{ value: last_online_le, onChange: setValue('last_online_le') }}
          />
        </Filter.Collapse>
        <Filter.Collapse
          label={t('common.last_played')}
          {...((last_played_ge || last_played_le) && {
            onClear: clearValue(['last_played_ge', 'last_played_le']),
          })}
        >
          <Filter.Date
            from={{ value: last_played_ge, onChange: setValue('last_played_ge') }}
            to={{ value: last_played_le, onChange: setValue('last_played_le') }}
          />
        </Filter.Collapse>
        <Filter.Collapse
          label={t('common.modified')}
          {...((updated_ge || updated_le) && {
            onClear: clearValue(['updated_ge', 'updated_le']),
          })}
        >
          <Filter.Date
            from={{ value: updated_ge, onChange: setValue('updated_ge') }}
            to={{ value: updated_le, onChange: setValue('updated_le') }}
          />
        </Filter.Collapse>
        <Filter.Collapse
          label={t('common.created')}
          {...((created_ge || created_le) && {
            onClear: clearValue(['created_ge', 'created_le']),
          })}
        >
          <Filter.Date
            from={{ value: created_ge, onChange: setValue('created_ge') }}
            to={{ value: created_le, onChange: setValue('created_le') }}
          />
        </Filter.Collapse>
      </div>
      {Boolean(workspace?.trainer_tech.includes(TRAINER_TECH.POKERRRR2_DROP_MANUAL)) && (
        <Filter.Switcher checked={on_verification} onChange={setValue('on_verification')}>
          {t('sentences.show_accounts_pending_verification')}
        </Filter.Switcher>
      )}
      <Filter.Switcher checked={show_no_deals} onChange={setValue('show_no_deals')}>
        {t('common.show_accounts_without_deals')}
      </Filter.Switcher>
    </Filter>
  );
};

export default WorkspaceAccountFilter;
