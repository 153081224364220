import clsx from 'clsx';
import { FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { pick } from 'remeda';
import { AccountRoomConfigSchema } from '../../../api/schemas/accountRoomConfigSchema';
import { TRAINER_POKER_ROOM } from '../../../api/schemas/trainerPokerRoomSchema';
import Alert from '../../../components/Alert';
import Checkbox from '../../../components/Checkbox';
import Input from '../../../components/Input';
import PageAddon from '../../../components/PageAddon';
import Select from '../../../components/Select';
import useQuickForm from '../../../hooks/useQuickForm';
import { required, max, handleError } from '../../../utils/form';
import { numbers } from '../../../utils/validations';
import {
  backgroundsOptions,
  cardsBackOptions,
  deckTypesOptions,
  feltColorsOptions,
  getDefaultValues,
  getPayload,
  labels,
  ritTypesOptions,
  roomsFields,
  tableShapesOptions,
} from '../roomConfigHelpers';
import { RoomConfigFields as Fields, CommonAccountSchema } from '../types';
import styles from './styles.module.scss';

interface Props {
  account: CommonAccountSchema;
  data: AccountRoomConfigSchema;
  onUpdate: (payload: ReturnType<typeof getPayload>) => void;
  disabled?: boolean;
}

const AccountDetailRoomConfig = (props: Props) => {
  const { account, data, disabled, onUpdate } = props;

  const { room } = account;

  const { t } = useTranslation();

  const form = useQuickForm<Fields>({ data, defaultValues: getDefaultValues(data) });

  const { control, handleSubmit } = form;

  const onSubmit = async (values: Fields) => {
    try {
      const payload = pick(getPayload(values), roomsFields[room]);

      await onUpdate(payload);
    } catch (error) {
      handleError({ error, form });
    }
  };

  const commonProps = { control, disabled, onSubmit: handleSubmit(onSubmit) };

  const balanceCheck = (
    <PageAddon.Field label={labels.balance_check}>
      <Checkbox.Quick name="balance_check" {...commonProps} />
    </PageAddon.Field>
  );

  const autoplay = (
    <PageAddon.Field label={labels.autoplay}>
      <Checkbox.Quick name="autoplay" {...commonProps} />
    </PageAddon.Field>
  );

  const showCards = (
    <PageAddon.Field label={labels.show_cards}>
      <Checkbox.Quick name="show_cards" {...commonProps} />
    </PageAddon.Field>
  );

  const rit_equity = (
    <Input.Quick
      name="rit_equity"
      allow={numbers}
      rules={{ validate: { required, max: max(t('common.rit_equity_%'), 100) } }}
      {...commonProps}
    />
  );

  if (data && !Object.keys(data).length) {
    return <Alert type="info" title={t('common.no_config')} closable={false} />;
  }

  return (
    <FormProvider {...form}>
      <div className={styles.container}>
        {room === TRAINER_POKER_ROOM.PARTYPOKER && (
          <>
            <PageAddon.Field label={labels.TableShape}>
              <Select.Quick name="TableShape" options={tableShapesOptions} {...commonProps} />
            </PageAddon.Field>
            <PageAddon.Field label={labels.FeltColor}>
              <Select.Quick name="FeltColor" options={feltColorsOptions} {...commonProps} />
            </PageAddon.Field>
            <PageAddon.Field label={labels.Background}>
              <Select.Quick name="Background" options={backgroundsOptions} {...commonProps} />
            </PageAddon.Field>
            <PageAddon.Field label={labels.DeckType}>
              <Select.Quick name="DeckType" options={deckTypesOptions} {...commonProps} />
            </PageAddon.Field>
            <PageAddon.Field label={labels.CardBack}>
              <Select.Quick name="CardBack" options={cardsBackOptions} {...commonProps} />
            </PageAddon.Field>
            <PageAddon.Field label={labels.IsDefaultAvatar}>
              <Checkbox.Quick name="IsDefaultAvatar" {...commonProps} />
            </PageAddon.Field>
            <div className={styles.checkboxes}>
              <PageAddon.Field label={labels.AutoBuyinCashGamesRebuy}>
                <Checkbox.Quick name="AutoBuyinCashGamesRebuy" {...commonProps} />
              </PageAddon.Field>
              <PageAddon.Field label={labels.MuckLosingUncalledHands}>
                <Checkbox.Quick name="MuckLosingUncalledHands" {...commonProps} />
              </PageAddon.Field>
            </div>
          </>
        )}
        {room === TRAINER_POKER_ROOM.PPPOKER && (
          <>
            <PageAddon.Field label={labels.ev_chop_percent}>
              <Input.Quick
                name="ev_chop_percent"
                allow={numbers}
                rules={{ validate: { required, max: max(t('common.ev_chop_%'), 100) } }}
                {...commonProps}
              />
            </PageAddon.Field>
            <PageAddon.Field label={labels.rim_percent}>
              <Input.Quick
                name="rim_percent"
                allow={numbers}
                rules={{ validate: { required, max: max(t('common.rim_%'), 100) } }}
                {...commonProps}
              />
            </PageAddon.Field>
            <PageAddon.Field label={labels.custom_timing_profile}>
              <Input.Quick name="custom_timing_profile" {...commonProps} />
            </PageAddon.Field>
            <div className={styles.checkboxes}>
              <PageAddon.Field label={labels.ppsr}>
                <Checkbox.Quick name="ppsr" {...commonProps} />
              </PageAddon.Field>
              {autoplay}
              {showCards}
              <PageAddon.Field label={labels.brain_connection}>
                <Checkbox.Quick name="brain_connection" {...commonProps} />
              </PageAddon.Field>
            </div>
          </>
        )}
        {room === TRAINER_POKER_ROOM.POKERRRR2 && (
          <>
            <div className={styles.checkboxes}>
              <PageAddon.Field label={labels.auto_muck}>
                <Checkbox.Quick name="auto_muck" {...commonProps} />
              </PageAddon.Field>
              <PageAddon.Field label={labels.auto_rebuy}>
                <Checkbox.Quick name="auto_rebuy" {...commonProps} />
              </PageAddon.Field>
              {balanceCheck}
              <PageAddon.Field label={labels.dead_blind}>
                <Checkbox.Quick name="dead_blind" {...commonProps} />
              </PageAddon.Field>
              <PageAddon.Field label={labels.is_standard_stack_seat}>
                <Checkbox.Quick name="is_standard_stack_seat" {...commonProps} />
              </PageAddon.Field>
              <PageAddon.Field label={labels.timebank}>
                <Checkbox.Quick name="timebank" {...commonProps} />
              </PageAddon.Field>
            </div>
            <PageAddon.Collapse label={t('common.MTT')} className={styles.collapse}>
              <PageAddon.Collapse.Field label={t('common.max_rebuy')}>
                <Input.Quick
                  name="max_tournament_rebuy"
                  allow={numbers}
                  rules={{ validate: { required, max: max(t('common.max_tournament_rebuy'), 5) } }}
                  {...commonProps}
                />
              </PageAddon.Collapse.Field>
              <PageAddon.Collapse.Field label={t('common.addon')}>
                <Checkbox.Quick name="tournament_addon" {...commonProps} />
              </PageAddon.Collapse.Field>
            </PageAddon.Collapse>
            <PageAddon.Collapse
              label={t('common.RIT')}
              className={clsx(styles.collapse, styles.sibling)}
            >
              <PageAddon.Collapse.Field label={t('common.type')}>
                <Select.Quick name="rit_type" options={ritTypesOptions} {...commonProps} />
              </PageAddon.Collapse.Field>
              <PageAddon.Collapse.Field label={t('common.percent')}>
                <Input.Quick
                  name="rit_percent"
                  allow={numbers}
                  rules={{ validate: { required, max: max(t('common.rit_%'), 100) } }}
                  {...commonProps}
                />
              </PageAddon.Collapse.Field>
              <PageAddon.Collapse.Field label={t('common.equity')}>
                {rit_equity}
              </PageAddon.Collapse.Field>
            </PageAddon.Collapse>
          </>
        )}
        {room === TRAINER_POKER_ROOM.RULER && (
          <>
            <PageAddon.Field label={labels.nickname}>
              <Input.Quick name="nickname" {...commonProps} />
            </PageAddon.Field>
            <div className={styles.checkboxes}>{balanceCheck}</div>
          </>
        )}
        {room === TRAINER_POKER_ROOM.CLUBGG && (
          <>
            <PageAddon.Field label={t('common.rit_equity_%')}>{rit_equity}</PageAddon.Field>
            <div className={styles.checkboxes}>
              {autoplay}
              {showCards}
            </div>
          </>
        )}
      </div>
    </FormProvider>
  );
};

export default AccountDetailRoomConfig;
