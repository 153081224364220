import { useTranslation } from 'react-i18next';
import { GlobalClubSchema } from '../../../api/schemas/globalClubSchema';
import { WorkspaceSchema } from '../../../api/schemas/workspaceSchema';
import Collapse from '../../../components/Collapse';
import Empty from '../../../components/Empty';
import { formatAbsoluteDate } from '../../../utils/date';
import { WORKSPACE_TYPES_LABELS } from '../../global-workspace/helpers';
import { can } from '../../permission/helpers';
import { useGlobalClubWorkspacesQuery } from '../queries';
import styles from './styles.module.scss';

interface Props {
  clubId: GlobalClubSchema['id'];
  getWorkspaceRoute?: (args: { workspaceId: WorkspaceSchema['id'] }) => string;
}

const GlobalClubDetailWorkspacesTab = (props: Props) => {
  const { clubId, getWorkspaceRoute } = props;

  const { t } = useTranslation();

  const { data } = useGlobalClubWorkspacesQuery({ clubId });

  const getContent = () => {
    if (!data.length) return <Empty />;

    return data.map((item) => (
      <Collapse
        key={item.id}
        label={item.name}
        caption={
          <Collapse.Caption>
            {item.maintenance_types.map((el) => WORKSPACE_TYPES_LABELS[el]).join(' • ')}
          </Collapse.Caption>
        }
        {...(can.workspace.read &&
          getWorkspaceRoute && { to: getWorkspaceRoute({ workspaceId: item.id }) })}
      >
        <Collapse.InfoField label={t('common.modified')}>
          {formatAbsoluteDate(item.modified_on)}
        </Collapse.InfoField>
        <Collapse.InfoField label={t('common.created')}>
          {formatAbsoluteDate(item.created_on)}
        </Collapse.InfoField>
      </Collapse>
    ));
  };

  return <div className={styles.container}>{getContent()}</div>;
};

export default GlobalClubDetailWorkspacesTab;
