import api from '../../services/api';
import emptyResponseSchema from '../schemas/emptyResponseSchema';
import { GlobalClubSchema } from '../schemas/globalClubSchema';

type Schema = GlobalClubSchema;

interface Payload {
  name?: Schema['name'];
  league_id?: Exclude<Schema['league'], null>['id'] | null;
  extra?: Schema['extra'];
  high_risk?: Schema['high_risk'];
}

interface Args {
  clubId: Schema['id'];
  payload: Payload;
}

const updateGlobalClub = async (args: Args) => {
  const { clubId, payload } = args;

  const response = await api.patch(`/api/reference/club/${clubId}`, payload);

  return emptyResponseSchema.parse(response.data);
};

export default updateGlobalClub;
