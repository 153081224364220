import { loadGlobalOrderTransactions } from '../../api';
import { OrderSchema } from '../../api/schemas/orderSchema';
import queryKeys from '../../config/queryKeys';
import usePaginatedData from '../../hooks/usePaginatedData';
import OrderTransactions from '../order/OrderTransactions';

interface Props {
  orderId: OrderSchema['id'];
}

const GlobalOrderTransactions = (props: Props) => {
  const { orderId } = props;

  const queryKey = [queryKeys.orderTransactions({ orderId })];

  const { data, loading, loadingMore, checkIsInView } = usePaginatedData({
    queryKey,
    queryFn: (params) => loadGlobalOrderTransactions({ orderId }, params),
  });

  return (
    <OrderTransactions
      data={data}
      loading={loading}
      loadingMore={loadingMore}
      checkIsInView={checkIsInView}
    />
  );
};

export default GlobalOrderTransactions;
