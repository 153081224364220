import { useTranslation } from 'react-i18next';
import { GlobalClubSchema } from '../../api/schemas/globalClubSchema';
import { GlobalLeagueSchema } from '../../api/schemas/globalLeagueSchema';
import FadingText from '../../components/FadingText';
import Link from '../../components/Link';
import PageAddon from '../../components/PageAddon';
import { formatAbsoluteDate } from '../../utils/date';
import { TRAINER_POKER_ROOM_LABELS } from '../../utils/trainer';

interface Props {
  data: GlobalClubSchema;
  getLeagueRoute?: (args: { leagueId: GlobalLeagueSchema['id'] }) => string;
}

const GlobalClubDetailDetailsTab = (props: Props) => {
  const { data, getLeagueRoute } = props;

  const { t } = useTranslation();

  const { id, name, poker_room, league, extra, high_risk, created_on, modified_on } = data;

  const getLeague = () => {
    if (!league) return '—';

    const content = <FadingText text={league.code} tooltip={false} />;

    if (getLeagueRoute) {
      return <Link to={getLeagueRoute({ leagueId: league.id })}>{content}</Link>;
    }

    return content;
  };

  return (
    <PageAddon.Fields>
      <PageAddon.Id id={id} />
      <PageAddon.Field label={t('common.name')}>
        <FadingText text={name} tooltip={false} />
      </PageAddon.Field>
      <PageAddon.Field label={t('common.poker_room')}>
        {TRAINER_POKER_ROOM_LABELS[poker_room]}
      </PageAddon.Field>
      <PageAddon.Field label={t('common.league')}>{getLeague()}</PageAddon.Field>
      <PageAddon.ExtraFields data={extra} />
      <PageAddon.Field label={t('common.high_risk')}>
        {high_risk ? t('common.true') : t('common.false')}
      </PageAddon.Field>
      <PageAddon.Field label={t('common.modified')}>
        {formatAbsoluteDate(modified_on)}
      </PageAddon.Field>
      <PageAddon.Field label={t('common.created')}>
        {formatAbsoluteDate(created_on)}
      </PageAddon.Field>
    </PageAddon.Fields>
  );
};

export default GlobalClubDetailDetailsTab;
