import { FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { loadGlobalBotSettings, loadGlobalClubs, updateGlobalAccount } from '../../api';
import { GlobalAccountSchema } from '../../api/schemas/account/globalAccountSchema';
import { GlobalUserSchema } from '../../api/schemas/globalUserSchema';
import FadingText from '../../components/FadingText';
import Input from '../../components/Input';
import Link from '../../components/Link';
import PageAddon from '../../components/PageAddon';
import Select from '../../components/Select';
import useQuickForm from '../../hooks/useQuickForm';
import { formatAbsoluteDate } from '../../utils/date';
import { handleError, max } from '../../utils/form';
import { TRAINER_POKER_ROOM_LABELS, trainerGameTypeOptions } from '../../utils/trainer';
import { numbers } from '../../utils/validations';
import AccountDetailBotSettings from '../account/AccountDetailBotSettings';
import AccountDetailChannels from '../account/AccountDetailChannels';
import AccountDetailClubs from '../account/AccountDetailClubs';
import AccountGeolocationDialog from '../account/AccountGeolocationDialog';
import { limitOptions, tableSizeOptions } from '../account/helpers';
import { GlobalAccountFields as Fields } from '../account/types';
import { can } from '../permission/helpers';
import { getTitle } from '../session/helpers';
import { getDefaultValues, getPayload } from './helpers';

interface Props {
  data: GlobalAccountSchema;
  getUserRoute?: (args: { userId: GlobalUserSchema['id'] }) => string;
}

const GlobalAccountDetailGeneralTab = (props: Props) => {
  const { data, getUserRoute } = props;

  const {
    id,
    maintenance,
    room,
    user,
    email,
    channels,
    affiliate_reference_number,
    next_session,
    last_online,
    last_played,
    created_on,
    modified_on,
  } = data;

  const { t } = useTranslation();

  const disabled = !can.account.createUpdate;

  const getUser = () => {
    if (!user) return '—';

    const content = user.username;

    if (can.user.read && getUserRoute) {
      return <Link to={getUserRoute({ userId: user.id })}>{content}</Link>;
    }

    return <PageAddon.Copy>{content}</PageAddon.Copy>;
  };

  const form = useQuickForm<Fields>({ data, defaultValues: getDefaultValues(data) });

  const { control, handleSubmit } = form;

  const onSubmit = async (values: Fields) => {
    try {
      await updateGlobalAccount({ accountId: data.id, payload: getPayload(values) });
    } catch (error) {
      handleError({ error, form });
    }
  };

  const commonProps = { control, disabled, onSubmit: handleSubmit(onSubmit) };

  return (
    <FormProvider {...form}>
      <PageAddon.Fields>
        <PageAddon.Id id={id} />
        <PageAddon.Field label={t('common.workspace')}>
          <FadingText text={maintenance.name}>
            <PageAddon.Copy>{maintenance.name}</PageAddon.Copy>
          </FadingText>
        </PageAddon.Field>
        <PageAddon.Field label={t('common.poker_room')}>
          <PageAddon.Copy>{TRAINER_POKER_ROOM_LABELS[room]}</PageAddon.Copy>
        </PageAddon.Field>
        <PageAddon.Field label={t('common.clubs')}>
          <AccountDetailClubs
            data={data}
            disabled={disabled}
            onSubmit={() => commonProps.onSubmit()}
            onLoad={async (args) => loadGlobalClubs({ ...args, poker_room_in: `${room}` })}
          />
        </PageAddon.Field>
        <PageAddon.Field label={t('common.game_types')}>
          <Select.Multi.Quick name="game_types" options={trainerGameTypeOptions} {...commonProps} />
        </PageAddon.Field>
        <PageAddon.Field label={t('common.limits')}>
          <Select.Multi.Quick
            selectAll
            name="comparative_limits"
            options={limitOptions}
            {...commonProps}
          />
        </PageAddon.Field>
        <PageAddon.Field label={t('common.table_size')}>
          <Select.Multi.Quick name="table_sizes" options={tableSizeOptions} {...commonProps} />
        </PageAddon.Field>
        <PageAddon.Field label={t('common.max_tables')}>
          <Input.Quick
            name="max_tables"
            allow={numbers}
            rules={{ validate: { max: max(t('common.max_tables'), 5) } }}
            {...commonProps}
          />
        </PageAddon.Field>
        <PageAddon.Field label={t('common.geolocation')}>
          <Input.Quick
            name="geo"
            {...commonProps}
            extra={<AccountGeolocationDialog quick onChange={() => commonProps.onSubmit()} />}
          />
        </PageAddon.Field>
        <PageAddon.Field label={t('common.channels')}>
          {channels.length ? <AccountDetailChannels data={data} /> : '—'}
        </PageAddon.Field>
        {can.account.emailRead && (
          <PageAddon.Field label={t('common.email')}>
            {email ? (
              <FadingText text={email}>
                <PageAddon.Copy>{email}</PageAddon.Copy>
              </FadingText>
            ) : (
              '—'
            )}
          </PageAddon.Field>
        )}
        <PageAddon.Field label={t('common.bot_settings')}>
          <AccountDetailBotSettings
            data={data}
            disabled={disabled}
            onSubmit={() => commonProps.onSubmit()}
            onLoad={(args) =>
              loadGlobalBotSettings({ ...args, maintenance_in: maintenance.id || '' })
            }
          />
        </PageAddon.Field>
        <PageAddon.Field label={t('common.assign_to')}>{getUser()}</PageAddon.Field>
        <PageAddon.Field label={t('common.affiliate')}>
          {affiliate_reference_number ? (
            <FadingText text={affiliate_reference_number}>
              <PageAddon.Copy>{affiliate_reference_number}</PageAddon.Copy>
            </FadingText>
          ) : (
            '—'
          )}
        </PageAddon.Field>
        <PageAddon.Field label={t('common.next_session')}>
          {next_session ? (
            <PageAddon.Copy>
              {getTitle({ start: next_session.start, end: next_session.end })}
            </PageAddon.Copy>
          ) : (
            '—'
          )}
        </PageAddon.Field>
        <PageAddon.Field label={t('common.last_online')}>
          {last_online ? <PageAddon.Copy>{formatAbsoluteDate(last_online)}</PageAddon.Copy> : '—'}
        </PageAddon.Field>
        <PageAddon.Field label={t('common.last_played')}>
          {last_online ? <PageAddon.Copy>{formatAbsoluteDate(last_played)}</PageAddon.Copy> : '—'}
        </PageAddon.Field>
        <PageAddon.Field label={t('common.modified')}>
          <PageAddon.Copy>{formatAbsoluteDate(modified_on)}</PageAddon.Copy>
        </PageAddon.Field>
        <PageAddon.Field label={t('common.created')}>
          <PageAddon.Copy>{formatAbsoluteDate(created_on)}</PageAddon.Copy>
        </PageAddon.Field>
      </PageAddon.Fields>
    </FormProvider>
  );
};

export default GlobalAccountDetailGeneralTab;
