import { useQuery } from '@tanstack/react-query';
import { useState } from 'react';
import { createPortal } from 'react-dom';
import { loadNotificationsCount } from '../../../api';
import { ACTION_TYPE, NotificationSchema } from '../../../api/schemas/notificationSchema';
import { NotificationsCountSchema } from '../../../api/schemas/notificationsCountSchema';
import queryKeys from '../../../config/queryKeys';
import useWebSocketMessage from '../../../hooks/useWebSocketMessage';
import icon from '../../../static/icons';
import notify from '../../../utils/notify';
import { mutateDetailQueryData } from '../../../utils/queryClient';
import Button from '../../Button';
import Indicator from '../../Indicator';
import PageNotifications from '../PageNotifications';
import { getActionTypeContent } from '../PageNotifications/helpers';

const PageHeaderNotifications = () => {
  const queryKey = [queryKeys.notificationsCount];

  const { data } = useQuery({
    queryKey,
    queryFn: () => loadNotificationsCount(),
    meta: { ignoredStatuses: [403] },
    staleTime: Infinity,
  });

  useWebSocketMessage<NotificationSchema>({
    domain: 'Notification',
    onMessage: (message) => {
      const { payload } = message;
      const { action_type } = payload;

      if (message.action === 'Created') {
        mutateDetailQueryData<NotificationsCountSchema>(queryKey, (prevData) => ({
          count: prevData.count + 1,
        }));

        const getType = () => {
          if (
            action_type === ACTION_TYPE.CM_MANAGER_STOPPED ||
            action_type === ACTION_TYPE.CHANNEL_DEACTIVATED
          ) {
            return 'warning';
          }

          if (
            action_type === ACTION_TYPE.CM_TRANSACTION_FAILED ||
            action_type === ACTION_TYPE.ACCOUNT_START_FAILED ||
            action_type === ACTION_TYPE.ACCOUNT_CRITICAL_ERROR
          ) {
            return 'error';
          }

          return 'info';
        };

        notify(getType(), {
          large: true,
          subTitle: (
            <>
              {payload.workspace.name}
              {payload.author && (
                <>
                  {' '}
                  <span>•</span> {payload.author.username}
                </>
              )}
            </>
          ),
          title: getActionTypeContent(payload),
          ...(payload.message && { description: payload.message }),
        });
      }

      if (message.action === 'MarkedRead') {
        mutateDetailQueryData<NotificationsCountSchema>(queryKey, (prevData) => ({
          count: prevData.count - 1,
        }));
      }
    },
  });

  const [open, setOpen] = useState(false);

  const toggle = () => setOpen((prev) => !prev);

  return (
    <>
      {open &&
        createPortal(
          <PageNotifications count={data?.count || 0} onClose={() => setOpen(false)} />,
          document.body
        )}
      <Indicator variant="dot" count={data?.count || 0}>
        <Button
          size="small"
          variant="transparent"
          icon={icon('notification', 16)}
          onClick={toggle}
        />
      </Indicator>
    </>
  );
};

export default PageHeaderNotifications;
