import clsx from 'clsx';
import { useState, forwardRef, ChangeEventHandler, ReactNode } from 'react';
import Error from '../Error';
import Label, { getLabelProps } from '../Label';
import styles from './styles.module.scss';

interface Props extends React.ComponentPropsWithRef<'textarea'> {
  label?: Parameters<typeof getLabelProps>[0];
  error?: string;
  addon?: ReactNode;
  maxLength?: number;
  onChange?: ChangeEventHandler<HTMLTextAreaElement>;
}

const Textarea = forwardRef<HTMLTextAreaElement, Props>((props, ref) => {
  const {
    label,
    error,
    addon,
    maxLength = 75,
    onChange,
    className: customClassName,
    ...rest
  } = props;

  const [value, setValue] = useState(0);

  const className = clsx(styles.textarea, {
    [styles.error]: error,
    ...(customClassName && { [customClassName]: true }),
  });

  return (
    <div className={styles.container}>
      {(label || addon) && <Label addon={addon} {...getLabelProps(label)} />}
      <div className={className}>
        <textarea
          ref={ref}
          onChange={(event) => {
            if (onChange) {
              onChange(event);
            }

            setValue(event.target.value.length);
          }}
          {...(maxLength !== 0 && { maxLength })}
          {...rest}
        />
      </div>
      <div className={styles.bottom}>
        <Error error={error} />
        {maxLength ? <div className={styles.maxLength}>{maxLength - value}</div> : null}
      </div>
    </div>
  );
});

export default Textarea;
