import { differenceInYears, isMatch } from 'date-fns';
import { FieldValues, Path, UseFormReturn } from 'react-hook-form';
import { t } from '../i18n';
import { isApiError, isApiError400 } from '../services/api';
import notify from './notify';
import { email as emailRegExp, uuid as uuidRegExp } from './regExp';

interface ErrorDetail<T> {
  loc: [string, Path<T>];
  msg: string;
}

export const handleError = <T extends FieldValues>(args: {
  error: unknown;
  ignoredStatuses?: number[];
  form?: UseFormReturn<T>;
}) => {
  const { error, ignoredStatuses = [], form } = args;

  if (isApiError(error) && error.response) {
    const { status } = error.response;

    if (form) form.reset();

    if (!ignoredStatuses.includes(status)) {
      if (status === 400 && isApiError400(error)) {
        const { data } = error.response;

        notify('error', { title: data.error[0].title });
      } else if (status === 422 && form) {
        const errors = error.response.data.detail;

        errors.forEach((item: ErrorDetail<T>) => {
          form.setError(item.loc[1], { message: item.msg, type: 'validate' });
        });

        notify('error', { title: String(t('sentences.fields_are_filled_incorrectly')) });
      } else {
        const { data } = error.response;

        if (data && data.error && Array.isArray(data.error)) {
          notify('error', { title: data.error[0] });
        } else {
          notify('error', { title: String(t('common.something_went_wrong')) });
        }
      }
    }
  } else if (error instanceof SyntaxError) {
    notify('error', { title: String(error).replace('SyntaxError: ', '') });
  } else {
    notify('error', { title: String(t('common.something_went_wrong')) });
  }
};

export const required = <T>(value?: T) => {
  const errorText = t('validations.required');

  if (!value) return errorText;

  if (Array.isArray(value) && !value.length) return errorText;

  if (typeof value === 'string' && !value.trim()) return errorText;

  return true;
};

export const email = (value?: string) => {
  value = value?.trim();

  const errorText = t('validations.email');
  const maxLengthErrorText = t('validations.email_max_length');

  if (!value) return true;

  if (!emailRegExp.test(value)) return errorText;

  const beforeAt = value.split('@')[0];

  if (beforeAt.length > 64) return maxLengthErrorText;

  return true;
};

export const password = (value?: string) => {
  const errorText = t('validations.password');

  if (!value) return errorText;

  if (value.length < 6) return errorText;

  return true;
};

export const min = (minValue: number) => (value?: string) => {
  const errorText = t('validations.min');

  if (Number(value) < minValue) return errorText;

  return true;
};

export const max = (field: string, maxValue: number, currency?: string) => (value?: string) => {
  const errorText = t('validations.max', { field, maxValue, currency });

  if (Number(value) > maxValue) return errorText;

  return true;
};

export const minLength =
  (length = 3) =>
  (value?: string) => {
    const errorText = t('validations.min_length', { length });

    if (!value) return true;

    if (value.length < length) return errorText;

    return true;
  };

export const maxLength =
  (length = 70) =>
  (value?: string) => {
    const errorText = t('validations.max_length', { length });

    if (!value) return true;

    if (value.length > length) return errorText;

    return true;
  };

export const bigNumber = (value?: string) => {
  const errorText = t('validations.big_number');

  if (!value) return true;

  if (Number(value) > Number.MAX_SAFE_INTEGER) return errorText;

  return true;
};

export const age21 = (value: string) => {
  const errorText = t('validations.age_21');

  const difference = differenceInYears(new Date(), new Date(value));

  if (difference < 21) return errorText;

  return true;
};

export const validDateFormat = (format: string) => (value: string) => {
  const errorText = t('validations.invalid_date_format');

  if (!value) return true;

  if (!isMatch(value, format)) return errorText;

  return true;
};

export const uuid = (value?: string) => {
  const errorText = t('validations.uuid');

  if (!value) return true;

  if (!uuidRegExp.test(value)) return errorText;

  return true;
};
