import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { deleteGlobalAccountSession } from '../../api';
import { STATUS } from '../../api/schemas/session';
import { GlobalSessionSchema } from '../../api/schemas/session/globalSessionSchema';
import { presets } from '../../components/Dropdown';
import routes from '../../config/routes';
import useURL from '../../hooks/useURL';
import { RouteParams } from '../../utils/generics';
import notify from '../../utils/notify';
import { can } from '../permission/helpers';
import { getTitle } from '../session/helpers';

type Params = RouteParams<typeof routes.session>;

interface Args {
  data: GlobalSessionSchema;
}

const useDropdown = (args: Args) => {
  const { data } = args;

  const { id, start, end, status, account, is_deleted } = data;

  const { id: accountId } = account;

  const { t } = useTranslation();
  const { navigate } = useURL();
  const { sessionId } = useParams<Params>();

  const title = getTitle({ start, end });

  const started = status === STATUS.SENT_START || status === STATUS.RUNNING;
  const finished = status === STATUS.SENT_FINISH || status === STATUS.FINISHED;

  const getTooltip = () => {
    if (!can.session.delete) return t('common.no_permission');

    if (started) {
      return t('sentences.you_cant_delete_an_active_session');
    }

    if (finished) return t('sentences.you_cant_delete_a_past_session');

    return null;
  };

  return {
    title,
    disabled: is_deleted,
    options: {
      edit: {
        ...presets.edit,
        onClick: () => navigate(routes.sessionEdit({ accountId, sessionId: id })),
        disabled: !can.session.createUpdate,
        ...(!can.session.createUpdate && { tooltip: t('common.no_permission') }),
      },
      copy: presets.copyId(id),
      divider: { divider: true },
      delete: {
        ...presets.delete,
        onClick: async () => {
          await deleteGlobalAccountSession({ accountId, sessionId: id });

          if (id === sessionId) {
            navigate(routes.sessions);
          }

          notify('success', { title: t('sentences.record_has_been_deleted') });
        },
        disabled: started || finished || !can.session.delete,
        tooltip: getTooltip(),
      },
    },
  };
};

export default useDropdown;
