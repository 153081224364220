import { RouteObject } from 'react-router-dom';
import routes from '../../config/routes';
import GlobalClubDetail from '../../features/global-club/GlobalClubDetail';
import GlobalClubForm from '../../features/global-club/GlobalClubForm';
import GlobalClubLoader from '../../features/global-club/GlobalClubLoader';
import GlobalUserDetail from '../../features/global-user/GlobalUserDetail';
import GlobalUserForm from '../../features/global-user/GlobalUserForm';
import GlobalUserLoader from '../../features/global-user/GlobalUserLoader';
import GlobalWorkspaceDetail from '../../features/global-workspace/GlobalWorkspaceDetail';
import GlobalWorkspaceForm from '../../features/global-workspace/GlobalWorkspaceForm';
import GlobalWorkspaceLoader from '../../features/global-workspace/GlobalWorkspaceLoader';
import GlobalWorkspacesPage from '../../pages/GlobalWorkspacesPage';
import Route from './Route';

const page = <GlobalWorkspacesPage />;

const createForm = (
  <Route>
    {({ navigate }) => (
      <GlobalWorkspaceForm
        onClose={() => navigate(routes.globalWorkspaces)}
        onCreate={({ workspaceId }) => navigate(routes.globalWorkspace({ workspaceId }))}
      />
    )}
  </Route>
);

const detail = (
  <Route params={['workspaceId']}>
    {({ workspaceId, navigate }) => (
      <GlobalWorkspaceLoader workspaceId={workspaceId}>
        {({ data }) => (
          <GlobalWorkspaceDetail
            data={data}
            onClose={() => navigate(routes.globalWorkspaces)}
            onEdit={() => navigate(routes.globalWorkspaceEdit({ workspaceId }))}
            getUserRoute={({ userId }) => routes.globalWorkspaceUser({ workspaceId, userId })}
            getClubRoute={({ clubId }) => routes.globalWorkspaceClub({ workspaceId, clubId })}
          />
        )}
      </GlobalWorkspaceLoader>
    )}
  </Route>
);

const detailUser = (
  <Route params={['workspaceId', 'userId']}>
    {({ workspaceId, userId, navigate }) => (
      <GlobalUserLoader userId={userId}>
        {({ data }) => (
          <GlobalUserDetail
            data={data}
            onClose={() => navigate(routes.globalWorkspace({ workspaceId }))}
            onEdit={() => navigate(routes.globalWorkspaceUserEdit({ workspaceId, userId }))}
          />
        )}
      </GlobalUserLoader>
    )}
  </Route>
);

const detailUserEdit = (
  <Route params={['workspaceId', 'userId']}>
    {({ workspaceId, userId, navigate }) => (
      <GlobalUserLoader userId={userId}>
        {({ data }) => (
          <GlobalUserForm
            data={data}
            onClose={() => navigate(routes.globalWorkspaceUser({ workspaceId, userId }))}
          />
        )}
      </GlobalUserLoader>
    )}
  </Route>
);

const detailClub = (
  <Route params={['workspaceId', 'clubId']}>
    {({ workspaceId, clubId, navigate }) => (
      <GlobalClubLoader clubId={clubId}>
        {({ data }) => (
          <GlobalClubDetail
            data={data}
            onClose={() => navigate(routes.globalWorkspace({ workspaceId }))}
            onEdit={() => navigate(routes.globalWorkspaceClubEdit({ workspaceId, clubId }))}
          />
        )}
      </GlobalClubLoader>
    )}
  </Route>
);

const detailClubEdit = (
  <Route params={['workspaceId', 'clubId']}>
    {({ workspaceId, clubId, navigate }) => (
      <GlobalClubLoader clubId={clubId}>
        {({ data, workspacesData }) => (
          <GlobalClubForm
            data={data}
            workspacesData={workspacesData}
            onClose={() => navigate(routes.globalWorkspaceClub({ workspaceId, clubId }))}
            onEdit={() => navigate(routes.globalWorkspaceClub({ workspaceId, clubId }))}
          />
        )}
      </GlobalClubLoader>
    )}
  </Route>
);

const editForm = (
  <Route params={['workspaceId']}>
    {({ workspaceId, navigate }) => (
      <GlobalWorkspaceLoader workspaceId={workspaceId}>
        {({ data, clubsData }) => (
          <GlobalWorkspaceForm
            data={data}
            clubsData={clubsData}
            onClose={() => navigate(routes.globalWorkspace({ workspaceId }))}
            onEdit={() => navigate(routes.globalWorkspace({ workspaceId }))}
          />
        )}
      </GlobalWorkspaceLoader>
    )}
  </Route>
);

const globalWorkspacesPage: RouteObject = {
  path: routes.globalWorkspaces,
  element: page,
  children: [
    {
      path: routes.globalWorkspacesCreate,
      element: createForm,
    },
    {
      path: routes.globalWorkspace({ workspaceId: ':workspaceId' }),
      element: detail,
      children: [
        {
          path: routes.globalWorkspaceUser({ workspaceId: ':workspaceId', userId: ':userId' }),
          element: detailUser,
        },
        {
          path: routes.globalWorkspaceUserEdit({ workspaceId: ':workspaceId', userId: ':userId' }),
          element: detailUserEdit,
        },
        {
          path: routes.globalWorkspaceClub({ workspaceId: ':workspaceId', clubId: ':clubId' }),
          element: detailClub,
        },
        {
          path: routes.globalWorkspaceClubEdit({ workspaceId: ':workspaceId', clubId: ':clubId' }),
          element: detailClubEdit,
        },
      ],
    },
    {
      path: routes.globalWorkspaceEdit({ workspaceId: ':workspaceId' }),
      element: editForm,
    },
  ],
};

export default globalWorkspacesPage;
