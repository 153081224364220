import { useTranslation } from 'react-i18next';
import { WorkspaceSchema } from '../../api/schemas/workspaceSchema';
import { WorkspaceUserSchema } from '../../api/schemas/workspaceUserSchema';
import Collapse from '../../components/Collapse';
import Tooltip from '../../components/Tooltip';
import queryKeys from '../../config/queryKeys';
import useWebSocketMessage from '../../hooks/useWebSocketMessage';
import icon from '../../static/icons';
import { formatAbsoluteDate } from '../../utils/date';
import {
  createInfinitePaginatedQueryData,
  deleteInfinitePaginatedQueryData,
} from '../../utils/queryClient';
import { can } from '../permission/helpers';
import { useGlobalWorkspaceUsersQuery } from './queries';

interface Props {
  workspaceId: WorkspaceSchema['id'];
  getUserRoute?: (args: { userId: WorkspaceUserSchema['id'] }) => string;
}

const GlobalWorkspaceDetailUsersTab = (props: Props) => {
  const { workspaceId, getUserRoute } = props;

  const { t } = useTranslation();

  const queryKey = [queryKeys.globalWorkspaceUsers({ workspaceId })];

  const { data, checkIsInView } = useGlobalWorkspaceUsersQuery({ workspaceId });

  useWebSocketMessage<{
    id: WorkspaceSchema['id'];
    participant: WorkspaceUserSchema;
    modified_on: WorkspaceSchema['modified_on'];
  }>({
    domain: 'GlobalMaintenance',
    onMessage: (message) => {
      const payload = message.payload.participant;

      if (message.action === 'ParticipantAdded') {
        createInfinitePaginatedQueryData(queryKey, payload);
      }

      if (message.action === 'ParticipantRemoved') {
        deleteInfinitePaginatedQueryData(queryKey, (item) => item.id === payload.id);
      }
    },
    enabled: (message) => message.payload.id === workspaceId,
  });

  if (!data) return null;

  return (
    <Collapse.Group>
      {data.map((item, index) => (
        <Collapse
          key={item.id}
          label={item.username}
          labelAfter={
            item.is_owner && (
              <Collapse.SecondaryLabel>
                <Tooltip label={t('common.owner')}>{icon('crown', 12)}</Tooltip>
              </Collapse.SecondaryLabel>
            )
          }
          caption={<Collapse.Id id={item.id} />}
          {...(can.user.read && getUserRoute && { to: getUserRoute({ userId: item.id }) })}
          {...checkIsInView(index)}
        >
          <Collapse.InfoField label={t('common.invited')}>
            {formatAbsoluteDate(item.invited_on)}
          </Collapse.InfoField>
        </Collapse>
      ))}
    </Collapse.Group>
  );
};

export default GlobalWorkspaceDetailUsersTab;
