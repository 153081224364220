import { useQuery } from '@tanstack/react-query';
import { Dispatch, SetStateAction, useEffect, useId } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { loadNotificationsSetting, updateNotificationsSetting } from '../../../../api';
import { NotificationsSettingSchema } from '../../../../api/schemas/notificationsSettingSchema';
import notify from '../../../../utils/notify';
import Button from '../../../Button';
import Chip from '../../../Chip';
import Dialog from '../../../Dialog';
import Form from '../../../Form';
import { Option, Options } from '../../../Select';
import Spinner from '../../../Spinner';
import Stub from '../../../Stub';
import SubmitButton from '../../../SubmitButton';
import { ACTION_TYPE_SETTINGS_LABELS, getActionTypesGroups } from '../helpers';
import styles from './styles.module.scss';
import { Dirty, Workspace } from './types';

interface Fields {
  workspace: Option;
  action_types: Options<NotificationsSettingSchema['action_types'][number]>;
}

interface Props {
  data: NotificationsSettingSchema;
  workspace: Workspace;
  setDirty: Dispatch<SetStateAction<Dirty>>;
}

const Component = (props: Props) => {
  const { data, workspace, setDirty } = props;

  const { t } = useTranslation();

  const form = useForm<Fields>({
    defaultValues: {
      action_types: data.action_types.map((item) => ({
        value: item,
        label: ACTION_TYPE_SETTINGS_LABELS[item],
      })),
    },
  });

  const {
    control,
    watch,
    reset,
    formState: { isDirty },
  } = form;

  const { action_types } = watch();

  const actionTypesValues = action_types.map((item) => item.value);

  useEffect(() => {
    setDirty(isDirty);
  }, [isDirty, setDirty]);

  const onSubmit = async (values: Fields) => {
    const payload = {
      action_types: values.action_types.map((item) => item.value),
    };

    await updateNotificationsSetting({ workspaceId: workspace.value, payload });

    reset({ action_types: values.action_types, workspace: values.workspace });

    notify('success', { title: t('sentences.changes_have_been_saved') });
  };

  const groups = getActionTypesGroups(workspace.value);

  return (
    <Form form={form} onSubmit={onSubmit} className={styles.form}>
      <Controller
        name="action_types"
        control={control}
        render={({ field }) => (
          <div className={styles.groups}>
            {groups.length ? (
              groups.map((item) => (
                <div key={item.label} className={styles.group}>
                  <span>{item.label}</span>
                  <div className={styles.chips}>
                    {item.action_types.map((type) => {
                      const handleClick = () => {
                        if (actionTypesValues.includes(type)) {
                          field.onChange(action_types.filter((el) => el.value !== type));
                        } else {
                          field.onChange([
                            ...action_types,
                            { value: type, label: ACTION_TYPE_SETTINGS_LABELS[type] },
                          ]);
                        }
                      };

                      return (
                        <Chip
                          key={type}
                          active={actionTypesValues.includes(type)}
                          onClick={handleClick}
                        >
                          {ACTION_TYPE_SETTINGS_LABELS[type]}
                        </Chip>
                      );
                    })}
                  </div>
                </div>
              ))
            ) : (
              <Stub title={t('sentences.no_settings_access_due_to_permissions')} />
            )}
          </div>
        )}
      />
      <Dialog.Footer>
        <SubmitButton disabled={!isDirty}>{t('common.save')}</SubmitButton>
      </Dialog.Footer>
    </Form>
  );
};

const PageNotificationsSettingsDialogFormActionTypes = (props: Omit<Props, 'data'>) => {
  const { workspace } = props;

  const id = useId();

  const { data, isPending } = useQuery({
    queryKey: [id, workspace.value],
    queryFn: () => loadNotificationsSetting({ workspaceId: workspace.value }),
  });

  const { t } = useTranslation();

  return (
    <>
      {isPending && (
        <div className={styles.spinner}>
          <Spinner />
        </div>
      )}
      {data && <Component data={data} {...props} />}
      {isPending && (
        <Dialog.Footer>
          <Button disabled>{t('common.save')}</Button>
        </Dialog.Footer>
      )}
    </>
  );
};

export default PageNotificationsSettingsDialogFormActionTypes;
