import { useTranslation } from 'react-i18next';
import { GlobalUserSchema } from '../../api/schemas/globalUserSchema';
import { presets } from '../../components/Dropdown';
import routes from '../../config/routes';
import useURL from '../../hooks/useURL';
import icon from '../../static/icons';
import { can } from '../permission/helpers';
import { inviteUser, resetPassword } from './helpers';

interface Args {
  data: GlobalUserSchema;
}

const useDropdown = (args: Args) => {
  const { data } = args;

  const { id, username, verified, is_deleted } = data;

  const { t } = useTranslation();
  const { navigate } = useURL();

  return {
    title: username,
    disabled: is_deleted,
    options: {
      edit: {
        ...presets.edit,
        onClick: () => navigate(routes.userEdit({ userId: id })),
        disabled: !can.user.createUpdate || !can.role.read,
        ...((!can.user.createUpdate || !can.role.read) && { tooltip: t('common.no_permission') }),
      },
      resetPassword: {
        icon: icon('unlock', 20),
        label: t('common.reset_password'),
        ...(!can.user.createUpdate && { tooltip: t('common.no_permission') }),
        disabled: !can.user.createUpdate,
        confirm: true,
        confirmText: t('common.reset'),
        confirmDanger: false,
        onClick: () => resetPassword({ id, username }),
      },
      ...(!verified && {
        invite: {
          icon: icon('mail', 20),
          label: t('common.send_invite_link'),
          ...(!can.user.createUpdate && { tooltip: t('common.no_permission') }),
          confirm: true,
          confirmText: t('common.send'),
          confirmDanger: false,
          disabled: !can.user.createUpdate,
          onClick: () => inviteUser({ email: username }),
        },
      }),
      copy: presets.copyId(id),
    },
  };
};

export default useDropdown;
