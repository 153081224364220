import { useTranslation } from 'react-i18next';
import PageCreateButton from '../../components/PageCreateButton';
import routes from '../../config/routes';
import useURL from '../../hooks/useURL';
import { can } from '../permission/helpers';

const GlobalAgentCreateButton = () => {
  const { t } = useTranslation();
  const { navigate } = useURL();

  const handleClick = () => navigate(routes.agentsCreate);

  if (!can.agent.createUpdate) return null;

  return <PageCreateButton onClick={handleClick}>{t('common.agent')}</PageCreateButton>;
};

export default GlobalAgentCreateButton;
