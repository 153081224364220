import { RouteObject } from 'react-router-dom';
import routes from '../../config/routes';
import GlobalBotSettingDetail from '../../features/global-bot-setting/GlobalBotSettingDetail';
import GlobalBotSettingForm from '../../features/global-bot-setting/GlobalBotSettingForm';
import GlobalBotSettingLoader from '../../features/global-bot-setting/GlobalBotSettingLoader';
import GlobalBotSettingRuleDetail from '../../features/global-bot-setting-rule/GlobalBotSettingRuleDetail';
import GlobalBotSettingRuleForm from '../../features/global-bot-setting-rule/GlobalBotSettingRuleForm';
import GlobalBotSettingRuleLoader from '../../features/global-bot-setting-rule/GlobalBotSettingRuleLoader';
import GlobalBotSettingsRulesPage from '../../pages/GlobalBotSettingsRulesPage';
import Route from './Route';

const page = <GlobalBotSettingsRulesPage />;

const createForm = (
  <Route>
    {({ navigate }) => (
      <GlobalBotSettingRuleForm
        onClose={() => navigate(routes.botSettingsRules)}
        onCreate={({ botSettingRuleId }) => navigate(routes.botSettingRule({ botSettingRuleId }))}
      />
    )}
  </Route>
);

const detail = (
  <Route params={['botSettingRuleId']}>
    {({ botSettingRuleId, navigate }) => (
      <GlobalBotSettingRuleLoader botSettingRuleId={botSettingRuleId}>
        {({ data }) => (
          <GlobalBotSettingRuleDetail
            data={data}
            onClose={() => navigate(routes.botSettingsRules)}
            onEdit={() => navigate(routes.botSettingRuleEdit({ botSettingRuleId }))}
            getBotSettingRoute={({ botSettingId }) =>
              routes.botSettingRuleBotSetting({ botSettingRuleId, botSettingId })
            }
          />
        )}
      </GlobalBotSettingRuleLoader>
    )}
  </Route>
);

const detailBotSetting = (
  <Route params={['botSettingId', 'botSettingRuleId']}>
    {({ botSettingId, botSettingRuleId, navigate }) => (
      <GlobalBotSettingLoader botSettingId={botSettingId}>
        {({ data }) => (
          <GlobalBotSettingDetail
            deletable={false}
            data={data}
            onClose={() => navigate(routes.botSettingRule({ botSettingRuleId }))}
            onEdit={() =>
              navigate(routes.botSettingRuleBotSettingEdit({ botSettingRuleId, botSettingId }))
            }
          />
        )}
      </GlobalBotSettingLoader>
    )}
  </Route>
);

const detailBotSettingEditForm = (
  <Route params={['botSettingId', 'botSettingRuleId']}>
    {({ botSettingId, botSettingRuleId, navigate }) => (
      <GlobalBotSettingLoader botSettingId={botSettingId}>
        {({ data }) => (
          <GlobalBotSettingForm
            data={data}
            onClose={() =>
              navigate(routes.botSettingRuleBotSetting({ botSettingRuleId, botSettingId }))
            }
            onEdit={() =>
              navigate(routes.botSettingRuleBotSetting({ botSettingRuleId, botSettingId }))
            }
          />
        )}
      </GlobalBotSettingLoader>
    )}
  </Route>
);

const editForm = (
  <Route params={['botSettingRuleId']}>
    {({ botSettingRuleId, navigate }) => (
      <GlobalBotSettingRuleLoader botSettingRuleId={botSettingRuleId}>
        {({ data }) => (
          <GlobalBotSettingRuleForm
            data={data}
            onClose={() => navigate(routes.botSettingRule({ botSettingRuleId }))}
            onEdit={() => navigate(routes.botSettingRule({ botSettingRuleId }))}
          />
        )}
      </GlobalBotSettingRuleLoader>
    )}
  </Route>
);

const globalBotSettingsRulesPage: RouteObject = {
  path: routes.botSettingsRules,
  element: page,
  children: [
    {
      path: routes.botSettingsRulesCreate,
      element: createForm,
    },
    {
      path: routes.botSettingRule({ botSettingRuleId: ':botSettingRuleId' }),
      element: detail,
      children: [
        {
          path: routes.botSettingRuleBotSetting({
            botSettingRuleId: ':botSettingRuleId',
            botSettingId: ':botSettingId',
          }),
          element: detailBotSetting,
        },
        {
          path: routes.botSettingRuleBotSettingEdit({
            botSettingRuleId: ':botSettingRuleId',
            botSettingId: ':botSettingId',
          }),
          element: detailBotSettingEditForm,
        },
      ],
    },
    {
      path: routes.botSettingRuleEdit({ botSettingRuleId: ':botSettingRuleId' }),
      element: editForm,
    },
  ],
};

export default globalBotSettingsRulesPage;
