import { useTranslation } from 'react-i18next';
import { GlobalClubSchema } from '../../api/schemas/globalClubSchema';
import PageAddon from '../../components/PageAddon';
import Tag from '../../components/Tag';
import Tooltip from '../../components/Tooltip';

interface Props {
  data: GlobalClubSchema['code'][];
  defaultClub?: GlobalClubSchema['code'];
}

const ClubPageAddonTagsOld = (props: Props) => {
  const { data, defaultClub } = props;

  const { t } = useTranslation();

  if (!data.length) return <PageAddon.Tags>—</PageAddon.Tags>;

  return (
    <PageAddon.Tags>
      {data.map((item) => {
        if (defaultClub && item === defaultClub) {
          return (
            <Tooltip key={item} label={t('common.default')}>
              <div>
                <Tag highlighted>{item}</Tag>
              </div>
            </Tooltip>
          );
        }

        return <Tag key={item}>{item}</Tag>;
      })}
    </PageAddon.Tags>
  );
};

export default ClubPageAddonTagsOld;
