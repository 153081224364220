import PrettyNumber from '../../components/PrettyNumber';
import { convertCentsToDollars } from '../../utils/data';
import { CommonDealSchema } from './types';

interface Props {
  data: CommonDealSchema;
}

const DealBalanceUSD = (props: Props) => {
  const { data } = props;

  const { balance_usd } = data;

  if (!balance_usd) return '—';

  return <PrettyNumber>{convertCentsToDollars(balance_usd)}</PrettyNumber>;
};

export default DealBalanceUSD;
