import { Controller, UseFormReturn } from 'react-hook-form';
import { z } from 'zod';
import { AccountRoomConfigSchema } from '../../api/schemas/accountRoomConfigSchema';
import { TRAINER_POKER_ROOM } from '../../api/schemas/trainerPokerRoomSchema';
import Input from '../../components/Input';
import Select from '../../components/Select';
import { t } from '../../i18n';
import { max, required } from '../../utils/form';
import { booleanOptions, getBooleanOption } from './helpers';
import { RoomConfigFields as Fields } from './types';

const TABLE_SHAPES = {
  ZERO: 0,
  ONE: 1,
} as const;

const FELT_COLORS = {
  GREEN: '3,135,44',
  BLUE: '2,80,151',
  RED: '158,3,3',
  GRAY: '52,52,52',
} as const;

const BACKGROUNDS = {
  ZERO: 0,
  ONE: 1,
  TWO: 2,
  THREE: 3,
  FOUR: 4,
  FIVE: 5,
} as const;

const DECK_TYPES = {
  DEFAULT: 'Default',
  FOUR_COLOR_CLASSIC: '4ColorClassic',
  MODERN: 'Modern',
  MODERN_COLOR: 'ModernColor',
  ALTERNATIVE: 'Alternative',
  ALTERNATIVE_MINIMAL: 'AlternativeMinimal',
} as const;

const CARDS_BACK = {
  EMPTY: '',
  MODERN: 'Modern',
  ALTERNATIVE: 'Alternative',
  ALTERNATIVE_MINIMAL: 'AlternativeMinimal',
  WPT: 'WPT',
  PPLIVE: 'PPLive',
} as const;

const RIT_TYPES = {
  PERCENT: 1,
  EQUITY: 2,
} as const;

const getRitTypeLabel = (value: unknown) => {
  if (value === RIT_TYPES.PERCENT) return t('common.percent');
  if (value === RIT_TYPES.EQUITY) return t('common.equity');

  return t('common.unknown');
};

const tableShapes = Object.values(TABLE_SHAPES);
const feltColors = Object.values(FELT_COLORS);
const backgrounds = Object.values(BACKGROUNDS);
const deckTypes = Object.values(DECK_TYPES);
const cardsBack = Object.values(CARDS_BACK);
const ritTypes = Object.values(RIT_TYPES);

export const tableShapesOptions = tableShapes.map((item) => ({ value: item, label: String(item) }));
export const feltColorsOptions = feltColors.map((item) => ({ value: item, label: item }));
export const backgroundsOptions = backgrounds.map((item) => ({ value: item, label: String(item) }));
export const deckTypesOptions = deckTypes.map((item) => ({ value: item, label: item }));
export const cardsBackOptions = cardsBack.map((item) => ({ value: item, label: item }));

export const ritTypesOptions = ritTypes.map((item) => ({
  value: item,
  label: getRitTypeLabel(item),
}));

export const getDefaultValues = (data?: AccountRoomConfigSchema) => ({
  // PartyPoker
  TableShape: data ? { value: Number(data.TableShape), label: String(data.TableShape) } : null,
  FeltColor: data ? { value: String(data.FeltColor), label: String(data.FeltColor) } : null,
  Background: data ? { value: Number(data.Background), label: String(data.Background) } : null,
  DeckType: data ? { value: String(data.DeckType), label: String(data.DeckType) } : null,
  CardBack: data ? { value: String(data.CardBack), label: String(data.CardBack) } : null,
  IsDefaultAvatar: Boolean(data?.IsDefaultAvatar),
  AutoBuyinCashGamesRebuy: Boolean(data?.AutoBuyinCashGamesRebuy),
  MuckLosingUncalledHands: Boolean(data?.MuckLosingUncalledHands),

  // PPPoker
  ev_chop_percent: data ? String(data.ev_chop_percent) : '',
  rim_percent: data ? String(data.rim_percent) : '',
  custom_timing_profile: data?.custom_timing_profile ? String(data.custom_timing_profile) : '',
  ppsr: Boolean(data?.ppsr),
  brain_connection: Boolean(data?.brain_connection),

  // Pokerrrr2
  rit_type: data ? { value: Number(data.rit_type), label: getRitTypeLabel(data.rit_type) } : null,
  rit_percent: data ? String(data.rit_percent) : '',
  dead_blind: Boolean(data?.dead_blind),
  auto_muck: Boolean(data?.auto_muck),
  timebank: Boolean(data?.timebank),
  is_standard_stack_seat: Boolean(data?.is_standard_stack_seat),
  auto_rebuy: Boolean(data?.auto_rebuy),
  tournament_addon: Boolean(data?.tournament_addon),
  max_tournament_rebuy: data ? String(data.max_tournament_rebuy) : '',

  // Ruler
  nickname: data ? String(data.nickname) : '',

  // common
  balance_check: Boolean(data?.balance_check), // Pokerrrr2, Ruler
  autoplay: Boolean(data?.autoplay), // PPPoker, ClubGG
  show_cards: Boolean(data?.show_cards), // PPPoker, ClubGG
  rit_equity: data ? String(data.rit_equity) : '', // Pokerrrr2, ClubGG
});

// eslint-disable-next-line no-use-before-define
export const labels: Record<keyof ReturnType<typeof getFields>, string> = {
  TableShape: t('common.table_shape'),
  FeltColor: t('common.felt_color'),
  Background: t('common.background'),
  DeckType: t('common.deck_type'),
  CardBack: t('common.card_back'),
  IsDefaultAvatar: t('common.default_avatar'),
  AutoBuyinCashGamesRebuy: t('common.rebuy'),
  MuckLosingUncalledHands: t('common.muck_hands'),
  ev_chop_percent: t('common.ev_chop_%'),
  rim_percent: t('common.rim_%'),
  ppsr: t('common.ppsr'),
  autoplay: t('common.autoplay'),
  rit_percent: t('common.rit_%'),
  rit_equity: t('common.rit_equity_%'),
  rit_type: t('common.rit_type'),
  dead_blind: t('common.dead_blind'),
  auto_muck: t('common.auto_muck'),
  timebank: t('common.timebank'),
  balance_check: t('common.balance_check'),
  nickname: t('common.nickname'),
  show_cards: t('common.show_cards'),
  is_standard_stack_seat: t('common.standart_stack'),
  auto_rebuy: t('common.auto_rebuy'),
  tournament_addon: t('common.tournament_addon'),
  max_tournament_rebuy: t('common.max_tournament_rebuy'),
  custom_timing_profile: t('common.timing_profile'),
  brain_connection: t('common.brain'),
};

export const getFields = (form: UseFormReturn<Fields>) => {
  const {
    control,
    formState: { errors },
  } = form;

  return {
    // PartyPoker
    TableShape: (
      <Controller
        name="TableShape"
        control={control}
        rules={{ validate: required }}
        render={({ field }) => (
          <Select
            clearable={false}
            label={labels.TableShape}
            options={tableShapesOptions}
            value={field.value}
            onChange={field.onChange}
            error={errors.TableShape?.message}
          />
        )}
      />
    ),
    FeltColor: (
      <Controller
        name="FeltColor"
        control={control}
        rules={{ validate: required }}
        render={({ field }) => (
          <Select
            clearable={false}
            label={labels.FeltColor}
            options={feltColorsOptions}
            value={field.value}
            onChange={field.onChange}
            error={errors.FeltColor?.message}
          />
        )}
      />
    ),
    Background: (
      <Controller
        name="Background"
        control={control}
        rules={{ validate: required }}
        render={({ field }) => (
          <Select
            clearable={false}
            label={labels.Background}
            options={backgroundsOptions}
            value={field.value}
            onChange={field.onChange}
            error={errors.Background?.message}
          />
        )}
      />
    ),
    DeckType: (
      <Controller
        name="DeckType"
        control={control}
        rules={{ validate: required }}
        render={({ field }) => (
          <Select
            clearable={false}
            label={labels.DeckType}
            options={deckTypesOptions}
            value={field.value}
            onChange={field.onChange}
            error={errors.DeckType?.message}
          />
        )}
      />
    ),
    CardBack: (
      <Controller
        name="CardBack"
        control={control}
        rules={{ validate: required }}
        render={({ field }) => (
          <Select
            clearable={false}
            label={labels.CardBack}
            options={cardsBackOptions}
            value={field.value}
            onChange={field.onChange}
            error={errors.CardBack?.message}
          />
        )}
      />
    ),
    IsDefaultAvatar: (
      <Controller
        name="IsDefaultAvatar"
        control={control}
        render={({ field }) => (
          <Select
            clearable={false}
            label={labels.IsDefaultAvatar}
            options={booleanOptions}
            value={typeof field.value === 'boolean' ? getBooleanOption(field.value) : field.value}
            onChange={field.onChange}
            error={errors.IsDefaultAvatar?.message}
          />
        )}
      />
    ),
    AutoBuyinCashGamesRebuy: (
      <Controller
        name="AutoBuyinCashGamesRebuy"
        control={control}
        render={({ field }) => (
          <Select
            clearable={false}
            label={labels.AutoBuyinCashGamesRebuy}
            options={booleanOptions}
            value={typeof field.value === 'boolean' ? getBooleanOption(field.value) : field.value}
            onChange={field.onChange}
            error={errors.AutoBuyinCashGamesRebuy?.message}
          />
        )}
      />
    ),
    MuckLosingUncalledHands: (
      <Controller
        name="MuckLosingUncalledHands"
        control={control}
        render={({ field }) => (
          <Select
            clearable={false}
            label={labels.MuckLosingUncalledHands}
            options={booleanOptions}
            value={typeof field.value === 'boolean' ? getBooleanOption(field.value) : field.value}
            onChange={field.onChange}
            error={errors.MuckLosingUncalledHands?.message}
          />
        )}
      />
    ),

    // PPPoker
    ev_chop_percent: (
      <Controller
        name="ev_chop_percent"
        control={control}
        rules={{ validate: required }}
        render={({ field }) => (
          <Input
            label={labels.ev_chop_percent}
            error={errors.ev_chop_percent?.message}
            {...field}
          />
        )}
      />
    ),
    rim_percent: (
      <Controller
        name="rim_percent"
        control={control}
        rules={{ validate: required }}
        render={({ field }) => (
          <Input label={labels.rim_percent} error={errors.rim_percent?.message} {...field} />
        )}
      />
    ),
    custom_timing_profile: (
      <Controller
        name="custom_timing_profile"
        control={control}
        render={({ field }) => (
          <Input
            label={labels.custom_timing_profile}
            error={errors.custom_timing_profile?.message}
            {...field}
          />
        )}
      />
    ),
    ppsr: (
      <Controller
        name="ppsr"
        control={control}
        render={({ field }) => (
          <Select
            clearable={false}
            label={labels.ppsr}
            options={booleanOptions}
            value={typeof field.value === 'boolean' ? getBooleanOption(field.value) : field.value}
            onChange={field.onChange}
            error={errors.ppsr?.message}
          />
        )}
      />
    ),
    brain_connection: (
      <Controller
        name="brain_connection"
        control={control}
        render={({ field }) => (
          <Select
            clearable={false}
            label={labels.brain_connection}
            options={booleanOptions}
            value={typeof field.value === 'boolean' ? getBooleanOption(field.value) : field.value}
            onChange={field.onChange}
            error={errors.brain_connection?.message}
          />
        )}
      />
    ),

    /// Pokerrrr2
    rit_type: (
      <Controller
        name="rit_type"
        control={control}
        rules={{ validate: required }}
        render={({ field }) => (
          <Select
            clearable={false}
            label={labels.rit_type}
            options={ritTypesOptions}
            value={field.value}
            onChange={field.onChange}
            error={errors.rit_type?.message}
          />
        )}
      />
    ),
    rit_percent: (
      <Controller
        name="rit_percent"
        control={control}
        rules={{ validate: required }}
        render={({ field }) => (
          <Input label={labels.rit_percent} error={errors.rit_percent?.message} {...field} />
        )}
      />
    ),
    rit_equity: (
      <Controller
        name="rit_equity"
        control={control}
        rules={{ validate: { required, max: max(t('common.rit_equity_%'), 100) } }}
        render={({ field }) => (
          <Input label={labels.rit_equity} error={errors.rit_equity?.message} {...field} />
        )}
      />
    ),
    dead_blind: (
      <Controller
        name="dead_blind"
        control={control}
        render={({ field }) => (
          <Select
            clearable={false}
            label={labels.dead_blind}
            options={booleanOptions}
            value={typeof field.value === 'boolean' ? getBooleanOption(field.value) : field.value}
            onChange={field.onChange}
            error={errors.dead_blind?.message}
          />
        )}
      />
    ),
    auto_muck: (
      <Controller
        name="auto_muck"
        control={control}
        render={({ field }) => (
          <Select
            clearable={false}
            label={labels.auto_muck}
            options={booleanOptions}
            value={typeof field.value === 'boolean' ? getBooleanOption(field.value) : field.value}
            onChange={field.onChange}
            error={errors.auto_muck?.message}
          />
        )}
      />
    ),
    timebank: (
      <Controller
        name="timebank"
        control={control}
        render={({ field }) => (
          <Select
            clearable={false}
            label={labels.timebank}
            options={booleanOptions}
            value={typeof field.value === 'boolean' ? getBooleanOption(field.value) : field.value}
            onChange={field.onChange}
            error={errors.timebank?.message}
          />
        )}
      />
    ),
    is_standard_stack_seat: (
      <Controller
        name="is_standard_stack_seat"
        control={control}
        render={({ field }) => (
          <Select
            clearable={false}
            label={labels.is_standard_stack_seat}
            options={booleanOptions}
            value={typeof field.value === 'boolean' ? getBooleanOption(field.value) : field.value}
            onChange={field.onChange}
            error={errors.is_standard_stack_seat?.message}
          />
        )}
      />
    ),
    auto_rebuy: (
      <Controller
        name="auto_rebuy"
        control={control}
        render={({ field }) => (
          <Select
            clearable={false}
            label={labels.auto_rebuy}
            options={booleanOptions}
            value={typeof field.value === 'boolean' ? getBooleanOption(field.value) : field.value}
            onChange={field.onChange}
            error={errors.auto_rebuy?.message}
          />
        )}
      />
    ),
    tournament_addon: (
      <Controller
        name="tournament_addon"
        control={control}
        render={({ field }) => (
          <Select
            clearable={false}
            label={labels.tournament_addon}
            options={booleanOptions}
            value={typeof field.value === 'boolean' ? getBooleanOption(field.value) : field.value}
            onChange={field.onChange}
            error={errors.tournament_addon?.message}
          />
        )}
      />
    ),
    max_tournament_rebuy: (
      <Controller
        name="max_tournament_rebuy"
        control={control}
        rules={{ validate: required }}
        render={({ field }) => (
          <Input
            label={labels.max_tournament_rebuy}
            error={errors.max_tournament_rebuy?.message}
            {...field}
          />
        )}
      />
    ),

    /// Ruler
    nickname: (
      <Controller
        name="nickname"
        control={control}
        rules={{ validate: required }}
        render={({ field }) => (
          <Input label={labels.nickname} error={errors.nickname?.message} {...field} />
        )}
      />
    ),

    // common
    balance_check: (
      <Controller
        name="balance_check" // Pokerrrr2, Ruler
        control={control}
        render={({ field }) => (
          <Select
            clearable={false}
            label={labels.balance_check}
            options={booleanOptions}
            value={typeof field.value === 'boolean' ? getBooleanOption(field.value) : field.value}
            onChange={field.onChange}
            error={errors.balance_check?.message}
          />
        )}
      />
    ),
    autoplay: (
      <Controller
        name="autoplay" // PPPoker, ClubGG
        control={control}
        render={({ field }) => (
          <Select
            clearable={false}
            label={labels.autoplay}
            options={booleanOptions}
            value={typeof field.value === 'boolean' ? getBooleanOption(field.value) : field.value}
            onChange={field.onChange}
            error={errors.autoplay?.message}
          />
        )}
      />
    ),
    show_cards: (
      <Controller
        name="show_cards" // PPPoker, ClubGG
        control={control}
        render={({ field }) => (
          <Select
            clearable={false}
            label={labels.show_cards}
            options={booleanOptions}
            value={typeof field.value === 'boolean' ? getBooleanOption(field.value) : field.value}
            onChange={field.onChange}
            error={errors.show_cards?.message}
          />
        )}
      />
    ),
  };
};

export const getPayload = (values: Fields) => {
  const getBooleanPayload = (value: unknown) =>
    typeof value === 'boolean'
      ? value
      : Boolean(z.object({ value: z.number() }).parse(value).value);

  return {
    // PartyPoker
    TableShape: values.TableShape?.value,
    FeltColor: values.FeltColor?.value,
    Background: values.Background?.value,
    DeckType: values.DeckType?.value,
    CardBack: values.CardBack?.value,
    IsDefaultAvatar: getBooleanPayload(values.IsDefaultAvatar),
    AutoBuyinCashGamesRebuy: getBooleanPayload(values.AutoBuyinCashGamesRebuy),
    MuckLosingUncalledHands: getBooleanPayload(values.MuckLosingUncalledHands),

    // PPPoker
    ev_chop_percent: Number(values.ev_chop_percent),
    rim_percent: Number(values.rim_percent),
    custom_timing_profile: values.custom_timing_profile,
    ppsr: getBooleanPayload(values.ppsr),
    brain_connection: getBooleanPayload(values.brain_connection),

    // Pokerrrr2
    rit_type: values.rit_type?.value,
    rit_percent: Number(values.rit_percent),
    dead_blind: getBooleanPayload(values.dead_blind),
    auto_muck: getBooleanPayload(values.auto_muck),
    timebank: getBooleanPayload(values.timebank),
    is_standard_stack_seat: getBooleanPayload(values.is_standard_stack_seat),
    auto_rebuy: getBooleanPayload(values.auto_rebuy),
    tournament_addon: getBooleanPayload(values.tournament_addon),
    max_tournament_rebuy: Number(values.max_tournament_rebuy),

    // Ruler
    nickname: values.nickname,

    // common
    balance_check: getBooleanPayload(values.balance_check), // Pokerrrr2, Ruler
    autoplay: getBooleanPayload(values.autoplay), // PPPoker, ClubGG
    show_cards: getBooleanPayload(values.show_cards), // PPPoker, ClubGG
    rit_equity: Number(values.rit_equity), // Pokerrrr2, ClubGG
  };
};

export const roomsFields: Record<string, (keyof ReturnType<typeof getFields>)[]> = {
  [TRAINER_POKER_ROOM.PARTYPOKER]: [
    'TableShape',
    'FeltColor',
    'Background',
    'DeckType',
    'CardBack',
    'IsDefaultAvatar',
    'AutoBuyinCashGamesRebuy',
    'MuckLosingUncalledHands',
  ],
  [TRAINER_POKER_ROOM.PPPOKER]: [
    'ev_chop_percent',
    'rim_percent',
    'custom_timing_profile',
    'ppsr',
    'autoplay',
    'brain_connection',
    'show_cards',
  ],
  [TRAINER_POKER_ROOM.POKERRRR2]: [
    'rit_type',
    'rit_percent',
    'rit_equity',
    'dead_blind',
    'balance_check',
    'auto_muck',
    'timebank',
    'is_standard_stack_seat',
    'auto_rebuy',
    'tournament_addon',
    'max_tournament_rebuy',
  ],
  [TRAINER_POKER_ROOM.RULER]: ['nickname', 'balance_check'],
  [TRAINER_POKER_ROOM.CLUBGG]: ['autoplay', 'show_cards', 'rit_equity'],
};
