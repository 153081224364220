import { z } from 'zod';
import api from '../services/api';
import permissionSchema from './schemas/permissionSchema';

const loadPermissions = async () => {
  const response = await api.get('/api/permissions');

  return z.array(permissionSchema).parse(response.data);
};

export default loadPermissions;
