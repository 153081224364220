import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { deleteGlobalWorkspace } from '../../api';
import { WorkspaceSchema } from '../../api/schemas/workspaceSchema';
import { presets } from '../../components/Dropdown';
import routes from '../../config/routes';
import useURL from '../../hooks/useURL';
import { RouteParams } from '../../utils/generics';
import notify from '../../utils/notify';
import { can } from '../permission/helpers';

type Params = RouteParams<typeof routes.globalWorkspace>;

interface Args {
  data: WorkspaceSchema;
}

const useDropdown = (args: Args) => {
  const { data } = args;

  const { id, name, is_deleted } = data;

  const { t } = useTranslation();
  const { navigate } = useURL();
  const { workspaceId } = useParams<Params>();

  return {
    title: name,
    disabled: is_deleted,
    options: {
      edit: {
        ...presets.edit,
        onClick: () => navigate(routes.globalWorkspaceEdit({ workspaceId: id })),
        disabled: !can.workspace.createUpdate,
        ...(!can.workspace.createUpdate && { tooltip: t('common.no_permission') }),
      },
      copy: presets.copyId(id),
      ...(can.workspace.delete && {
        divider: { divider: true },
        delete: {
          ...presets.delete,
          onClick: async () => {
            await deleteGlobalWorkspace({ workspaceId: id });

            if (id === workspaceId) {
              navigate(routes.globalWorkspaces);
            }

            notify('success', { title: t('sentences.record_has_been_deleted') });
          },
        },
      }),
    },
  };
};

export default useDropdown;
