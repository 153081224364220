import { useTranslation } from 'react-i18next';
import { Outlet } from 'react-router-dom';
import { GlobalClubSchema } from '../../api/schemas/globalClubSchema';
import { GlobalLeagueSchema } from '../../api/schemas/globalLeagueSchema';
import { WorkspaceSchema } from '../../api/schemas/workspaceSchema';
import Button from '../../components/Button';
import Notes from '../../components/Notes';
import PageAddon from '../../components/PageAddon';
import Tooltip from '../../components/Tooltip';
import icon from '../../static/icons';
import { addWhen } from '../../utils';
import { can } from '../permission/helpers';
import GlobalClubDetailAnnouncementsTab from './GlobalClubDetailAnnouncementsTab';
import GlobalClubDetailDetailsTab from './GlobalClubDetailDetailsTab';
import GlobalClubDetailDropdown from './GlobalClubDetailDropdown';
import GlobalClubDetailWorkspacesTab from './GlobalClubDetailWorkspacesTab';

interface Props {
  data: GlobalClubSchema;
  onClose: () => void;
  onEdit: () => void;
  getNotesRoute?: () => void;
  getLeagueRoute?: (args: { leagueId: GlobalLeagueSchema['id'] }) => string;
  getWorkspaceRoute?: (args: { workspaceId: WorkspaceSchema['id'] }) => string;
}

const GlobalClubDetail = (props: Props) => {
  const { data, onClose, onEdit, getNotesRoute, getLeagueRoute, getWorkspaceRoute } = props;

  const { t } = useTranslation();

  const { id, code, notes_count, is_deleted } = data;

  const titleIcons = (
    <>
      {is_deleted && icon('trash', 12)}
      {Boolean(notes_count) && <Tooltip label={t('common.notes')}>{icon('notes', 12)}</Tooltip>}
    </>
  );

  return (
    <PageAddon.Group>
      <Outlet />
      <PageAddon
        title={code}
        icons={titleIcons}
        onClose={onClose}
        tabs={[
          {
            label: t('common.details'),
            content: <GlobalClubDetailDetailsTab data={data} getLeagueRoute={getLeagueRoute} />,
          },
          ...addWhen(
            {
              label: t('common.workspaces'),
              content: (
                <GlobalClubDetailWorkspacesTab clubId={id} getWorkspaceRoute={getWorkspaceRoute} />
              ),
            },
            can.workspace.read
          ),
          {
            label: t('common.announcements'),
            content: <GlobalClubDetailAnnouncementsTab clubId={id} />,
          },
        ]}
      >
        {!is_deleted && (
          <PageAddon.Controls>
            <PageAddon.Controls.Group>
              {getNotesRoute && <Notes.Button count={notes_count} onClick={getNotesRoute} />}
              <Tooltip
                label={can.club.createUpdate ? t('common.edit') : t('common.no_permission')}
                hideOnMobile={can.club.createUpdate}
              >
                <div>
                  <Button
                    variant="transparent"
                    icon={icon('edit', 16)}
                    onClick={onEdit}
                    data-testid="edit"
                    disabled={!can.club.createUpdate}
                  />
                </div>
              </Tooltip>
              <GlobalClubDetailDropdown data={data} />
            </PageAddon.Controls.Group>
          </PageAddon.Controls>
        )}
      </PageAddon>
    </PageAddon.Group>
  );
};

export default GlobalClubDetail;
