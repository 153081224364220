import { useQuery } from '@tanstack/react-query';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import loadGlobalLobbyTablePlayers from '../../../api/global-lobby/loadGlobalLobbyTablePlayers';
import { LobbySchema } from '../../../api/schemas/lobbySchema';
import Alert from '../../../components/Alert';
import Button from '../../../components/Button';
import Placeholder from '../../../components/Placeholder';
import queryKeys from '../../../config/queryKeys';
import icon from '../../../static/icons';
import { PLAYER_TYPE_LABELS } from '../helpers';
import styles from './styles.module.scss';

interface Props {
  data: LobbySchema;
  variant: 'primary' | 'secondary';
}

const GlobalLobbyTablePlayers = (props: Props) => {
  const { data: lobbyData, variant } = props;

  const tableId = lobbyData.table.id;

  const { t } = useTranslation();

  const queryKey = [queryKeys.lobbyTablePlayers({ tableId })];
  const queryFn = () => loadGlobalLobbyTablePlayers({ tableId });

  const { data, isPending, isError, refetch } = useQuery({
    queryKey,
    queryFn,
  });

  const wrapperClassName = clsx(styles.wrapper, [styles[variant]]);

  if (isPending) {
    return (
      <div className={styles.container}>
        <div className={styles.player}>
          <Placeholder className={styles.indexPlaceholder} />
          <div className={clsx(styles.info, styles.placeholders, [styles[variant]])}>
            <Placeholder />
            <Placeholder />
          </div>
        </div>
      </div>
    );
  }

  if (isError) {
    return (
      <div className={wrapperClassName}>
        <Alert
          type="error"
          title={t('sentences.something_went_wrong')}
          description={t('sentences.click_retry_to_try_again')}
        />
        <Button
          size="small"
          variant="secondary"
          icon={icon('refresh', 16)}
          onClick={() => refetch()}
        >
          {t('common.retry')}
        </Button>
      </div>
    );
  }

  if (!data) return null;

  const { players } = data;

  if (players === null) {
    return (
      <div className={wrapperClassName}>
        <span>{t('common.table_state_is_unknown')}</span>
      </div>
    );
  }

  if (players.length === 0) {
    return (
      <div className={wrapperClassName}>
        <span>{t('common.table_is_empty')}</span>
      </div>
    );
  }

  return (
    <div className={styles.container}>
      {players.map((item) => (
        <div key={item.pid} className={styles.player}>
          <div className={clsx(styles.index, [styles[variant]])}>{item.position}</div>
          <div className={clsx(styles.info, [styles[variant]])}>
            <span>{item.pid}</span>
            <span>{PLAYER_TYPE_LABELS[item.type]}</span>
          </div>
        </div>
      ))}
    </div>
  );
};

export default GlobalLobbyTablePlayers;
