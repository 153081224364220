import clsx from 'clsx';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import icon from '../../../static/icons';
import Button from '../../Button';
import styles from './styles.module.scss';

interface Props {
  label: React.ReactNode;
  children: React.ReactNode;
  count?: number;
  onClear?: () => void;
}

const FilterCollapse = (props: Props) => {
  const { label, children, count, onClear } = props;

  const { t } = useTranslation();

  const hasValue = Boolean(count !== 0 && onClear);

  const [open, setOpen] = useState(hasValue);

  const handleClick = () => setOpen((prev) => !prev);

  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        <button
          type="button"
          className={clsx(styles.toggler, open && styles.open)}
          onClick={handleClick}
        >
          {icon('chevronDown', 16)}
          <div className={styles.label}>{label}</div>
        </button>
        {hasValue && (
          <Button
            variant="light"
            icon={icon('broom', 16)}
            className={styles.clear}
            onClick={onClear}
          >
            {t('common.clear')}
          </Button>
        )}
      </div>
      {open && <div className={styles.content}>{children}</div>}
    </div>
  );
};

export default FilterCollapse;
