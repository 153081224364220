import { useTranslation } from 'react-i18next';
import { GlobalUserSchema } from '../../../api/schemas/globalUserSchema';
import { WorkspaceSchema } from '../../../api/schemas/workspaceSchema';
import Collapse from '../../../components/Collapse';
import Empty from '../../../components/Empty';
import Tooltip from '../../../components/Tooltip';
import icon from '../../../static/icons';
import { formatAbsoluteDate } from '../../../utils/date';
import GlobalWorkspaceTypes from '../../global-workspace/GlobalWorkspaceTypes';
import styles from './styles.module.scss';

interface Props {
  data: GlobalUserSchema;
  getWorkspaceRoute?: (args: { workspaceId: WorkspaceSchema['id'] }) => string;
}

const GlobalUserDetailWorkspacesTab = (props: Props) => {
  const { data, getWorkspaceRoute } = props;

  const { t } = useTranslation();

  const { maintenances } = data;

  const getContent = () => {
    if (!maintenances.length) return <Empty />;

    return (
      <Collapse.Group>
        {maintenances.map((item) => (
          <Collapse
            key={item.id}
            label={item.name}
            labelAfter={
              item.is_owner && (
                <Collapse.SecondaryLabel>
                  <Tooltip label={t('common.owner')}>{icon('crown', 12)}</Tooltip>
                </Collapse.SecondaryLabel>
              )
            }
            caption={<Collapse.Id id={item.id} />}
            {...(getWorkspaceRoute && { to: getWorkspaceRoute({ workspaceId: item.id }) })}
          >
            <Collapse.InfoField label={t('common.types')}>
              <GlobalWorkspaceTypes types={item.maintenance_types} />
            </Collapse.InfoField>
            <Collapse.InfoField label={t('common.invited')}>
              {formatAbsoluteDate(item.invited_on)}
            </Collapse.InfoField>
          </Collapse>
        ))}
      </Collapse.Group>
    );
  };

  return <div className={styles.container}>{getContent()}</div>;
};

export default GlobalUserDetailWorkspacesTab;
