import clsx from 'clsx';
import { ReactNode } from 'react';
import Page from '../../components/Page';
import useURL from '../../hooks/useURL';
import { snakeToSentenceCase } from '../../utils/data';
import StorybookPageAlert from './StorybookPageAlert';
import StorybookPageBadge from './StorybookPageBadge';
import StorybookPageButton from './StorybookPageButton';
import StorybookPageCard from './StorybookPageCard';
import StorybookPageCheckbox from './StorybookPageCheckbox';
import StorybookPageColorPicker from './StorybookPageColorPicker';
import StorybookPageIcons from './StorybookPageIcons';
import StorybookPageMap from './StorybookPageMap';
import StorybookPageQRCode from './StorybookPageQRCode';
import StorybookPageSelect from './StorybookPageSelect';
import StorybookPageTabs from './StorybookPageTabs';
import styles from './styles.module.scss';

const components: Record<string, ReactNode> = {
  alert: <StorybookPageAlert />,
  badge: <StorybookPageBadge />,
  button: <StorybookPageButton />,
  card: <StorybookPageCard />,
  checkbox: <StorybookPageCheckbox />,
  color_picker: <StorybookPageColorPicker />,
  map: <StorybookPageMap />,
  select: <StorybookPageSelect />,
  tabs: <StorybookPageTabs />,
  qr_code: <StorybookPageQRCode />,
  icons: <StorybookPageIcons />,
};

const StorybookPage = () => {
  const { urlParams, setURLParams } = useURL();

  const { component = 'button' } = urlParams;

  return (
    <Page heading="Storybook" search={false} goToTop={false} filter={false} sidebar={false}>
      <div className={styles.container}>
        <div className={styles.sidebar}>
          {Object.keys(components).map((item) => (
            <button
              key={item}
              type="button"
              onClick={() => setURLParams({ component: item })}
              className={clsx(styles.button, component === item && styles.active)}
            >
              {snakeToSentenceCase(item)}
            </button>
          ))}
        </div>
        <div className={styles.component}>{components[component]}</div>
      </div>
    </Page>
  );
};

export default StorybookPage;
