import { useTranslation } from 'react-i18next';
import { Outlet, useParams } from 'react-router-dom';
import { loadWorkspaceOrders } from '../../api';
import { OrderSchema } from '../../api/schemas/orderSchema';
import { WorkspaceSchema } from '../../api/schemas/workspaceSchema';
import Card from '../../components/Card';
import Page from '../../components/Page';
import PageControls from '../../components/PageControls';
import PageTop from '../../components/PageTop';
import Table, { getTableProps } from '../../components/Table';
import queryKeys from '../../config/queryKeys';
import routes from '../../config/routes';
import { TYPE_LABELS } from '../../features/order/helpers';
import OrderAmount from '../../features/order/OrderAmount';
import OrderDetailLink from '../../features/order/OrderDetailLink';
import OrderDropdown from '../../features/order/OrderDropdown';
import OrderStatus from '../../features/order/OrderStatus';
import WorkspaceOrderFilter from '../../features/workspace-order/WorkspaceOrderFilter';
import useBreakpoints from '../../hooks/useBreakpoints';
import usePaginatedDataNew from '../../hooks/usePaginatedDataNew';
import useURL from '../../hooks/useURL';
import useWebSocketPaginatedActions from '../../hooks/useWebSocketPaginatedActions';
import { convertCentsToUSDT } from '../../utils/data';
import { RouteParams } from '../../utils/generics';
import Error403Page from '../Error403Page';
import styles from './styles.module.scss';

type Params = RouteParams<typeof routes.workspaceOrder>;

interface Props {
  workspaceId: WorkspaceSchema['id'];
}

const WorkspaceOrdersPage = (props: Props) => {
  const { workspaceId } = props;

  const { t } = useTranslation();
  const { isDesktop, isTablet, isPhone } = useBreakpoints();
  const { orderId } = useParams<Params>();
  const { urlParams } = useURL();
  const { search } = urlParams;

  const queryKey = [queryKeys.workspaceOrders({ workspaceId }), urlParams];

  const query = usePaginatedDataNew({
    queryKey,
    queryFn: (params) => loadWorkspaceOrders({ workspaceId }, { ...urlParams, ...params }),
  });

  const { data, error, checkIsInView } = query;

  const { checkIsCreated } = useWebSocketPaginatedActions<OrderSchema, OrderSchema>({
    queryKey,
    domain: 'Order',
    updateAction: ['Edited', 'Done'],
    select: (item) => item,
    enabled: (message) => workspaceId === message.meta_info?.maintenance_id,
  });

  if (error?.response?.status === 403) return <Error403Page />;

  const getDetailLink = (order: OrderSchema) =>
    routes.workspaceOrder({ workspaceId, orderId: order.id });

  return (
    <Page
      heading={t('common.orders')}
      filter={<WorkspaceOrderFilter workspaceId={workspaceId} urlParams={urlParams} />}
      extra={<PageControls />}
      addon={<Outlet />}
    >
      {isPhone && (
        <PageTop
          sorting={[
            { key: 'created_on', title: t('common.created'), default: true, defaultOrder: 'desc' },
            { key: 'type', title: t('common.type') },
            { key: 'amount', title: t('common.amount') },
            { key: 'status', title: t('common.status') },
          ]}
        />
      )}
      {(isDesktop || isTablet) && (
        <Table
          data={data}
          search={search}
          {...getTableProps(query)}
          className={styles.table}
          columns={[
            {
              key: 'created_on',
              title: t('common.date'),
              sorting: true,
              render: ({ values }) => <OrderDetailLink data={values} to={getDetailLink(values)} />,
            },
            {
              key: 'type',
              title: t('common.type'),
              sorting: true,
              render: ({ values }) => TYPE_LABELS[values.type],
            },
            {
              key: 'amount',
              title: <OrderAmount />,
              sorting: true,
              render: ({ values }) => convertCentsToUSDT(values.amount),
            },
            {
              key: 'status',
              title: t('common.status'),
              sorting: true,
              render: ({ values }) => <OrderStatus data={values} />,
            },
            {
              render: ({ values }) => <OrderDropdown data={values} />,
              align: 'right',
              controls: true,
            },
          ]}
          renderRow={(row, index) => (
            <Table.Row
              highlighted={checkIsCreated(row.data.id)}
              active={row.data.id === orderId}
              {...checkIsInView(index)}
              {...row}
            />
          )}
        />
      )}
      {isPhone && (
        <Card.Group>
          {data.map((item, index) => (
            <Card key={item.id} highlighted={checkIsCreated(item.id)} {...checkIsInView(index)}>
              <Card.Top>
                <OrderDetailLink data={item} to={getDetailLink(item)} />
                <Card.Top.Controls>
                  <OrderDropdown data={item} />
                </Card.Top.Controls>
              </Card.Top>
              <Card.Field.Group>
                <Card.Field label={t('common.type')}>{TYPE_LABELS[item.type]}</Card.Field>
                <Card.Field label={<OrderAmount />}>{convertCentsToUSDT(item.amount)}</Card.Field>
                <Card.Field label={t('common.status')}>
                  <OrderStatus data={item} />
                </Card.Field>
              </Card.Field.Group>
            </Card>
          ))}
        </Card.Group>
      )}
    </Page>
  );
};

export default WorkspaceOrdersPage;
