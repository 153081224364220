import { RouteObject } from 'react-router-dom';
import routes from '../../config/routes';
import GlobalLeagueDetail from '../../features/global-league/GlobalLeagueDetail';
import GlobalLeagueForm from '../../features/global-league/GlobalLeagueForm';
import GlobalLeagueLoader from '../../features/global-league/GlobalLeagueLoader';
import GlobalSuperLeagueDetail from '../../features/global-super-league/GlobalSuperLeagueDetail';
import GlobalSuperLeagueForm from '../../features/global-super-league/GlobalSuperLeagueForm';
import GlobalSuperLeagueLoader from '../../features/global-super-league/GlobalSuperLeagueLoader';
import GlobalLeaguesPage from '../../pages/GlobalLeaguesPage';
import Route from './Route';

const page = <GlobalLeaguesPage />;

const createForm = (
  <Route>
    {({ navigate }) => (
      <GlobalLeagueForm
        onClose={() => navigate(routes.leagues)}
        onCreate={({ leagueId }) => navigate(routes.league({ leagueId }))}
      />
    )}
  </Route>
);

const detail = (
  <Route params={['leagueId']}>
    {({ leagueId, navigate }) => (
      <GlobalLeagueLoader leagueId={leagueId}>
        {({ data }) => (
          <GlobalLeagueDetail
            data={data}
            onClose={() => navigate(routes.leagues)}
            onEdit={() => navigate(routes.leagueEdit({ leagueId }))}
            getSuperLeagueRoute={({ superLeagueId }) =>
              routes.leagueSuperLeague({ leagueId, superLeagueId })
            }
          />
        )}
      </GlobalLeagueLoader>
    )}
  </Route>
);

const detailSuperLeague = (
  <Route params={['leagueId', 'superLeagueId']}>
    {({ leagueId, superLeagueId, navigate }) => (
      <GlobalSuperLeagueLoader superLeagueId={superLeagueId}>
        {({ data }) => (
          <GlobalSuperLeagueDetail
            data={data}
            onClose={() => navigate(routes.league({ leagueId }))}
            onEdit={() => navigate(routes.leagueSuperLeagueEdit({ leagueId, superLeagueId }))}
          />
        )}
      </GlobalSuperLeagueLoader>
    )}
  </Route>
);

const detailSuperLeagueEditForm = (
  <Route params={['leagueId', 'superLeagueId']}>
    {({ leagueId, superLeagueId, navigate }) => (
      <GlobalSuperLeagueLoader superLeagueId={superLeagueId}>
        {({ data }) => (
          <GlobalSuperLeagueForm
            data={data}
            onClose={() => navigate(routes.leagueSuperLeague({ leagueId, superLeagueId }))}
            onEdit={() => navigate(routes.leagueSuperLeague({ leagueId, superLeagueId }))}
          />
        )}
      </GlobalSuperLeagueLoader>
    )}
  </Route>
);

const editForm = (
  <Route params={['leagueId']}>
    {({ leagueId, navigate }) => (
      <GlobalLeagueLoader leagueId={leagueId}>
        {({ data }) => (
          <GlobalLeagueForm
            data={data}
            onClose={() => navigate(routes.league({ leagueId }))}
            onEdit={() => navigate(routes.league({ leagueId }))}
          />
        )}
      </GlobalLeagueLoader>
    )}
  </Route>
);

const globalLeaguesPage: RouteObject = {
  path: routes.leagues,
  element: page,
  children: [
    {
      path: routes.leaguesCreate,
      element: createForm,
    },
    {
      path: routes.league({ leagueId: ':leagueId' }),
      element: detail,
      children: [
        {
          path: routes.leagueSuperLeague({
            leagueId: ':leagueId',
            superLeagueId: ':superLeagueId',
          }),
          element: detailSuperLeague,
        },
        {
          path: routes.leagueSuperLeagueEdit({
            leagueId: ':leagueId',
            superLeagueId: ':superLeagueId',
          }),
          element: detailSuperLeagueEditForm,
        },
      ],
    },
    {
      path: routes.leagueEdit({ leagueId: ':leagueId' }),
      element: editForm,
    },
  ],
};

export default globalLeaguesPage;
