import {
  PermissionSchema,
  accountsPermissions,
  botSettingPermissions,
  clubPermissions,
  currencyPermissions,
  dealPermissions,
  lobbyPermissions,
  ordersPermissions,
  rolesPermissions,
  seatControlRulesPermissions,
  sessionsPermissions,
  usersPermissions,
  workspacesPermissions,
} from '../../api/schemas/permissionSchema';
import { t } from '../../i18n';
import { useUserStore } from '../../store/user';

const cp = (permission: PermissionSchema) => {
  const userPermissions = useUserStore.getState().permissions;

  if (userPermissions) return userPermissions.includes(permission);

  return false;
};

export const permissionsGroups = [
  { label: t('common.accounts'), permissions: accountsPermissions },
  { label: t('common.bot_settings'), permissions: botSettingPermissions },
  { label: t('common.clubs'), permissions: clubPermissions },
  { label: t('common.currencies'), permissions: currencyPermissions },
  { label: t('common.deals'), permissions: dealPermissions },
  { label: t('common.lobby'), permissions: lobbyPermissions },
  { label: t('common.orders'), permissions: ordersPermissions },
  { label: t('common.roles'), permissions: rolesPermissions },
  { label: t('common.seat_control_rules'), permissions: seatControlRulesPermissions },
  { label: t('common.sessions'), permissions: sessionsPermissions },
  { label: t('common.users'), permissions: usersPermissions },
  { label: t('common.workspaces'), permissions: workspacesPermissions },
];

export const can = {
  account: {
    get read() {
      return cp('nous_platform_global_account_read');
    },
    get createUpdate() {
      return cp('nous_platform_global_account_create_update');
    },
    get balanceRead() {
      return cp('nous_platform_global_account_balance_read');
    },
    get emailRead() {
      return cp('nous_platform_global_account_email_read');
    },
    get fingerprintRead() {
      return cp('nous_platform_global_account_fingerprint_read');
    },
    get fingerprintCreateUpdate() {
      return cp('nous_platform_global_account_fingerprint_create_update');
    },
    get fingerprintDelete() {
      return cp('nous_platform_global_account_fingerprint_delete');
    },
    get roomConfigRead() {
      return cp('nous_platform_global_account_room_customize_read');
    },
    get roomConfigCreateUpdate() {
      return cp('nous_platform_global_account_room_customize_create_update');
    },
    get switchPilotOff() {
      return cp('nous_platform_global_account_switch_pilot_off');
    },
  },
  botSetting: {
    get read() {
      return cp('nous_platform_global_bot_setting_read');
    },
    get createUpdate() {
      return cp('nous_platform_global_bot_setting_create_update');
    },
    get delete() {
      return cp('nous_platform_global_bot_setting_delete');
    },
    get ruleRead() {
      return cp('nous_platform_global_bot_setting_usage_rule_read');
    },
    get ruleCreateUpdate() {
      return cp('nous_platform_global_bot_setting_usage_rule_create_update');
    },
    get ruleDelete() {
      return cp('nous_platform_global_bot_setting_usage_rule_delete');
    },
  },
  club: {
    get read() {
      return cp('nous_platform_club_read');
    },
    get createUpdate() {
      return cp('nous_platform_club_create_update');
    },
    get delete() {
      return cp('nous_platform_club_delete');
    },
  },
  currency: {
    get read() {
      return cp('nous_platform_currency_read');
    },
    get createUpdate() {
      return cp('nous_platform_currency_create_update');
    },
    get delete() {
      return cp('nous_platform_currency_delete');
    },
  },
  deal: {
    get read() {
      return cp('nous_platform_global_deal_read');
    },
    get createUpdate() {
      return cp('nous_platform_global_deal_create_update');
    },
    get delete() {
      return cp('nous_platform_global_deal_delete');
    },
  },
  agent: {
    get read() {
      return cp('nous_platform_global_agent_read');
    },
    get createUpdate() {
      return cp('nous_platform_global_agent_create_update');
    },
    get delete() {
      return cp('nous_platform_global_agent_delete');
    },
  },
  lobby: {
    get read() {
      return cp('nous_platform_lobby_read');
    },
    get closePokerRoom() {
      return cp('nous_platform_lobby_close_poker_room');
    },
    get leaveTable() {
      return cp('nous_platform_lobby_leave_table');
    },
    get playersRead() {
      return cp('nous_platform_lobby_players_read');
    },
    get resetCache() {
      return cp('nous_platform_lobby_reset_cache');
    },
    get searchTables() {
      return cp('nous_platform_lobby_search_tables');
    },
  },
  order: {
    get read() {
      return cp('nous_platform_global_order_read');
    },
  },
  role: {
    get read() {
      return cp('nous_platform_role_read');
    },
    get createUpdate() {
      return cp('nous_platform_role_create_update');
    },
    get delete() {
      return cp('nous_platform_role_delete');
    },
  },
  seatControlRule: {
    get read() {
      return cp('nous_platform_seat_control_read');
    },
    get createUpdate() {
      return cp('nous_platform_seat_control_create_update');
    },
    get delete() {
      return cp('nous_platform_seat_control_delete');
    },
    get restoreDeleted() {
      return cp('nous_platform_seat_control_restore_deleted');
    },
  },
  session: {
    get read() {
      return cp('nous_platform_session_read');
    },
    get createUpdate() {
      return cp('nous_platform_session_create_update');
    },
    get delete() {
      return cp('nous_platform_session_delete');
    },
  },
  user: {
    get read() {
      return cp('nous_platform_user_read');
    },
    get createUpdate() {
      return cp('nous_platform_user_create_update');
    },
  },
  workspace: {
    get read() {
      return cp('nous_platform_global_maintenance_read');
    },
    get createUpdate() {
      return cp('nous_platform_global_maintenance_create_update');
    },
    get delete() {
      return cp('nous_platform_global_maintenance_delete');
    },
    get addressRead() {
      return cp('nous_platform_global_maintenance_address');
    },
    get balanceRead() {
      return cp('nous_platform_global_maintenance_balance');
    },
  },
};
