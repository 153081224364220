import { loadGlobalWorkspaceClubs, loadGlobalWorkspaceUsers } from '../../api';
import { WorkspaceSchema } from '../../api/schemas/workspaceSchema';
import queryKeys from '../../config/queryKeys';
import usePaginatedData from '../../hooks/usePaginatedData';

export const useGlobalWorkspaceUsersQuery = (args: {
  workspaceId: WorkspaceSchema['id'];
  enabled?: boolean;
}) => {
  const { workspaceId, enabled = false } = args;

  const queryKey = [queryKeys.globalWorkspaceUsers({ workspaceId })];

  return usePaginatedData({
    queryKey,
    queryFn: ({ page }) => loadGlobalWorkspaceUsers({ workspaceId }, { ...(page && { page }) }),
    enabled,
  });
};

export const useGlobalWorkspaceClubsQuery = (args: {
  workspaceId: WorkspaceSchema['id'];
  enabled?: boolean;
}) => {
  const { workspaceId, enabled = false } = args;

  const queryKey = [queryKeys.globalWorkspaceClubs({ workspaceId })];

  return {
    queryKey,
    ...usePaginatedData({
      queryKey,
      queryFn: ({ page }) =>
        loadGlobalWorkspaceClubs({ workspaceId }, { size: '2000', ...(page && { page }) }),
      enabled,
    }),
  };
};
