import { useTranslation } from 'react-i18next';
import { Outlet, useParams } from 'react-router-dom';
import { loadGlobalOrders } from '../../api';
import { OrderSchema } from '../../api/schemas/orderSchema';
import Card from '../../components/Card';
import FadingText from '../../components/FadingText';
import Page from '../../components/Page';
import PageControls from '../../components/PageControls';
import PageTop from '../../components/PageTop';
import Table, { getTableProps } from '../../components/Table';
import queryKeys from '../../config/queryKeys';
import routes from '../../config/routes';
import GlobalOrderFilter from '../../features/global-order/GlobalOrderFilter';
import { TYPE_LABELS } from '../../features/order/helpers';
import OrderAmount from '../../features/order/OrderAmount';
import OrderDetailLink from '../../features/order/OrderDetailLink';
import OrderDropdown from '../../features/order/OrderDropdown';
import OrderStatus from '../../features/order/OrderStatus';
import useBreakpoints from '../../hooks/useBreakpoints';
import usePaginatedData from '../../hooks/usePaginatedData';
import useURL from '../../hooks/useURL';
import useWebSocketPaginatedActions from '../../hooks/useWebSocketPaginatedActions';
import { convertCentsToUSDT } from '../../utils/data';
import { RouteParams } from '../../utils/generics';
import Error403Page from '../Error403Page';
import styles from './styles.module.scss';

type Params = RouteParams<typeof routes.order>;

const GlobalOrdersPage = () => {
  const { t } = useTranslation();
  const { isDesktop, isTablet, isPhone } = useBreakpoints();
  const { orderId } = useParams<Params>();
  const { urlParams } = useURL();
  const { search } = urlParams;

  const queryKey = [queryKeys.orders, urlParams];

  const query = usePaginatedData({
    queryKey,
    queryFn: (params) => loadGlobalOrders({ ...urlParams, ...params }),
  });

  const { data, total, loading, error, checkIsInView } = query;

  const { checkIsCreated, ...meta } = useWebSocketPaginatedActions<OrderSchema, OrderSchema>({
    queryKey,
    domain: 'GlobalOrder',
    updateAction: ['Edited', 'Done'],
    select: (item) => item,
  });

  if (error?.response?.status === 403) return <Error403Page />;

  const getDetailLink = (order: OrderSchema) => routes.order({ orderId: order.id });

  return (
    <Page
      heading={t('common.orders')}
      filter={<GlobalOrderFilter urlParams={urlParams} total={total} />}
      extra={<PageControls />}
      addon={<Outlet />}
    >
      <PageTop
        total={total}
        loading={loading}
        {...(isPhone && {
          sorting: [
            { key: 'created_on', title: t('common.created'), default: true, defaultOrder: 'desc' },
            { key: 'type', title: t('common.type') },
            { key: 'amount', title: t('common.amount') },
            { key: 'status', title: t('common.status') },
          ],
        })}
        meta={meta}
      />
      {(isDesktop || isTablet) && (
        <Table
          data={data}
          search={search}
          {...getTableProps(query)}
          className={styles.table}
          columns={[
            {
              key: 'created_on',
              sorting: true,
              title: t('common.date'),
              render: ({ values }) => <OrderDetailLink data={values} to={getDetailLink(values)} />,
            },
            {
              key: 'type',
              sorting: true,
              title: t('common.type'),
              render: ({ values }) => TYPE_LABELS[values.type],
            },
            {
              key: 'amount',
              sorting: true,
              title: <OrderAmount />,
              render: ({ values }) => convertCentsToUSDT(values.amount),
            },
            {
              key: 'status',
              sorting: true,
              title: t('common.status'),
              render: ({ values }) => <OrderStatus data={values} />,
            },
            {
              title: t('common.workspace'),
              render: ({ values }) => <FadingText text={values.maintenance.name} />,
            },
            {
              render: ({ values }) => <OrderDropdown data={values} />,
              align: 'right',
              controls: true,
            },
          ]}
          renderRow={(row, index) => (
            <Table.Row
              highlighted={checkIsCreated(row.data.id)}
              active={row.data.id === orderId}
              {...checkIsInView(index)}
              {...row}
            />
          )}
        />
      )}
      {isPhone && (
        <Card.Group>
          {data.map((item, index) => (
            <Card key={item.id} highlighted={checkIsCreated(item.id)} {...checkIsInView(index)}>
              <Card.Top>
                <OrderDetailLink data={item} to={getDetailLink(item)} />
                <Card.Top.Controls>
                  <OrderDropdown data={item} />
                </Card.Top.Controls>
              </Card.Top>
              <Card.Field.Group>
                <Card.Field label={t('common.type')}>{TYPE_LABELS[item.type]}</Card.Field>
                <Card.Field label={<OrderAmount />}>{convertCentsToUSDT(item.amount)}</Card.Field>
                <Card.Field label={t('common.status')}>
                  <OrderStatus data={item} />
                </Card.Field>
                <Card.Field label={t('common.workspace')}>
                  <FadingText text={item.maintenance.name} />
                </Card.Field>
              </Card.Field.Group>
            </Card>
          ))}
        </Card.Group>
      )}
    </Page>
  );
};

export default GlobalOrdersPage;
