import { useTranslation } from 'react-i18next';
import { Outlet, useParams } from 'react-router-dom';
import { loadGlobalClubs } from '../../api';
import { GlobalClubSchema } from '../../api/schemas/globalClubSchema';
import Card from '../../components/Card';
import FadingText from '../../components/FadingText';
import Page from '../../components/Page';
import PageControls from '../../components/PageControls';
import PageTop from '../../components/PageTop';
import Table, { getTableProps } from '../../components/Table';
import queryKeys from '../../config/queryKeys';
import routes from '../../config/routes';
import { GlobalClubProvider } from '../../features/global-club/GlobalClubContext';
import GlobalClubCreateButton from '../../features/global-club/GlobalClubCreateButton';
import GlobalClubDetailLink from '../../features/global-club/GlobalClubDetailLink';
import GlobalClubDropdown from '../../features/global-club/GlobalClubDropdown';
import GlobalClubFilter from '../../features/global-club/GlobalClubFilter';
import useBreakpoints from '../../hooks/useBreakpoints';
import usePaginatedData from '../../hooks/usePaginatedData';
import useURL from '../../hooks/useURL';
import useWebSocketPaginatedActions from '../../hooks/useWebSocketPaginatedActions';
import { RouteParams } from '../../utils/generics';
import { TRAINER_POKER_ROOM_LABELS } from '../../utils/trainer';
import Error403Page from '../Error403Page';
import styles from './styles.module.scss';

type Params = RouteParams<typeof routes.club>;

const GlobalClubsPage = () => {
  const { t } = useTranslation();
  const { isDesktop, isTablet, isPhone } = useBreakpoints();
  const { clubId } = useParams<Params>();
  const { urlParams } = useURL();
  const { search } = urlParams;

  const queryKey = [queryKeys.clubs, urlParams];

  const query = usePaginatedData({
    queryKey,
    queryFn: (params) => loadGlobalClubs({ ...urlParams, ...params }),
  });

  const { data, total, loading, error, checkIsInView } = query;

  const { checkIsCreated, ...meta } = useWebSocketPaginatedActions<
    GlobalClubSchema,
    GlobalClubSchema
  >({
    queryKey,
    domain: 'GlobalClub',
    updateAction: ['Edited', 'NoteAdded', 'NoteRemoved'],
    select: (item) => item,
  });

  if (error?.response?.status === 403) return <Error403Page />;

  return (
    <GlobalClubProvider>
      <Page
        heading={t('common.clubs')}
        tabs={[
          { to: routes.clubs, label: t('common.clubs'), active: true },
          { to: routes.leagues, label: t('common.leagues') },
          { to: routes.superLeagues, label: t('common.super_leagues') },
        ]}
        filter={<GlobalClubFilter total={total} />}
        extra={
          <PageControls filter={false}>
            <GlobalClubCreateButton />
          </PageControls>
        }
        addon={<Outlet />}
      >
        <PageTop
          total={total}
          loading={loading}
          {...(isPhone && {
            sorting: [
              { key: 'code', title: t('common.code'), default: true },
              { key: 'name', title: t('common.name') },
              { key: 'created_on', title: t('common.created') },
            ],
          })}
          meta={meta}
        />
        {(isDesktop || isTablet) && (
          <Table
            data={data}
            search={search}
            {...getTableProps(query)}
            className={styles.table}
            columns={[
              {
                key: 'code',
                sorting: true,
                title: t('common.code'),
                render: ({ values }) => <GlobalClubDetailLink data={values} />,
              },
              {
                key: 'name',
                sorting: true,
                title: t('common.name'),
                render: ({ values }) => <FadingText text={values.name} />,
              },
              {
                title: t('common.poker_room'),
                render: ({ values }) => TRAINER_POKER_ROOM_LABELS[values.poker_room],
              },
              {
                render: ({ values }) => <GlobalClubDropdown data={values} />,
                align: 'right',
                controls: true,
              },
            ]}
            renderRow={(row, index) => (
              <Table.Row
                highlighted={checkIsCreated(row.data.id)}
                active={row.data.id === clubId}
                danger={row.data.is_deleted}
                {...checkIsInView(index)}
                {...row}
              />
            )}
          />
        )}
        {isPhone && (
          <Card.Group>
            {data.map((item, index) => (
              <Card
                key={item.id}
                highlighted={checkIsCreated(item.id)}
                danger={item.is_deleted}
                {...checkIsInView(index)}
              >
                <Card.Top>
                  <GlobalClubDetailLink data={item} />
                  <Card.Top.Controls>
                    <GlobalClubDropdown data={item} />
                  </Card.Top.Controls>
                </Card.Top>
                <Card.Field.Group>
                  <Card.Field label={t('common.name')}>
                    <FadingText text={item.name} />
                  </Card.Field>
                  <Card.Field label={t('common.poker_room')}>
                    {TRAINER_POKER_ROOM_LABELS[item.poker_room]}
                  </Card.Field>
                </Card.Field.Group>
              </Card>
            ))}
          </Card.Group>
        )}
      </Page>
    </GlobalClubProvider>
  );
};

export default GlobalClubsPage;
