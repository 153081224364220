import { FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  loadWorkspaceBotSettings,
  loadWorkspaceChannels,
  loadWorkspaceClubs,
  loadWorkspaceIdentities,
  loadWorkspaceTags,
  loadWorkspaceUsers,
  updateWorkspaceAccount,
} from '../../api';
import { WorkspaceAccountSchema } from '../../api/schemas/account/workspaceAccountSchema';
import { BotSettingSchema } from '../../api/schemas/botSettingSchema';
import { ChannelSchema, STATUS } from '../../api/schemas/channelSchema';
import { WorkspaceDealSchema } from '../../api/schemas/deal/workspaceDealSchema';
import { IdentitySchema } from '../../api/schemas/identitySchema';
import { WorkspaceSchema } from '../../api/schemas/workspaceSchema';
import { WorkspaceUserSchema } from '../../api/schemas/workspaceUserSchema';
import Input from '../../components/Input';
import PageAddon from '../../components/PageAddon';
import Select from '../../components/Select';
import useQuickForm from '../../hooks/useQuickForm';
import useURL from '../../hooks/useURL';
import icon from '../../static/icons';
import { formatAbsoluteDate } from '../../utils/date';
import { email, handleError, max } from '../../utils/form';
import { TRAINER_POKER_ROOM_LABELS, trainerGameTypeOptions } from '../../utils/trainer';
import { numbers } from '../../utils/validations';
import { checkWorkspaceHasChannelsAccess } from '../../utils/workspace';
import AccountDetailBotSettings from '../account/AccountDetailBotSettings';
import AccountDetailChannels from '../account/AccountDetailChannels';
import AccountDetailClubs from '../account/AccountDetailClubs';
import AccountGeolocationDialog from '../account/AccountGeolocationDialog';
import { limitOptions, tableSizeOptions } from '../account/helpers';
import { WorkspaceAccountFields as Fields } from '../account/types';
import { getTitle } from '../session/helpers';
import { getIdentityFullName } from '../workspace-identity/helpers';
import { getDefaultValues, getPayload } from './helpers';
import WorkspaceAccountColorCircle from './WorkspaceAccountColorCircle';
import WorkspaceAccountDetailDeals from './WorkspaceAccountDetailDeals';
import WorkspaceAccountMetrics from './WorkspaceAccountMetrics';

interface Props {
  data: WorkspaceAccountSchema;
  workspaceId: WorkspaceSchema['id'];
  getBotSettingRoute?: (args: { botSettingId: BotSettingSchema['id'] }) => string;
  getChannelRoute?: (args: { channelId: ChannelSchema['id'] }) => string;
  getDealRoute?: (args: { dealId: WorkspaceDealSchema['id'] }) => string;
  getUserRoute?: (args: { userId: WorkspaceUserSchema['id'] }) => string;
  getIdentityRoute?: (args: { identityId: IdentitySchema['id'] }) => string;
}

const WorkspaceAccountDetailGeneralTab = (props: Props) => {
  const {
    data,
    workspaceId,
    getBotSettingRoute,
    getChannelRoute,
    getDealRoute,
    getUserRoute,
    getIdentityRoute,
  } = props;

  const { t } = useTranslation();
  const { navigate } = useURL();

  const {
    id,
    room,
    user,
    identity,
    trainer_version,
    next_session,
    last_online,
    last_played,
    created_on,
    modified_on,
  } = data;

  const workspaceHasChannelsAccess = checkWorkspaceHasChannelsAccess(workspaceId);

  const form = useQuickForm<Fields>({ data, defaultValues: getDefaultValues(data) });

  const { control, watch, setValue, handleSubmit } = form;

  const onSubmit = async (values: Fields) => {
    try {
      await updateWorkspaceAccount({
        workspaceId,
        accountId: data.id,
        payload: getPayload(values),
      });
    } catch (error) {
      handleError({ error, form });
    }
  };

  const commonProps = { control, onSubmit: handleSubmit(onSubmit) };

  const channelIds = watch('channel_ids');

  return (
    <FormProvider {...form}>
      <PageAddon.Fields>
        <PageAddon.Id id={id} />
        <PageAddon.Field label={t('common.poker_room')}>
          <PageAddon.Copy>{TRAINER_POKER_ROOM_LABELS[room]}</PageAddon.Copy>
        </PageAddon.Field>
        <PageAddon.Field label={t('common.clubs')}>
          <AccountDetailClubs
            data={data}
            onSubmit={() => commonProps.onSubmit()}
            onLoad={(args) =>
              loadWorkspaceClubs({ workspaceId }, { ...args, poker_room_in: `${room}` })
            }
          />
        </PageAddon.Field>
        <PageAddon.Field label={t('common.game_types')}>
          <Select.Multi.Quick name="game_types" options={trainerGameTypeOptions} {...commonProps} />
        </PageAddon.Field>
        <PageAddon.Field label={t('common.limits')}>
          <Select.Multi.Quick
            selectAll
            name="comparative_limits"
            options={limitOptions}
            {...commonProps}
          />
        </PageAddon.Field>
        <PageAddon.Field label={t('common.table_size')}>
          <Select.Multi.Quick name="table_sizes" options={tableSizeOptions} {...commonProps} />
        </PageAddon.Field>
        <PageAddon.Field label={t('common.max_tables')}>
          <Input.Quick
            name="max_tables"
            allow={numbers}
            rules={{ validate: { max: max(t('common.max_tables'), 5) } }}
            {...commonProps}
          />
        </PageAddon.Field>
        <PageAddon.Field label={t('common.geolocation')}>
          <Input.Quick
            name="geo"
            {...commonProps}
            extra={<AccountGeolocationDialog quick onChange={() => commonProps.onSubmit()} />}
          />
        </PageAddon.Field>
        {workspaceHasChannelsAccess && (
          <PageAddon.Field label={t('common.channels')}>
            <AccountDetailChannels
              data={data}
              onDelete={({ channelId }) => {
                setValue(
                  'channel_ids',
                  channelIds.filter((el) => el.value !== channelId)
                );

                commonProps.onSubmit();
              }}
              {...(getChannelRoute && {
                controls: ({ channelId }) => (
                  <Select.Quick.Link onClick={() => navigate(getChannelRoute({ channelId }))} />
                ),
              })}
            >
              <Select.AsyncV2
                onLoad={(args) =>
                  loadWorkspaceChannels({ workspaceId }, { ...args, is_free: 'true' })
                }
                select={(item) => {
                  const inactive =
                    item.status === STATUS.UNKNOWN || item.status === STATUS.INACTIVE;

                  return {
                    value: item.id,
                    label: item.host,
                    danger: inactive,
                    ...(item.country && { caption: item.country }),
                    ...(inactive && { endIcon: icon('alertTriangle', 16) }),
                  };
                }}
              >
                {({ options, ...selectAsyncProps }) => (
                  <Select.Quick
                    name="channel_ids"
                    options={options.filter(
                      (item) => !channelIds.map((el) => el.value).includes(item.value)
                    )}
                    onChange={(value) => {
                      setValue('channel_ids', value ? [...channelIds, value] : []);

                      commonProps.onSubmit();
                    }}
                    {...selectAsyncProps}
                  />
                )}
              </Select.AsyncV2>
            </AccountDetailChannels>
          </PageAddon.Field>
        )}
        <PageAddon.Field label={t('common.bot_settings')}>
          <AccountDetailBotSettings
            data={data}
            onSubmit={() => commonProps.onSubmit()}
            onLoad={(args) => loadWorkspaceBotSettings({ workspaceId }, args)}
            {...(getBotSettingRoute && {
              controls: ({ botSettingId }) => (
                <Select.Quick.Link onClick={() => navigate(getBotSettingRoute({ botSettingId }))} />
              ),
            })}
          />
        </PageAddon.Field>
        <PageAddon.Field label={t('common.deals')}>
          <WorkspaceAccountDetailDeals
            workspaceId={workspaceId}
            data={data}
            onSubmit={() => commonProps.onSubmit()}
            getDealRoute={getDealRoute}
          />
        </PageAddon.Field>
        <PageAddon.Field label={t('common.affiliate')}>
          <Input.Quick name="affiliate_reference_number" {...commonProps} />
        </PageAddon.Field>
        <PageAddon.Field label={t('common.assign_to')}>
          <Select.AsyncV2
            onLoad={(args) => loadWorkspaceUsers({ workspaceId }, args)}
            select={(item) => ({ value: item.id, label: item.username })}
          >
            {(selectAsyncProps) => (
              <Select.Quick
                clearable
                name="user_id"
                {...(user &&
                  getUserRoute && {
                    extra: (
                      <Select.Quick.Link
                        onClick={() => navigate(getUserRoute({ userId: user.id }))}
                      />
                    ),
                  })}
                {...commonProps}
                {...selectAsyncProps}
              />
            )}
          </Select.AsyncV2>
        </PageAddon.Field>
        <PageAddon.Field label={t('common.identity')}>
          <Select.AsyncV2
            onLoad={(args) => loadWorkspaceIdentities({ workspaceId }, args)}
            select={(item) => ({ value: item.id, label: getIdentityFullName(item) })}
          >
            {(selectAsyncProps) => (
              <Select.Quick
                clearable
                name="identity_id"
                {...(identity &&
                  getIdentityRoute && {
                    extra: (
                      <Select.Quick.Link
                        onClick={() => navigate(getIdentityRoute({ identityId: identity.id }))}
                      />
                    ),
                  })}
                {...commonProps}
                {...selectAsyncProps}
              />
            )}
          </Select.AsyncV2>
        </PageAddon.Field>
        <PageAddon.Field label={t('common.tags')}>
          <Select.AsyncV2
            onLoad={(args) => loadWorkspaceTags({ workspaceId }, args)}
            select={(item) => ({
              value: item.id,
              label: item.name,
              icon: <WorkspaceAccountColorCircle color={item.color} />,
            })}
          >
            {({ options, ...selectAsyncProps }) => (
              <Select.Multi.Quick
                name="tag_ids"
                options={options}
                tagExtra={(value) => {
                  const color = data.tags.find((item) => item.id === value)?.color;

                  return color ? <WorkspaceAccountColorCircle color={color} /> : null;
                }}
                {...commonProps}
                {...selectAsyncProps}
              />
            )}
          </Select.AsyncV2>
        </PageAddon.Field>
        <PageAddon.Field label={t('common.trainer_version')}>
          {trainer_version ? <PageAddon.Copy>{trainer_version}</PageAddon.Copy> : '—'}
        </PageAddon.Field>
        <PageAddon.Field label={t('common.next_session')}>
          {next_session ? (
            <PageAddon.Copy>
              {getTitle({ start: next_session.start, end: next_session.end })}
            </PageAddon.Copy>
          ) : (
            '—'
          )}
        </PageAddon.Field>
        <PageAddon.Field label={t('common.last_online')}>
          {last_online ? <PageAddon.Copy>{formatAbsoluteDate(last_online)}</PageAddon.Copy> : '—'}
        </PageAddon.Field>
        <PageAddon.Field label={t('common.last_played')}>
          {last_online ? <PageAddon.Copy>{formatAbsoluteDate(last_played)}</PageAddon.Copy> : '—'}
        </PageAddon.Field>
        <PageAddon.Field label={t('common.modified')}>
          <PageAddon.Copy>{formatAbsoluteDate(modified_on)}</PageAddon.Copy>
        </PageAddon.Field>
        <PageAddon.Field label={t('common.created')}>
          <PageAddon.Copy>{formatAbsoluteDate(created_on)}</PageAddon.Copy>
        </PageAddon.Field>
        <PageAddon.Collapse label={t('common.email')}>
          <PageAddon.Collapse.Field label={t('common.email')}>
            <Input.Quick name="email" rules={{ validate: email }} {...commonProps} />
          </PageAddon.Collapse.Field>
          <PageAddon.Collapse.Field label={t('common.password')}>
            <Input.Quick name="email_password" type="password" {...commonProps} />
          </PageAddon.Collapse.Field>
        </PageAddon.Collapse>
        <PageAddon.Collapse label={t('common.metrics')}>
          <WorkspaceAccountMetrics data={data} />
        </PageAddon.Collapse>
      </PageAddon.Fields>
    </FormProvider>
  );
};

export default WorkspaceAccountDetailGeneralTab;
