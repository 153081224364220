import { useQuery, keepPreviousData } from '@tanstack/react-query';
import { loadGlobalOrdersFilterValues } from '../../api';
import queryKeys from '../../config/queryKeys';
import URLParams from '../../types/URLParams';
import OrderFilter from '../order/OrderFilter';

interface Props {
  urlParams: URLParams;
  total?: number;
}

const GlobalOrderFilter = (props: Props) => {
  const { urlParams, total } = props;

  const queryKey = [queryKeys.ordersFilterValues, urlParams];
  const queryFn = () => loadGlobalOrdersFilterValues(urlParams);

  const { data, isPending } = useQuery({
    queryKey,
    queryFn,
    placeholderData: keepPreviousData,
    meta: { ignoredStatuses: [403] },
  });

  return <OrderFilter data={data} loading={isPending} total={total} />;
};

export default GlobalOrderFilter;
