import { useTranslation } from 'react-i18next';
import { Outlet } from 'react-router-dom';
import { GlobalLeagueSchema } from '../../api/schemas/globalLeagueSchema';
import { GlobalSuperLeagueSchema } from '../../api/schemas/globalSuperLeagueSchema';
import Button from '../../components/Button';
import FadingText from '../../components/FadingText';
import Link from '../../components/Link';
import PageAddon from '../../components/PageAddon';
import Tooltip from '../../components/Tooltip';
import icon from '../../static/icons';
import { formatAbsoluteDate } from '../../utils/date';
import { TRAINER_POKER_ROOM_LABELS } from '../../utils/trainer';
import { can } from '../permission/helpers';
import GlobalLeagueDetailDropdown from './GlobalLeagueDetailDropdown';

interface Props {
  data: GlobalLeagueSchema;
  onClose: () => void;
  onEdit: () => void;
  getSuperLeagueRoute: (args: { superLeagueId: GlobalSuperLeagueSchema['id'] }) => string;
}

const GlobalLeagueDetail = (props: Props) => {
  const { data, onClose, onEdit, getSuperLeagueRoute } = props;

  const { t } = useTranslation();

  const { id, code, name, poker_room, super_league, extra, is_deleted, created_on, modified_on } =
    data;

  const titleIcons = is_deleted && icon('trash', 12);

  return (
    <PageAddon.Group>
      <Outlet />
      <PageAddon title={code} icons={titleIcons} onClose={onClose}>
        <PageAddon.Fields>
          <PageAddon.Id id={id} />
          <PageAddon.Field label={t('common.name')}>
            <FadingText text={name} tooltip={false} />
          </PageAddon.Field>
          <PageAddon.Field label={t('common.poker_room')}>
            {TRAINER_POKER_ROOM_LABELS[poker_room]}
          </PageAddon.Field>
          {super_league && (
            <PageAddon.Field label={t('common.super_league')}>
              <Link to={getSuperLeagueRoute({ superLeagueId: super_league.id })}>
                <FadingText text={super_league.code} tooltip={false} />
              </Link>
            </PageAddon.Field>
          )}
          <PageAddon.ExtraFields data={extra} />
          <PageAddon.Field label={t('common.modified')}>
            {formatAbsoluteDate(modified_on)}
          </PageAddon.Field>
          <PageAddon.Field label={t('common.created')}>
            {formatAbsoluteDate(created_on)}
          </PageAddon.Field>
        </PageAddon.Fields>
        {!is_deleted && (
          <PageAddon.Controls>
            <PageAddon.Controls.Group>
              <Tooltip
                label={can.club.createUpdate ? t('common.edit') : t('common.no_permission')}
                hideOnMobile={can.club.createUpdate}
              >
                <div>
                  <Button
                    variant="transparent"
                    icon={icon('edit', 16)}
                    onClick={onEdit}
                    data-testid="edit"
                    disabled={!can.club.createUpdate}
                  />
                </div>
              </Tooltip>
              <GlobalLeagueDetailDropdown data={data} />
            </PageAddon.Controls.Group>
          </PageAddon.Controls>
        )}
      </PageAddon>
    </PageAddon.Group>
  );
};

export default GlobalLeagueDetail;
