import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { deleteGlobalDeal } from '../../api';
import { GlobalDealSchema } from '../../api/schemas/deal/globalDealSchema';
import { presets } from '../../components/Dropdown';
import routes from '../../config/routes';
import useURL from '../../hooks/useURL';
import icon from '../../static/icons';
import { RouteParams } from '../../utils/generics';
import notify from '../../utils/notify';
import { can } from '../permission/helpers';

type Params = RouteParams<typeof routes.deal>;

interface Args {
  data: GlobalDealSchema;
}

const useDropdown = (args: Args) => {
  const { data } = args;

  const { id, code, accounts, is_deleted } = data;

  const { t } = useTranslation();
  const { navigate } = useURL();
  const { dealId } = useParams<Params>();

  const hasAccounts = Boolean(accounts.length);

  const getTooltip = () => {
    if (!can.deal.delete) return t('common.no_permission');

    if (hasAccounts) return t('sentences.the_deal_has_linked_accounts');

    return null;
  };

  return {
    title: code,
    disabled: is_deleted,
    options: {
      edit: {
        ...presets.edit,
        onClick: () => navigate(routes.dealEdit({ dealId: id })),
        disabled: !can.deal.createUpdate,
        ...(!can.deal.createUpdate && { tooltip: t('common.no_permission') }),
      },
      notes: {
        icon: icon('notes', 20),
        label: t('common.notes'),
        onClick: () => navigate(routes.dealNotes({ dealId: id })),
      },
      copy: presets.copyId(id),
      divider: { divider: true },
      delete: {
        ...presets.delete,
        onClick: async () => {
          await deleteGlobalDeal({ dealId: id });

          if (id === dealId) {
            navigate(routes.deals);
          }

          notify('success', { title: t('sentences.record_has_been_deleted') });
        },
        disabled: !can.deal.delete || hasAccounts,
        tooltip: getTooltip(),
      },
    },
  };
};

export default useDropdown;
