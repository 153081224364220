import { z } from 'zod';
import dateTimeSchema from './dateTimeSchema';
import globalLeagueSchema from './globalLeagueSchema';
import jsonSchema from './jsonSchema';
import trainerPokerRoomSchema from './trainerPokerRoomSchema';

const globalClubSchema = z.object({
  id: z.string(),
  name: z.string(),
  code: z.string(),
  high_risk: z.boolean(),
  poker_room: trainerPokerRoomSchema,
  extra: jsonSchema,
  league: globalLeagueSchema.or(z.null()),
  notes_count: z.number(),
  is_deleted: z.boolean(),
  created_on: dateTimeSchema,
  modified_on: dateTimeSchema,
});

export const globalClubFilterValuesSchema = z.object({
  league: z.array(
    z.object({
      id: globalLeagueSchema.shape.id,
      name: globalLeagueSchema.shape.name,
    })
  ),
  poker_room: z.array(globalClubSchema.shape.poker_room),
});

export type GlobalClubSchema = z.infer<typeof globalClubSchema>;

export default globalClubSchema;
