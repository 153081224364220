import { secondsToMinutes } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { WorkspaceSchema } from '../../api/schemas/workspaceSchema';
import PageAddon from '../../components/PageAddon';
import Tag from '../../components/Tag';
import { displayMinuteInHours } from '../../utils/data';

interface Props {
  data: WorkspaceSchema;
}

const GlobalWorkspaceAutomining = (props: Props) => {
  const { data } = props;

  const { t } = useTranslation();

  const {
    auto_create_mining_session,
    mining_stages,
    mining_session_duration,
    mining_session_interval,
  } = data;

  if (!auto_create_mining_session) {
    return <PageAddon.Field label={t('common.automining')}>{t('common.false')}</PageAddon.Field>;
  }

  const getTags = () => {
    if (!mining_stages.length) return '—';

    return mining_stages.map((item) => <Tag key={item.id}>{item.name}</Tag>);
  };

  return (
    <PageAddon.Collapse label={t('common.automining')}>
      <PageAddon.Collapse.Field label={t('common.stages')}>
        <PageAddon.Tags>{getTags()}</PageAddon.Tags>
      </PageAddon.Collapse.Field>
      <PageAddon.Collapse.Field label={t('common.session_duration')}>
        {displayMinuteInHours(secondsToMinutes(mining_session_duration))}
      </PageAddon.Collapse.Field>
      <PageAddon.Collapse.Field label={t('common.session_interval')}>
        {displayMinuteInHours(secondsToMinutes(mining_session_interval))}
      </PageAddon.Collapse.Field>
    </PageAddon.Collapse>
  );
};

export default GlobalWorkspaceAutomining;
